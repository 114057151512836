import { IStaffPreferences } from "../../../../@types/Preferences";
import { Checkbox } from "../../../../components/Checkbox";
import { useToast } from "../../../../hooks/useToast";
import { useUserPreferences } from "../../../../hooks/useUserPreferences";
import { PageTitle } from "../../../../layout/components/PageTitle";
import { staffService } from "../../../../services/staffService";
import {
  MessagesPreferencesContainer,
  ParentContainer,
  TextContainer,
} from "./MessagesPreferencesSection.styles";

export function MessagesPreferencesSection() {
  const { staffPreferences, changeStaffPreferences } = useUserPreferences();
  const { showToast } = useToast();

  const handleCheckboxChange = async (isChecked: boolean) => {
    const newStafPreferences: IStaffPreferences = {
      ...staffPreferences,
      notifyByEmailOnMessage: isChecked,
    };
    changeStaffPreferences(newStafPreferences);

    const result = await staffService.updateNotifyByEmailOnMessageFlag(
      isChecked
    );

    if (result.status >= 200 && result.status < 300) {
      showToast("Messages preferences successfully updated", "success");
    } else {
      const oldStafPreferences: IStaffPreferences = {
        ...staffPreferences,
        notifyByEmailOnMessage: !isChecked,
      };
      changeStaffPreferences(oldStafPreferences);

      showToast(
        "Something went wrong while updating, please try again",
        "error"
      );
    }
  };

  return (
    <ParentContainer>
      <PageTitle>Messages Preferences</PageTitle>

      <MessagesPreferencesContainer>
        <TextContainer>
          Receive email notification for new messages
        </TextContainer>
        <Checkbox
          spacing="40px"
          label=""
          onChange={(isChecked) => {
            handleCheckboxChange(isChecked);
          }}
          checked={staffPreferences.notifyByEmailOnMessage}
        />
      </MessagesPreferencesContainer>
    </ParentContainer>
  );
}
