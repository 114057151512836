import { css, styled } from "../../../../../styles";

// Components
export const SurveyBuilderContainer = styled("div", {
  width: "100vw",
  height: "100vh",
  position: "relative",
  backgroundColor: "$gray-20",

  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export const FormContainer = styled("div", {
  marginTop: "62px",
  padding: "20px",
  marginBottom: "30px",
  width: "50%",

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  backgroundColor: "white",

  border: " 1px solid rgb(236, 236, 236)",
  borderRadius: "10px",

  "@sm": {
    width: "90%",
  },
});
export const FormInfoContainer = styled("div", {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  padding: "20px",

  variants: {
    answer: {
      true: {
        justifyContent: "flex-start",
      },
    },
  },
});

export const FormField = styled("div", {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  marginBottom: "30px",

  ".ant-select": {
    height: "42px !important",
  },

  p: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",

    color: "$charcoal",
    marginBottom: "15px",

    fontSize: "20px",
    lineHeight: "16px",
    fontFamily: "$openSans",
  },
});

export const HandleFormContainer = styled("div", {
  width: "100%",
  display: "flex",
  justifyContent: "flex-end ",
  padding: "26px 12px 0px 0px",
  alignItems: "center",
});

export const QuestionsContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: "20px",
  width: "100%",
});

export const Question = styled("div", {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  width: "90%",
});

export const SeparationLine = styled("hr", {
  borderTop: "1px solid #DFDFDF",
  margin: "20px 0 20px 0",
  width: "100%",
});

export const FormTitle = styled("div", {
  width: "100%",

  display: "flex",
  flexDirection: "row",
  alignItems: "center",

  color: "$charcoal",
  marginBottom: "30px",

  fontSize: "18px",
  fontWeight: "600",
  lineHeight: "24px",
  fontFamily: "$openSans",
});

export const SectionDropdownContainer = styled("div", {
  width: "100%",
  display: "flex",
});

export const AddSectionContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "40px",
});

export const SectionTitle = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "20px 0 20px 0",
  maxWidth: "80%",
});

export const ScoreContainer = styled("div", {
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "20px 5px 20px 20px",
  gap: "14px",

  p: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "15px",

    color: "$charcoal",

    fontSize: "15px",
    lineHeight: "16px",
    fontFamily: "$openSans",
  },

  ".ant-select-single": {
    height: "42px!important",
  },
});

export const ScoringToogleContainer = styled("div", {
  width: "fit-content",
  height: "42px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
});

export const DomainContainer = styled("div", {
  width: "100%",
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "column",
  justifyContent: "space-start",
  padding: "20px 5px 40px 20px",
  gap: "14px",
});

export const AddDomainContainer = styled("div", {
  width: "100%",
  display: "flex",
  alignItems: "baseline",
  justifyContent: "space-start",
  gap: "14px",
});

export const DomainList = styled("div", {
  width: "fit-content",
  minWidth: "300px",
  display: "flex",
  flexDirection: "column",
  border: "1px solid #DFDFDF ",
  borderRadius: "8px",
  padding: "15px 20px",
  gap: "14px",
  fontSize: "14px",
  lineHeight: "18px",
  fontFamily: "$openSans",
  color: "$gray-90",
});

export const CloseContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  marginTop: "1px",

  variants: {
    disabled: {
      true: {
        display: "none",
      },
    },
  },
});

export const ListItem = styled("div", {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "8px",
  marginLeft: "4px",
});

// CSS

export const textInputCss = css({
  width: "100%",

  span: {
    margin: "0px",
  },

  p: {
    width: "100% !important",
  },
});
