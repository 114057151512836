import { useEffect, useState } from "react";

const DEFAULT_SCALE = 1;
const ACTIVE_SCALE = 1.5;

export function StarDot(props: any) {
  const { cx, cy, fill, legend, onDotClick } = props;

  const [active, setActive] = useState(false);
  const [scale, setScale] = useState(DEFAULT_SCALE);

  useEffect(() => {
    if (!legend) setScale(active ? ACTIVE_SCALE : DEFAULT_SCALE);
  }, [active]);

  return (
    <svg
      {...props}
      x={cx && cx - 4 * scale}
      y={cy && cy - 4 * scale}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      cursor="pointer"
      onMouseOver={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      onClick={onDotClick}
    >
      <path
        d="M1.49579 1.51009C1.49579 1.50219 1.50191 1.49579 1.50947 1.49579H9.49053C9.49809 1.49579 9.50421 1.50219 9.50421 1.51009V9.48991C9.50421 9.49781 9.49809 9.50421 9.49053 9.50421H1.50947C1.50191 9.50421 1.49579 9.49781 1.49579 9.48991V1.51009Z"
        fill={fill}
        transform={`scale(${scale})`}
      />
      <path
        d="M5.49012 0.00409458C5.49557 -0.00136476 5.50443 -0.00136491 5.50989 0.00409443L10.9959 5.49011C11.0014 5.49557 11.0014 5.50443 10.9959 5.50989L5.50989 10.9959C5.50443 11.0014 5.49557 11.0014 5.49012 10.9959L0.00409458 5.50989C-0.00136476 5.50443 -0.00136491 5.49557 0.00409443 5.49012L5.49012 0.00409458Z"
        fill={fill}
        transform={`scale(${scale})`}
      />
    </svg>
  );
}
