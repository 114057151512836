export function DiamondLegend(props: any) {
  const { fill } = props;

  return (
    <svg x={0} y={0} width="16" height="16" viewBox="-5 -3 16 16" fill="none">
      <rect
        y="6"
        width="7"
        height="7"
        fill={fill}
        transform="rotate(-45 0 6)"
      />
    </svg>
  );
}
