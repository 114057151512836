import { useEffect, useState } from "react";

import { IUserPreferences } from "../../../../@types/Preferences";
import { IUpdateUserUnitPreferences } from "../../../../@types/User";
import { Dropdown, DropdownTextItem } from "../../../../components/Dropdown";
import { SkeletonLoading } from "../../../../components/Skeleton";
import { AUTH_UNIT_PREFERENCES } from "../../../../constants/localStorageKeys";
import { useUserPreferencesData } from "../../../../hooks/queries/patients";
import { useToast } from "../../../../hooks/useToast";
import { PageTitle } from "../../../../layout/components/PageTitle";
import { userService } from "../../../../services/userService";
import {
  PreferencesContainer,
  SectionContainer,
  UnitContainer,
  UnitTitle,
} from "./PreferencesSection.styles";

export function PreferencesSection() {
  const [temperatureUnit, setTemperatureUnit] = useState("°C");
  const [glucoseUnit, setGlucoseUnit] = useState("mmol/L");
  const [weightUnit, setWeightUnit] = useState("kg");
  const [pefUnit, setPefUnit] = useState("L/sec");
  const [creatinineUnit, setCreatinineUnit] = useState("μmol/L");
  const [heightUnit, setHeightUnit] = useState("cm");

  const [updateUnits, setUpdateUnits] = useState(false);

  const { userPreferences, refetch } = useUserPreferencesData();
  const { showToast } = useToast();

  useEffect(() => {
    if (userPreferences) {
      if (userPreferences.temperatureUnit) {
        setTemperatureUnit(userPreferences.temperatureUnit);
      }
      if (userPreferences.bloodGlucoseUnit) {
        setGlucoseUnit(userPreferences.bloodGlucoseUnit);
      }
      if (userPreferences.weightUnit) {
        setWeightUnit(userPreferences.weightUnit);
      }
      if (userPreferences.flowRateUnit) {
        setPefUnit(userPreferences.flowRateUnit);
      }
      if (userPreferences.creatinineUnit) {
        setCreatinineUnit(userPreferences.creatinineUnit);
      }
      if (userPreferences.heightUnit) {
        setHeightUnit(userPreferences.heightUnit);
      }
    }
  }, [userPreferences]);

  useEffect(() => {
    if (
      userPreferences &&
      updateUnits &&
      (userPreferences.temperatureUnit !== temperatureUnit ||
        userPreferences.bloodGlucoseUnit !== glucoseUnit ||
        userPreferences.weightUnit !== weightUnit ||
        userPreferences.flowRateUnit !== pefUnit ||
        userPreferences.creatinineUnit !== creatinineUnit ||
        userPreferences.heightUnit !== heightUnit)
    ) {
      const unitPreferences: IUpdateUserUnitPreferences = {
        temperatureUnit,
        bloodGlucoseUnit: glucoseUnit,
        weightUnit,
        flowRateUnit: pefUnit,
        creatinineUnit,
        heightUnit,
      };

      userService.updateUserUnitPreferences(unitPreferences).then((result) => {
        const savedPreferences = localStorage.getItem(AUTH_UNIT_PREFERENCES);

        if (savedPreferences) {
          const parsedSavedPreferences = JSON.parse(
            savedPreferences
          ) as IUserPreferences;

          parsedSavedPreferences.temperatureUnit = temperatureUnit;
          parsedSavedPreferences.bloodGlucoseUnit = glucoseUnit;
          parsedSavedPreferences.weightUnit = weightUnit;
          parsedSavedPreferences.flowRateUnit = pefUnit;
          parsedSavedPreferences.creatinineUnit = creatinineUnit;
          parsedSavedPreferences.heightUnit = heightUnit;

          const newPreferences = JSON.stringify(parsedSavedPreferences);

          localStorage.setItem(AUTH_UNIT_PREFERENCES, newPreferences);
        }
        refetch();
        setUpdateUnits(false);

        if (result.status >= 200 && result.status < 300) {
          showToast("Settings successfully updated", "success");
        } else {
          showToast("Sorry, something went wrong. Please try again", "error");
        }
      });
    }
  }, [updateUnits]);

  if (!userPreferences) {
    return (
      <SectionContainer>
        <PageTitle>Preferences</PageTitle>

        <PreferencesContainer>
          <UnitContainer>
            <SkeletonLoading width="190px" height="34px" />
            <SkeletonLoading width="130px" height="34px" />
          </UnitContainer>
          <UnitContainer>
            <SkeletonLoading width="190px" height="34px" />
            <SkeletonLoading width="130px" height="34px" />
          </UnitContainer>
          <UnitContainer>
            <SkeletonLoading width="190px" height="34px" />
            <SkeletonLoading width="130px" height="34px" />
          </UnitContainer>
          <UnitContainer>
            <SkeletonLoading width="190px" height="34px" />
            <SkeletonLoading width="130px" height="34px" />
          </UnitContainer>
          <UnitContainer>
            <SkeletonLoading width="190px" height="34px" />
            <SkeletonLoading width="130px" height="34px" />
          </UnitContainer>
          <UnitContainer>
            <SkeletonLoading width="190px" height="34px" />
            <SkeletonLoading width="130px" height="34px" />
          </UnitContainer>
        </PreferencesContainer>
      </SectionContainer>
    );
  }

  return (
    <SectionContainer>
      <PageTitle>Preferences</PageTitle>

      <PreferencesContainer>
        <UnitContainer>
          <UnitTitle>Default temperature unit</UnitTitle>
          <Dropdown
            value={temperatureUnit}
            trigger={temperatureUnit}
            onValueChange={(e) => {
              setTemperatureUnit(e);
              setUpdateUnits(true);
            }}
          >
            <DropdownTextItem text="Celsius" value="°C" />
            <DropdownTextItem text="Fahrenheit" value="°F" />
          </Dropdown>
        </UnitContainer>
        <UnitContainer>
          <UnitTitle>Default glucose unit</UnitTitle>
          <Dropdown
            value={glucoseUnit}
            trigger={glucoseUnit}
            onValueChange={(e) => {
              setGlucoseUnit(e);
              setUpdateUnits(true);
            }}
          >
            <DropdownTextItem text="mmol/L" value="mmol/L" />
            <DropdownTextItem text="mg/dL" value="mg/dL" />
          </Dropdown>
        </UnitContainer>
        <UnitContainer>
          <UnitTitle>Default weight unit</UnitTitle>
          <Dropdown
            value={weightUnit}
            trigger={weightUnit}
            onValueChange={(e) => {
              setWeightUnit(e);
              setUpdateUnits(true);
            }}
          >
            <DropdownTextItem text="Kilograms (kg)" value="kg" />
            <DropdownTextItem text="Pounds (lb)" value="lb" />
            <DropdownTextItem text="Stones (st)" value="st" />
          </Dropdown>
        </UnitContainer>
        <UnitContainer>
          <UnitTitle>Default PEF unit</UnitTitle>
          <Dropdown
            value={pefUnit}
            trigger={pefUnit}
            onValueChange={(e) => {
              setPefUnit(e);
              setUpdateUnits(true);
            }}
          >
            <DropdownTextItem text="L/sec" value="L/sec" />
            <DropdownTextItem text="L/min" value="L/min" />
          </Dropdown>
        </UnitContainer>
        <UnitContainer>
          <UnitTitle>Default creatinine unit</UnitTitle>
          <Dropdown
            value={creatinineUnit}
            trigger={creatinineUnit}
            onValueChange={(e) => {
              setCreatinineUnit(e);
              setUpdateUnits(true);
            }}
          >
            <DropdownTextItem text="μmol/L" value="μmol/L" />
            <DropdownTextItem text="mg/dL" value="mg/dL" />
          </Dropdown>
        </UnitContainer>
        <UnitContainer>
          <UnitTitle>Default height unit</UnitTitle>
          <Dropdown
            value={heightUnit}
            trigger={heightUnit}
            onValueChange={(e) => {
              setHeightUnit(e);
              setUpdateUnits(true);
            }}
          >
            <DropdownTextItem text="Feet (ft)" value="ft" />
            <DropdownTextItem text="Centimeters (cm)" value="cm" />
          </Dropdown>
        </UnitContainer>
      </PreferencesContainer>
    </SectionContainer>
  );
}
