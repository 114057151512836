import { useQueries, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";

import {
  GetMultiplePatientMeasurementProps,
  GetPatientBloodPressureMeasurementProps,
  GetPatientMeasurementProps,
  IPatientMeasurementAPIResponse,
  MeasurementTypes,
} from "../../@types/Measurements";
import { measurementsService } from "../../services/measurementsService";
import { getLongFormattedDate } from "../../utils/dateFormatter";
import { roundToX } from "../../utils/unitOperations";
import { useUserPreferences } from "../useUserPreferences";

const parseDataAndGetMainMeasurementValues = ({
  response,
  measurementType,
}: {
  response: IPatientMeasurementAPIResponse;
  measurementType: MeasurementTypes;
}) => {
  if (response === undefined) {
    return undefined;
  }

  const {
    measurements,
    min,
    max,
    minDate,
    maxDate,
    average,
    lastSpirometryTestId,
    patientBaselines,
  } = response;
  if (!measurements.length) {
    return {
      measurements: new Map<string, any[]>(),
      mainMeasurementValues: {
        min: null,
        max: null,
        minDate: null,
        maxDate: null,
        avg: null,
        lastSpirometryTestId: null,
      },
      type: measurementType,
      patientBaselines,
    };
  }
  const parsedMeasurements = new Map<string, any[]>();

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < measurements.length; i++) {
    const currentMeasurement = measurements[i];
    const { time, spirometry_test_id, source, id, archived_by, source_name } =
      currentMeasurement;

    // not considering empty or test data sources
    if (source && source.toLowerCase() !== "test") {
      const measurementParsedObject = {
        id,
        unformattedTime: dayjs(time).valueOf(),
        [measurementType]: currentMeasurement[measurementType],
        time: getLongFormattedDate(time),
        spirometryTestIds: spirometry_test_id
          ? [spirometry_test_id]
          : undefined,
        source,
        archived_by,
        source_name,
      };

      if (measurementType === "arrhythmia_status") {
        measurementParsedObject.heart_rate = currentMeasurement.heart_rate;
      }

      const existingArray = parsedMeasurements.get(currentMeasurement.source);
      if (existingArray) {
        existingArray.push(measurementParsedObject);
      } else {
        parsedMeasurements.set(currentMeasurement.source, [
          measurementParsedObject,
        ]);
      }
    }
  }

  return {
    type: measurementType,
    measurements: parsedMeasurements,
    mainMeasurementValues: {
      min: roundToX(min, 2),
      max: roundToX(max, 2),
      minDate: minDate ? dayjs(minDate) : undefined,
      maxDate: maxDate ? dayjs(maxDate) : undefined,
      average: roundToX(average, 2),
      lastSpirometryTestId,
    },
    patientBaselines,
  };
};

export function useMeasurement({
  type,
  patientId,
  zoomedVersion,
  fromDate,
  toDate,
  filter,
  hideFvcRejected,
  hideFev1Rejected,
  hideFvcUsable,
  hideFev1Usable,
}: GetPatientMeasurementProps) {
  const { getDateRangeFilter } = useUserPreferences();
  const dateRangeFilter = getDateRangeFilter();

  const { data, isError, isInitialLoading, isFetching, refetch, isRefetching } =
    useQuery({
      queryKey: [
        `measurement-${type}-${patientId}-${zoomedVersion}`,
        type,
        toDate,
        fromDate,
      ],
      queryFn: async () => {
        const serverResponse = await measurementsService.getPatientMeasurement({
          type,
          patientId,
          toDate: toDate || dateRangeFilter.startDate.format(),
          fromDate: fromDate || dateRangeFilter.endDate.format(),
          filter,
          hideFvcRejected,
          hideFev1Rejected,
          hideFvcUsable,
          hideFev1Usable,
        });

        return parseDataAndGetMainMeasurementValues({
          response: serverResponse.data,
          measurementType: type,
        });
      },
      refetchOnMount: "always",
      enabled: type !== "-",
    });

  return {
    measurements: data?.measurements,
    mainMeasurementValues: data?.mainMeasurementValues,
    isError,
    isFetching,
    isInitialLoading,
    refetch,
    isRefetching,
  };
}

export function useBloodPressureMeasurement({
  patientId,
  fromDate,
  toDate,
  filter,
}: GetPatientBloodPressureMeasurementProps) {
  const { getDateRangeFilter } = useUserPreferences();
  const dateRangeFilter = getDateRangeFilter();

  const systolicQuery = async () => {
    const serverResponse = await measurementsService.getPatientMeasurement({
      type: "bp_systolic",
      patientId,
      toDate: toDate || dateRangeFilter.startDate.format(),
      fromDate: fromDate || dateRangeFilter.endDate.format(),
      filter,
    });

    return parseDataAndGetMainMeasurementValues({
      response: serverResponse.data,
      measurementType: "bp_systolic",
    });
  };

  const diastolicQuery = async () => {
    const serverResponse = await measurementsService.getPatientMeasurement({
      type: "bp_diastolic",
      patientId,
      toDate: toDate || dateRangeFilter.startDate.format(),
      fromDate: fromDate || dateRangeFilter.endDate.format(),
      filter,
    });

    return parseDataAndGetMainMeasurementValues({
      response: serverResponse.data,
      measurementType: "bp_diastolic",
    });
  };

  const results = useQueries({
    queries: [
      {
        queryKey: [`systolic-${patientId}`],
        queryFn: systolicQuery,
        refetchOnMount: "always",
      },
      {
        queryKey: [`diastolic-${patientId}`],
        queryFn: diastolicQuery,
        refetchOnMount: "always",
      },
    ],
  });

  const [systolic, diastolic] = results;

  return {
    systolic: systolic.data?.measurements,
    diastolic: diastolic.data?.measurements,
    mainMeasurementValuesSysitolic: systolic.data?.mainMeasurementValues,
    mainMeasurementValuesDiastolic: diastolic.data?.mainMeasurementValues,
    isError: diastolic.isError && systolic.isError,
    isFetching: diastolic.isFetching && systolic.isFetching,
    isInitialLoading: diastolic.isInitialLoading && systolic.isInitialLoading,
    refetch: () => {
      systolic.refetch();
      diastolic.refetch();
    },
    isRefetching: diastolic.isRefetching && systolic.isRefetching,
  };
}

export function useMultipleMeasurements({
  types,
  patientId,
  zoomedVersion,
  fromDate,
  toDate,
  filter,
  hideFvcRejected,
  hideFev1Rejected,
  hideFvcUsable,
  hideFev1Usable,
}: GetMultiplePatientMeasurementProps) {
  const { getDateRangeFilter } = useUserPreferences();
  const queries: any[] = [];

  const dateRangeFilter = getDateRangeFilter();

  types.forEach((type) => {
    const measurementQuery = async () => {
      const serverResponse = await measurementsService.getPatientMeasurement({
        type,
        patientId,
        toDate: toDate || dateRangeFilter.endDate.format(),
        fromDate: fromDate || dateRangeFilter.startDate.format(),
        filter,
        hideFvcRejected,
        hideFev1Rejected,
        hideFvcUsable,
        hideFev1Usable,
      });

      return parseDataAndGetMainMeasurementValues({
        response: serverResponse.data,
        measurementType: type,
      });
    };

    queries.push({
      queryKey: [`${type}-${patientId}-${zoomedVersion}`],
      queryFn: measurementQuery,
      refetchOnMount: "always",
    });
  });

  const results = useQueries({
    queries,
  });

  return {
    data: results.map((r) => r.data),
    isError: results.some((r) => r.isError),
    isFetching: results.some((r) => r.isFetching),
    isInitialLoading: results.some((r) => r.isInitialLoading),
    isRefetching: results.some((r) => r.isRefetching),
    refetch: () => {
      results.forEach((r) => r.refetch());
    },
  };
}
