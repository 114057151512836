import { ColorPicker, Select } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { FormikProps, getIn } from "formik";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import {
  ISurveyDisplayRules,
  ISurveySection,
} from "../../../../../../../@types/Surveys";
import { Close } from "../../../../../../../assets/icons/Close";
import { Button } from "../../../../../../../components/Button";
import { CustomIconButton } from "../../../../../../../components/CustomIconButton";
import {
  IOption,
  SelectDropdown,
} from "../../../../../../../components/SelectDropdown";
import { TextField } from "../../../../../../../components/TextField";
import { DataSourceTypes } from "../../../../../../../constants/surveys";
import { getRandomHexColor } from "../../../../../../../utils/colorGenerator";
import {
  AddRangeOptionButton,
  colorPickerClassName,
  deleteRangeOptionButtonClassName,
  DisplaySubTitle,
  ErrorMessage,
  MultipleInputsRow,
  RowElement,
} from "../../DisplayTab.styles";
import {
  AnswerPlotSelectCss,
  answerPlotTextFieldCss,
  Divider,
  popupOptionsCss,
} from "./AnswerPlotForm.styles";

interface IAnswerPlotFormProps {
  displaySettingsFormik: FormikProps<ISurveyDisplayRules>;
  sections: ISurveySection[];
  addLegend: () => void;
  addLabel: () => void;
}

export function AnswerPlotForm({
  displaySettingsFormik,
  sections,
  addLegend,
  addLabel,
}: IAnswerPlotFormProps) {
  const [answerOptions, setAnswerOptions] = useState<IOption[]>([]);
  const [questionOptions, setQuestionOptions] = useState<IOption[]>([]);

  const dataSourceOptions: IOption[] = [
    {
      label: DataSourceTypes.Questions,
      value: DataSourceTypes.Questions,
      key: uuidv4(),
    },
    {
      label: DataSourceTypes.Answers,
      value: DataSourceTypes.Answers,
      key: uuidv4(),
    },
  ];

  const getAvailableSurveyQuestionOptions = () => {
    const availableSurveyQuestionOptions: IOption[] = sections
      .flatMap((section) => section.questions ?? [])
      .filter(
        (question) =>
          question.type === "SingleSelect" &&
          (question.options ?? []).some((option) =>
            displaySettingsFormik.values.yAxisLabels?.some((label) =>
              label.labelIds?.includes(option.id as string)
            )
          )
      )
      .map((question) => {
        return {
          label: question.text,
          value: question.id,
          key: uuidv4(),
        };
      });
    return availableSurveyQuestionOptions as DefaultOptionType[];
  };

  const getAvailableSurveyAnswerOptions = () => {
    const availableSurveyAnswerOptions = sections
      .flatMap((section) => section.questions ?? [])
      .filter(
        (question) =>
          question.type === "SingleSelect" &&
          displaySettingsFormik.values.yAxisLabels?.some((label) =>
            label.labelIds?.includes(question.id as string)
          )
      )
      .map((question) => {
        const availableQuestion: any = {
          label: question.text,
          title: question.id,
          options: [],
        };

        question.options?.forEach((option) => {
          availableQuestion.options.push({
            label: option.label,
            value: option.id,
          });
        });

        return availableQuestion;
      });
    return availableSurveyAnswerOptions as DefaultOptionType[];
  };

  const getAllSurveyAnswersAndQuestionsToArray = () => {
    const updatedAnswerOptions: IOption[] = [];
    const updatedQuestionOptions: IOption[] = [];
    sections.forEach((section) => {
      section.questions.forEach((question) => {
        updatedQuestionOptions.push({
          label: question.text,
          value: question.id,
          key: uuidv4(),
        });

        if (question.type === "SingleSelect" && question.options) {
          const availableQuestion: any = {
            label: question.text,
            title: question.id,
            options: [],
          };
          question.options.forEach((option) => {
            availableQuestion.options.push({
              label: option.label,
              value: option.id,
            });
          });
          updatedAnswerOptions.push(availableQuestion);
        }
      });
    });
    setAnswerOptions(updatedAnswerOptions);
    setQuestionOptions(updatedQuestionOptions);
  };

  const deleteLegendOrLabel = (
    indexToRemove: number,
    type: "legend" | "label"
  ) => {
    if (type === "legend") {
      displaySettingsFormik.setFieldValue(
        "legends",
        displaySettingsFormik.values.legends?.filter(
          (_, index) => index !== indexToRemove
        )
      );
    } else {
      displaySettingsFormik.setFieldValue(
        "yAxisLabels",
        displaySettingsFormik.values.yAxisLabels?.filter(
          (_, index) => index !== indexToRemove
        )
      );
    }
  };

  const shouldShowErrorMessageLabels = (
    field: "labelIds" | "text",
    index: number
  ) => {
    if (
      Array.isArray(displaySettingsFormik.touched.yAxisLabels) &&
      displaySettingsFormik.touched.yAxisLabels?.[index]
    ) {
      return displaySettingsFormik.touched.yAxisLabels?.[index][field]
        ? getIn(displaySettingsFormik.errors.yAxisLabels?.[index], field)
        : "";
    }
    return "";
  };

  const shouldShowErrorMessageLegends = (
    field: "legendIds" | "text" | "color",
    index: number
  ) => {
    if (
      Array.isArray(displaySettingsFormik.touched.legends) &&
      displaySettingsFormik.touched.legends?.[index]
    ) {
      return displaySettingsFormik.touched.legends?.[index][field]
        ? getIn(displaySettingsFormik.errors.legends?.[index], field)
        : "";
    }
    return "";
  };

  useEffect(() => {
    getAllSurveyAnswersAndQuestionsToArray();
  }, [sections]);

  return (
    <>
      <RowElement>
        <DisplaySubTitle>Y-axis data source*</DisplaySubTitle>
        <SelectDropdown
          className={AnswerPlotSelectCss()}
          popUpCsss={popupOptionsCss()}
          width={248}
          height={42}
          showSearch
          optionFilterProp="label"
          value={displaySettingsFormik.values.dataSourceType ?? ""}
          onValueChange={(value) => {
            displaySettingsFormik.setFieldValue("dataSourceType", value);
            displaySettingsFormik.setFieldValue("yAxisLabels", [
              {
                labelIds: undefined,
                text: undefined,
              },
            ]);
            displaySettingsFormik.setFieldValue("legends", [
              {
                legendIds: undefined,
                text: undefined,
                color: getRandomHexColor(),
              },
            ]);
          }}
          options={dataSourceOptions}
        />
      </RowElement>
      {displaySettingsFormik.values.yAxisLabels !== undefined &&
        displaySettingsFormik.values.yAxisLabels.map((_, index) => (
          <MultipleInputsRow key={index}>
            <RowElement>
              <DisplaySubTitle>{`Y-axis label ${index + 1}*`}</DisplaySubTitle>
              <Select
                maxTagTextLength={20}
                maxTagCount={1}
                mode="multiple"
                className={`${AnswerPlotSelectCss()} nopan nodrag`}
                popupClassName={popupOptionsCss()}
                showSearch
                optionFilterProp="label"
                value={displaySettingsFormik.values.yAxisLabels?.[
                  index
                ].labelIds?.map(String)}
                onChange={(value) => {
                  displaySettingsFormik.setFieldValue(
                    `yAxisLabels[${index}].labelIds`,
                    (value as string[])?.map(String)
                  );
                }}
                options={
                  displaySettingsFormik.values.dataSourceType ===
                  DataSourceTypes.Questions
                    ? questionOptions
                    : answerOptions
                }
              />
              <ErrorMessage>
                {shouldShowErrorMessageLabels("labelIds", index)}
              </ErrorMessage>
            </RowElement>
            <RowElement>
              <DisplaySubTitle>{`Label ${index + 1} text*`}</DisplaySubTitle>
              <TextField
                label=""
                placeholder="Add label"
                name={`yAxisLabels[${index}].text`}
                value={
                  displaySettingsFormik.values.yAxisLabels?.[index].text ?? ""
                }
                onChange={displaySettingsFormik.handleChange}
                className={answerPlotTextFieldCss()}
                backgroudColor="white"
                onBlur={displaySettingsFormik.handleBlur}
              />
              <ErrorMessage>
                {shouldShowErrorMessageLabels("text", index)}
              </ErrorMessage>
            </RowElement>
            {(displaySettingsFormik.values.yAxisLabels?.length ?? 0) > 1 && (
              <CustomIconButton
                className={deleteRangeOptionButtonClassName()}
                key={index}
                onClick={() => deleteLegendOrLabel(index, "label")}
              >
                <Close />
              </CustomIconButton>
            )}
          </MultipleInputsRow>
        ))}
      <RowElement>
        <Button
          type="button"
          label="Add label"
          className={AddRangeOptionButton()}
          onClick={addLabel}
        />
      </RowElement>
      <Divider />
      {displaySettingsFormik.values.legends !== undefined &&
        displaySettingsFormik.values.legends.map((_, index) => (
          <MultipleInputsRow key={index}>
            <RowElement>
              <DisplaySubTitle>{`Legend ${index + 1}*`}</DisplaySubTitle>

              <Select
                maxTagTextLength={20}
                maxTagCount={1}
                mode="multiple"
                className={`${AnswerPlotSelectCss()} nopan nodrag`}
                popupClassName={popupOptionsCss()}
                showSearch
                optionFilterProp="label"
                value={displaySettingsFormik.values.legends?.[
                  index
                ].legendIds?.map(String)}
                onChange={(value) => {
                  displaySettingsFormik.setFieldValue(
                    `legends[${index}].legendIds`,
                    (value as string[])?.map(String)
                  );
                }}
                options={
                  displaySettingsFormik.values.dataSourceType ===
                  DataSourceTypes.Questions
                    ? getAvailableSurveyAnswerOptions()
                    : getAvailableSurveyQuestionOptions()
                }
              />
              <ErrorMessage>
                {shouldShowErrorMessageLegends("legendIds", index)}
              </ErrorMessage>
            </RowElement>
            <RowElement>
              <DisplaySubTitle>{`Label ${index + 1} text*`}</DisplaySubTitle>
              <TextField
                label=""
                placeholder="Add legend"
                name={`legends[${index}].text`}
                value={displaySettingsFormik.values.legends?.[index].text ?? ""}
                onChange={displaySettingsFormik.handleChange}
                className={answerPlotTextFieldCss()}
                backgroudColor="white"
                onBlur={displaySettingsFormik.handleBlur}
              />
              <ErrorMessage>
                {shouldShowErrorMessageLegends("text", index)}
              </ErrorMessage>
            </RowElement>
            <RowElement>
              <DisplaySubTitle>Color *</DisplaySubTitle>
              <ColorPicker
                className={colorPickerClassName()}
                value={displaySettingsFormik.values.legends?.[index].color}
                onChange={(value) => {
                  displaySettingsFormik.setFieldValue(
                    `legends[${index}].color`,
                    value.toHexString()
                  );
                }}
                disabledFormat
                showText
                defaultValue="black"
                disabledAlpha
              />
              <ErrorMessage>
                {shouldShowErrorMessageLegends("color", index)}
              </ErrorMessage>
            </RowElement>
            {(displaySettingsFormik.values.legends?.length ?? 0) > 1 && (
              <CustomIconButton
                className={deleteRangeOptionButtonClassName()}
                key={index}
                onClick={() => deleteLegendOrLabel(index, "legend")}
              >
                <Close />
              </CustomIconButton>
            )}
          </MultipleInputsRow>
        ))}
      <RowElement>
        <Button
          type="button"
          label="Add legend"
          className={AddRangeOptionButton()}
          onClick={addLegend}
        />
      </RowElement>
    </>
  );
}
