import { Tooltip } from "antd";
import dayjs from "dayjs";
import AdvancedFormat from "dayjs/plugin/advancedFormat";
import { useEffect, useRef, useState } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { v4 as uuidv4 } from "uuid";

import {
  IPromsLineGraphData,
  IPromsRange,
} from "../../../../../../../@types/Proms";
import { getGraphSeparators } from "../../../../../../../utils/chartUtils";
import { DateFilterOptionType } from "../../../../MeasurementModal/components/CustomOptionsBar";
import { CustomPromsGraphDot } from "./components/CustomPromsGraphDot/CustomPromsGraphDot";
import { CustomPromsGraphLegend } from "./components/CustomPromsGraphLegend/CustomPromsGraphLegend";

type PromsLineGraphsProps = {
  data: IPromsLineGraphData[];
  minY: number;
  maxY: number;
  dateInterval: DateFilterOptionType;
  mainValues: any;
  ranges: IPromsRange[];
  showValues: boolean;
  selectedId?: string;
  onDotClick: (surveyId: number) => void;
};

dayjs.extend(AdvancedFormat);

export function PromsLineGraph({
  data,
  minY,
  maxY,
  dateInterval,
  mainValues,
  ranges,
  selectedId,
  showValues,
  onDotClick,
}: PromsLineGraphsProps) {
  const { graphSeparators, dates, separatorCounter, tickFormat, viewType } =
    getGraphSeparators(dateInterval, mainValues.minDate, mainValues.maxDate);

  const [selectedSurveyId, setSelectedSurveyId] = useState(selectedId);
  const [visibleTooltipId, setVisibleTooltipId] = useState(null);

  const chartRef = useRef<HTMLDivElement>(null);
  const [chartHeight, setChartHeight] = useState(300);

  let referenceTicks: number[] = [];

  const refs: number[] = [
    ...graphSeparators,
    ...dates.filter((_, index) => index % separatorCounter === 0),
  ];

  referenceTicks = [...refs];

  const formattedData = data.map((item) => ({
    ...item,
    time: dayjs(item.time).valueOf(),
  }));

  useEffect(() => {
    if (chartRef.current) {
      const { height } = chartRef.current.getBoundingClientRect();
      setChartHeight(height - 179);
    }
  }, [formattedData]);

  const minYValue = Math.min(...formattedData.map((d) => d.totalScore));
  const maxYValue = Math.max(...formattedData.map((d) => d.totalScore));

  const step = 0.1;
  const yValues = Array.from(
    { length: Math.floor((maxY - minY) / step) + 1 },
    (_, i) => (minY + i * step).toFixed(2)
  ).map(Number);

  const compare = (value: number, target: number, comparator: string) => {
    switch (comparator) {
      case ">":
        return value > target;
      case ">=":
        return value >= target;
      case "<":
        return value < target;
      case "<=":
        return value <= target;
      default:
        return false;
    }
  };

  const getColor = (value: number) => {
    const range = ranges.find((r) => {
      if (r.startComparator === "=") {
        return value === r.start;
      }
      return (
        compare(value, r.start, r.startComparator) &&
        (r.endComparator
          ? compare(value, r.end ?? r.start, r.endComparator)
          : true)
      );
    });
    return range ? range.color : "#000";
  };

  const tickXFormatter = (value: string) => {
    if (!data || !data.length) return "";

    if (
      graphSeparators.includes(Number(value)) ||
      referenceTicks.includes(Number(value))
    ) {
      if (tickFormat === "Q") {
        return (
          value &&
          `Q${dayjs(value).format(tickFormat)}, ${dayjs(value).format("YY")}`
        );
      }

      return value && dayjs(value).format(tickFormat);
    }

    return "";
  };

  const handleXDomain = () => {
    if (!dateInterval || !data) return [0, 1];

    const { minDate, maxDate } = mainValues;
    let { startDate, endDate } = dateInterval;

    if (minDate && maxDate && endDate.diff(startDate, "year") > 5) {
      startDate = minDate;
      endDate = maxDate;
    }

    if (endDate.diff(startDate, "months") > 11) {
      endDate = endDate.add(1, "month");
    }

    return [startDate.valueOf(), endDate.valueOf()];
  };

  return (
    <ResponsiveContainer
      ref={chartRef}
      width="100%"
      height="100%"
      maxHeight={500}
      style={{ paddingTop: "24px" }}
    >
      <LineChart data={formattedData} margin={{ right: 20, top: 8 }}>
        <defs>
          <linearGradient id="dynamicLineGradient" x1="0" y1="1" x2="0" y2="0">
            {yValues.map((yValue) => {
              const offset =
                ((yValue - minYValue) / (maxYValue - minYValue)) * 100;
              return (
                <stop
                  key={yValue}
                  offset={`${offset}%`}
                  stopColor={getColor(yValue)}
                />
              );
            })}
          </linearGradient>
          <linearGradient id="yAxisGradient" x1="0" y1="1" x2="0" y2="0">
            {yValues.map((yValue) => {
              const min = Math.min(minYValue, minY);
              const max = Math.max(maxYValue, maxY);

              const offset = ((yValue - min) / (max - min)) * 100;
              return (
                <stop
                  key={yValue}
                  offset={`${offset}%`}
                  stopColor={getColor(yValue)}
                />
              );
            })}
          </linearGradient>
        </defs>

        <CartesianGrid strokeDasharray="3 3" vertical={false} />

        <YAxis
          tickMargin={10}
          dataKey="totalScore"
          stroke="#D8D8D8"
          tickCount={11}
          axisLine={false}
          allowDecimals={false}
          width={48}
          domain={[minY, maxY]}
          tick={{
            fill: "#727272",
            fontSize: "12px",
            fontWeight: 400,
          }}
        />

        <XAxis
          tickMargin={14}
          dataKey="time"
          stroke="#D8D8D8"
          type="number"
          domain={handleXDomain()}
          tickFormatter={tickXFormatter}
          ticks={viewType === "yearly" ? graphSeparators : dates}
          tickLine={false}
          allowDecimals={false}
          allowDuplicatedCategory={false}
          height={48}
          minTickGap={100}
          interval={0}
          tick={{
            fill: "#727272",
            fontSize: "12px",
            fontWeight: "400",
          }}
          angle={refs.length >= 15 ? 30 : 0}
        />

        <Legend
          content={
            <CustomPromsGraphLegend
              mainValues={mainValues}
              empty={formattedData.length === 0 || !showValues}
              ranges={ranges}
              showValues={showValues}
            />
          }
        />

        {formattedData && formattedData.length > 0 ? (
          <svg
            width={20}
            height={chartHeight > 0 ? chartHeight : 0}
            x={37.5}
            y={7.5}
          >
            <rect
              width={10}
              height={chartHeight > 0 ? chartHeight : 0}
              fill="url(#yAxisGradient)"
              style={{ opacity: 0.38 }}
            />
          </svg>
        ) : null}

        {refs.map((ref) => (
          <ReferenceLine
            key={ref.toString()}
            x={ref}
            stroke="#D8D8D8"
            strokeDasharray="8 1000"
          />
        ))}

        <Line
          key={uuidv4()}
          type="monotone"
          dataKey="totalScore"
          stroke="url(#dynamicLineGradient)"
          strokeWidth={2}
          strokeOpacity={0.38}
          isAnimationActive={false}
          dot={({ cx, cy, payload }: any) => (
            <Tooltip
              title={`Total: ${payload.totalScore}`}
              arrow={false}
              placement="top"
              zIndex={3000}
              styles={{
                body: {
                  padding: "6px 12px",
                  minHeight: "40px",
                  width: "max-content",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "'Open Sans', sans-serif",
                },
              }}
              mouseEnterDelay={0.1}
              open={visibleTooltipId === payload.id}
            >
              <svg width="100%" height="100%">
                <CustomPromsGraphDot
                  cx={cx}
                  cy={cy}
                  payload={payload}
                  color={getColor(payload.totalScore)}
                  key={uuidv4()}
                  selectedId={selectedSurveyId}
                  onDotClick={() => {
                    setVisibleTooltipId(null);
                    setSelectedSurveyId(payload.id);
                    onDotClick?.(payload.id);
                  }}
                  onMouseOver={() => setVisibleTooltipId(payload.id)}
                  onMouseOut={() => setVisibleTooltipId(null)}
                />
              </svg>
            </Tooltip>
          )}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
