import { useQueryClient } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { Tooltip } from "antd";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Notification } from "../../@types/Notification";
import {
  IPatient,
  IPatientInfo,
  IPatientListVariants,
  PatientListConfig,
  PatientListTableContent,
} from "../../@types/Patient";
import { IPortalPreferences } from "../../@types/Preferences";
import { Dots } from "../../assets/icons/Dots";
import { TableSettings } from "../../assets/icons/TableSettings";
import { AlertsCounter } from "../../components/AlertsCounter";
import { CustomIconButton } from "../../components/CustomIconButton";
import { Dropdown, DropdownTextItem } from "../../components/Dropdown";
import { DropdownRouter } from "../../components/DropdownRouter";
import { ActionContainer } from "../../components/TableActionDropdownButton/TableActionDropdownButton.styles";
import { TableSkeleton } from "../../components/TableSkeleton";
import { Tag } from "../../components/Tag";
import {
  AUTH_PORTAL_PREFERENCES,
  PATIENT_LIST_VARIANT,
  PATIENT_IDS,
} from "../../constants/localStorageKeys";
import { patientListVariants } from "../../constants/patientListVariants";
import { useFlagPatient } from "../../hooks/mutations/patients";
import { usePatientList } from "../../hooks/queries/patients";
import { useArrowsListennerHandler } from "../../hooks/useArrowsListennerHandler";
import useIsMobile from "../../hooks/useIsMobile";
import { useModal } from "../../hooks/useModal";
import { useToast } from "../../hooks/useToast";
import { useUserPreferences } from "../../hooks/useUserPreferences";
import { PageLayout } from "../../layout/components/PageLayout";
import {
  MobilePageTitleContainer,
  PageTitle,
} from "../../layout/components/PageTitle";
import { getRelativeTimeSinceDate } from "../../utils/dateFormatter";
import { mixpanelActions } from "../../utils/mixpanel";
import { capitalizeFirstLetter } from "../../utils/stringFormatter";
import { AddPatientModal } from "./components/AddPatientModal";
import { ArchivePatientModal } from "./components/ArchivePatientModal";
import { PatientListConfigModal } from "./components/PatientListConfigModal";
import { PatientListSortedTable } from "./components/PatientListSortedTable";
import { FlagPatientButton } from "./components/PatientListSortedTable/components/FlagPatientButton/FlagPatientButton";
import { defaultTableColumns } from "./components/PatientListSortedTable/tableColumns";
import {
  AddPatientButton,
  LayoutCss,
  PatientCountContainer,
  PatientListHeaderContainer,
  PatientListTableContainer,
  TableSettingsContainer,
  TagContainer,
} from "./PatientList.styles";

export function PatientListHeader({ variant }: IPatientListVariants) {
  const { isMobile } = useIsMobile();

  useEffect(() => {
    if (variant === patientListVariants.ACTIVE) {
      mixpanelActions.track("Patient List Page: Active");
    } else {
      mixpanelActions.track("Patient List Page: Archived");
    }
  }, [variant]);

  if (isMobile) {
    return (
      <MobilePageTitleContainer>
        <PageTitle>Patients</PageTitle>

        <DropdownRouter childRoutesOf="PatientList" />
      </MobilePageTitleContainer>
    );
  }

  return <PageTitle>{capitalizeFirstLetter(variant)} patients</PageTitle>;
}

export function PatientList({ variant }: IPatientListVariants) {
  const navigate = useNavigate();
  const { disablePatientListArrowNav, enablePatientListArrowNav } =
    useArrowsListennerHandler();
  const { isModalOpen } = useModal();
  const { showToast } = useToast();

  document.title = `${capitalizeFirstLetter(variant)} Patients - patientMpower`;

  const { patients, isLoading, isRefetching, refetch } = usePatientList(
    {
      variant,
    },
    false
  );

  const mutate = useFlagPatient();
  const queryClient = useQueryClient();

  const { openModal, closeModal } = useModal();

  const { changePortalPreferences } = useUserPreferences();
  const [refreshTable, setRefreshTable] = useState(false);
  const [patientListConfig, setPatientListConfig] = useState<PatientListConfig>(
    {
      name: true,
      hospitalId: true,
      pmpId: true,
      alerts: true,
      signUpDate: true,
      lastUsed: true,
      lastReviewedBy: true,
      surveyResults: undefined,
      // ward: false,
      // oxSat: false,
    }
  );
  const [tableColumns, setTableColumns] =
    useState<ColumnDef<any, string>[]>(defaultTableColumns);

  const handleOnModalClose = (notification?: Notification) => {
    if (notification !== undefined) {
      refetch();
      showToast(notification.message, notification.type);
    }

    closeModal();
  };

  const parsePatientsToTable = useMemo(() => {
    if (patients === undefined) return [];

    const handleTagVariant = (value: string) => {
      if (value === "No symptoms") {
        return "success";
      }

      if (value === "Symptoms") {
        return "tertiary";
      }

      return "error";
    };

    const patientIds: number[] = [];

    const getLasUsedDate = (lastUsed: any, lastUsedContent: any) => {
      if (lastUsed !== "-")
        return dayjs(lastUsed, "MMM D, YYYY, hh:mma").toDate();
      if (lastUsedContent === "No data added") return 1;
      if (lastUsedContent === "Password not set") return 0;
      return undefined;
    };

    const patientList = patients?.map((patient: IPatient) => {
      patientIds.push(patient.id);
      let lastUsedContent = "";

      const options: any[] = [
        `${variant === "archived" ? "Reactivate" : "Archive"} patient`,
        "Open in new tab",
      ];

      const handleArchivePatient = (isReactivate = false) => {
        openModal(
          <ArchivePatientModal
            content={patient}
            isReactivate={isReactivate}
            onClose={handleOnModalClose}
          />,
          {
            width: "623px",
            height: "100%",
            showCloseButton: true,
          }
        );
      };
      if (patient.lastUsed !== "-") {
        lastUsedContent = getRelativeTimeSinceDate(patient.lastUsed);
      } else if (patient.isPasswordSet === true) {
        lastUsedContent = "No data added";
      } else {
        lastUsedContent = "Password not set";
      }

      const handleFlagPatient = async (patientId: number, flagged: boolean) => {
        await mutate({
          patientId,
          flagged,
        });

        queryClient.setQueryData(
          ["patient-list-active", "active"],
          (oldData: any) => {
            const updatedData = oldData.map((patient: IPatient) =>
              patient.id === patientId ? { ...patient, flagged } : patient
            );

            return updatedData;
          }
        );

        queryClient.setQueryData(
          [`patient-info-${patientId}`, patientId.toString()],
          (patientInfo?: IPatientInfo) => {
            if (patientInfo) {
              return { ...patientInfo, flagged };
            }
            return patientInfo;
          }
        );
      };

      const getAlertsCount = (alerts: string) => {
        const parsedAlerts = Number(alerts?.toString());
        if (Number.isNaN(parsedAlerts)) {
          return "1";
        }
        return alerts?.toString();
      };

      return {
        id: patient.id,
        name: patient.name,
        flagged: patient.flagged,
        lastUsed: lastUsedContent,
        lastUsedDate: getLasUsedDate(patient.lastUsed, lastUsedContent),
        hospitalId: patient.hospitalId,
        signUpDate: patient.signUpDate,
        signUpdateParsedDate: dayjs(
          patient.signUpDate,
          "MMM D, YYYY, hh:mma"
        ).toDate(),
        lastReviewedBy: patient.lastReviewedBy,
        alertsCount: getAlertsCount(patient.alerts),
        alerts:
          patient.alerts !== "0" ? (
            <Tooltip
              mouseLeaveDelay={0}
              title="Go to alerts section"
              zIndex={3002}
              color="#4B4B4B"
              arrow={false}
              styles={{
                body: {
                  minHeight: "40px",
                  borderRadius: "4px !important",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                },
              }}
            >
              <CustomIconButton
                style={{
                  width: "max-content",
                  margin: "0 10px 0 0",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/patients/${patient.id}/alerts`);
                }}
              >
                <AlertsCounter>{patient.alerts}</AlertsCounter>
              </CustomIconButton>
            </Tooltip>
          ) : (
            "-"
          ),
        surveyReceived: patient.surveyReceived,
        surveyReceivedDate:
          patient.surveyReceived !== "-"
            ? dayjs(patient.surveyReceived, "MMM D, YYYY, hh:mma").toDate()
            : 0,
        surveyResult: patient.surveyResult ? (
          <TagContainer>
            <Tag variant={handleTagVariant(patient.surveyResult)}>
              {patient.surveyResult}
            </Tag>
          </TagContainer>
        ) : null,
        surveyResultStatus:
          patient.surveyResult === null ? "" : patient.surveyResult,
        flag: (
          <div>
            <FlagPatientButton
              onClick={async (e) => {
                e.stopPropagation();

                await handleFlagPatient(patient.id, !patient.flagged);
              }}
              flagged={patient.flagged}
            />
          </div>
        ),
        action: (
          <div>
            <ActionContainer
              className="actionContainer"
              onClick={(e) => e.stopPropagation()}
            >
              <Dropdown
                trigger={
                  <CustomIconButton>
                    <Dots />
                  </CustomIconButton>
                }
                value=""
                onValueChange={(val: string) => {
                  if (val === options[0]) {
                    if (variant === "archived") {
                      mixpanelActions.track(
                        "User Action: ReactivatePatientButton"
                      );
                      handleArchivePatient(true);
                    } else {
                      mixpanelActions.track(
                        "User Action: ArchivePatientButton"
                      );
                      handleArchivePatient();
                    }
                  }
                  if (val === options[1]) {
                    mixpanelActions.track("User Action: OpenInNewTabButton");

                    window.open(
                      `/patients/${patient.id}/${
                        variant === patientListVariants.ALERTS
                          ? "alerts"
                          : "spirometry"
                      }`,
                      "_blank"
                    );
                  }
                }}
              >
                {options.map((option) => (
                  <DropdownTextItem key={option} text={option} />
                ))}
              </Dropdown>
            </ActionContainer>
          </div>
        ),
      } as unknown as PatientListTableContent;
    });

    // Update the patients IDs list to use for patient navigation
    localStorage.setItem(PATIENT_IDS, JSON.stringify(patientIds));

    return patientList;
  }, [patients]);

  const handleColumns = (tableConfig: PatientListConfig) => {
    const columnsToAdd: ColumnDef<any, string>[] = [];
    const temporaryTableConfig = { ...tableConfig };

    if (temporaryTableConfig.name) {
      columnsToAdd.push({
        accessorKey: "name",
        header: () => "Name",
        cell: (info) => info.renderValue(),
        size: 220,
        sortingFn: "text",
      });
    }

    if (temporaryTableConfig.hospitalId) {
      columnsToAdd.push({
        accessorKey: "hospitalId",
        header: () => "Hospital ID",
        cell: (info) => info.renderValue(),
        sortingFn: "text",
      });
    }

    if (temporaryTableConfig.pmpId) {
      columnsToAdd.push({
        accessorKey: "id",
        header: () => "pMp ID",
        cell: (info) => info.renderValue(),
        sortingFn: "basic",
      });
    }

    if (temporaryTableConfig.alerts) {
      columnsToAdd.push({
        accessorKey: "alerts",
        header: () => "Alerts",
        cell: (info) => info.renderValue(),
        sortingFn: (rowA, rowB) => {
          return rowA.original.alertsCount - rowB.original.alertsCount;
        },
      });
    }

    if (temporaryTableConfig.signUpDate) {
      columnsToAdd.push({
        accessorKey: "signUpDate",
        header: () => "Sign up date",
        cell: (info) => info.renderValue(),
        size: 160,
        sortingFn: (rowA, rowB) => {
          return (
            rowB.original.signUpdateParsedDate -
            rowA.original.signUpdateParsedDate
          );
        },
      });
    }

    if (temporaryTableConfig.lastUsed) {
      columnsToAdd.push({
        accessorKey: "lastUsed",
        header: () => "Last used",
        cell: (info) => info.renderValue(),
        size: 160,
        sortingFn: (rowA, rowB) => {
          return rowB.original.lastUsedDate - rowA.original.lastUsedDate;
        },
      });
    }

    if (temporaryTableConfig.lastReviewedBy) {
      columnsToAdd.push({
        accessorKey: "lastReviewedBy",
        header: () => "Last reviewed by",
        cell: (info) => info.renderValue(),
        sortingFn: "text",
      });
    }

    if (temporaryTableConfig.surveyResults) {
      columnsToAdd.push(
        {
          accessorKey: "surveyReceived",
          header: () => "Survey received",
          cell: (info) => info.renderValue(),
          size: 160,
          sortingFn: (rowA, rowB) => {
            return (
              rowA.original.surveyReceivedDate -
              rowB.original.surveyReceivedDate
            );
          },
        },

        {
          accessorKey: "surveyResult",
          header: () => "Survey result",
          cell: (info) => info.renderValue(),
          sortingFn: (rowA, rowB) => {
            return rowA.original.surveyResultStatus.localeCompare(
              rowB.original.surveyResultStatus
            );
          },
        }
      );
    }

    columnsToAdd.push({
      accessorKey: "flag",
      header: () => "",
      cell: (info) => info.renderValue(),
      size: 30,
      enableSorting: false,
    });

    columnsToAdd.push({
      accessorKey: "action",
      header: () => "",
      cell: (info) => info.renderValue(),
      size: 30,
      enableSorting: false,
    });

    setTableColumns(columnsToAdd);
    setPatientListConfig(temporaryTableConfig);

    const portalPreferences = localStorage.getItem(AUTH_PORTAL_PREFERENCES);

    if (portalPreferences) {
      const parsedPortalPreferences = portalPreferences
        ? (JSON.parse(portalPreferences) as IPortalPreferences)
        : undefined;

      if (parsedPortalPreferences) {
        const configJson = JSON.stringify(temporaryTableConfig);
        parsedPortalPreferences.patientListConfig = configJson;

        const portalPreferencesJson = JSON.stringify(parsedPortalPreferences);
        localStorage.setItem(AUTH_PORTAL_PREFERENCES, portalPreferencesJson);
        changePortalPreferences(parsedPortalPreferences);
      }
    }

    setRefreshTable(true);
    setTimeout(() => {
      setRefreshTable(false);
    }, 10);
  };

  useEffect(() => {
    const portalPreferences = localStorage.getItem(AUTH_PORTAL_PREFERENCES);

    if (portalPreferences) {
      const parsedPortalPreferences = portalPreferences
        ? (JSON.parse(portalPreferences) as IPortalPreferences)
        : undefined;

      if (
        parsedPortalPreferences?.patientListConfig &&
        parsedPortalPreferences?.patientListConfig !== ""
      ) {
        const parsedPatientListConfig = JSON.parse(
          parsedPortalPreferences?.patientListConfig
        ) as PatientListConfig;

        setPatientListConfig(parsedPatientListConfig);
        handleColumns(parsedPatientListConfig);
      } else if (
        parsedPortalPreferences &&
        parsedPortalPreferences.isBeaumont
      ) {
        const config: PatientListConfig = {
          name: true,
          hospitalId: true,
          pmpId: true,
          alerts: true,
          signUpDate: true,
          lastUsed: true,
          lastReviewedBy: true,
          surveyResults: true,
        };
        setPatientListConfig(config);
        handleColumns(config);
      }
    } else {
      handleColumns(patientListConfig);
    }
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      disablePatientListArrowNav();
    } else {
      enablePatientListArrowNav();
    }
  }, [isModalOpen]);

  const handleOnPatientListConfigClose = async (
    tableConfig: PatientListConfig,
    notification?: Notification,
    refreshTable?: boolean
  ) => {
    closeModal();

    if (refreshTable) {
      if (tableConfig) {
        handleColumns(tableConfig);
      }
    }

    if (notification !== undefined) {
      showToast(notification.message, notification.type);
    }
  };

  const handleAddPatientClick = () => {
    openModal(<AddPatientModal onClose={handleOnModalClose} />, {
      maxHeight: "95vh",
      width: "100%",
      showCloseButton: true,
    });
  };

  const handleTableConfigClick = () => {
    mixpanelActions.track("User Action: ConfigurePatientList");

    openModal(
      <PatientListConfigModal
        columnsConfig={patientListConfig}
        onClose={handleOnPatientListConfigClose}
      />,
      {
        width: "655px",
        maxHeight: "628px",
        showCloseButton: true,
      }
    );
  };

  useEffect(() => {
    localStorage.setItem(PATIENT_LIST_VARIANT, variant);
  }, [variant]);

  return (
    <PageLayout className={LayoutCss()}>
      <PatientListHeaderContainer>
        <PatientListHeader variant={variant} />
        {!isLoading && !isRefetching && (
          <>
            <PatientCountContainer>({patients?.length})</PatientCountContainer>

            <Tooltip
              title="Configure which columns are shown"
              arrow={false}
              placement="right"
              styles={{
                body: {
                  padding: "6px 12px",
                  minHeight: "40px",
                  width: "max-content",
                  minWidth: "220px",
                  borderRadius: "4px !important",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "'Open Sans', sans-serif",
                },
              }}
            >
              <TableSettingsContainer
                role="button"
                tabIndex={0}
                onClick={handleTableConfigClick}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    handleTableConfigClick();
                  }
                }}
              >
                <TableSettings />
              </TableSettingsContainer>
            </Tooltip>
          </>
        )}
        <AddPatientButton onClick={handleAddPatientClick}>
          Add patient
        </AddPatientButton>
      </PatientListHeaderContainer>

      <PatientListTableContainer>
        {!refreshTable ? (
          <PatientListSortedTable
            variant={variant}
            isLoading={isLoading || isRefetching}
            content={parsePatientsToTable}
            columns={tableColumns}
          />
        ) : (
          <TableSkeleton columnsNumber={7} rowsNumber={6} />
        )}
      </PatientListTableContainer>
    </PageLayout>
  );
}
