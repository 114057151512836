import { Tooltip } from "antd";
import dayjs from "dayjs";
import { ReactElement, useEffect, useState } from "react";

import {
  ChartTitle,
  StyledSubtitle,
  StyledTile,
} from "../../../../../../components/ChartCard/ChartCard.styles";
import {
  ChartCardValue,
  ChartData,
} from "../../../../../../components/ChartCard/ChartCard.types";
import { ChartCardLoading } from "../../../../../../components/ChartCard/ChartCardLoading";
import { ChartSpecificValues } from "../../../../../../components/ChartCard/ChartSpecificValues/ChartSpecificValues";
import { Tag } from "../../../../../../components/Tag";
import {
  Divider,
  BkvChartDataContainer,
  BkvMeasurement,
} from "./BkvChartCard.styles";

export type BkvChartCardProps = {
  title: string;
  data: Map<string, ChartData[]> | Map<string, any[]>;
  unit?: string;
  isLoading?: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
};

export function BkvChartCard({
  title,
  data,
  unit,
  isLoading,
  onClick,
}: BkvChartCardProps) {
  const [latestValue, setLatestVaule] = useState<ChartCardValue[]>([]);
  const [measurementComponents, setMeasurementComponents] = useState<
    ReactElement[]
  >([]);

  const isEmptyState = !data || !data.size;

  useEffect(() => {
    const lastMeasurements = !isEmptyState
      ? Array.from(data.values())
          .flat()
          .sort((a: any, b: any) => b.unformattedTime - a.unformattedTime)
          .slice(0, 3)
      : [];

    const measurementsToReturn: any[] = [];

    lastMeasurements.forEach((measurement, index) => {
      const isNumber = !Number.isNaN(Number(measurement.bkv));
      const isNotDetected = measurement.bkv.toLowerCase() === "not detected";

      const value = measurement.bkv ? `${measurement.bkv} ${unit} - ` : "";
      const date = `${dayjs(measurement.unformattedTime).format(
        "MMM DD - HH:mm"
      )}`;

      const valueAndDate = `${isNumber && !isNotDetected ? value : ""}${date}`;

      if (!isEmptyState && index === 0) {
        setLatestVaule([{ label: "Latest", value: measurement.bkv }]);
      }

      const tagColor = isNotDetected ? "success" : "error";

      measurementsToReturn.push(
        <div key={valueAndDate} style={{ width: "100%" }}>
          <BkvMeasurement>
            <p>{valueAndDate}</p>
            <Tooltip
              title={measurement.bkv}
              arrow={false}
              placement="top"
              zIndex={3000}
              styles={{
                body: {
                  padding: "6px 12px",
                  minHeight: "40px",
                  width: "max-content",
                  borderRadius: "4px !important",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "'Open Sans', sans-serif",
                },
              }}
            >
              <div>
                {isNumber || (
                  <Tag variant={tagColor} clickable={false}>
                    {measurement.bkv}
                  </Tag>
                )}
              </div>
            </Tooltip>
          </BkvMeasurement>

          {index !== lastMeasurements.length - 1 ? (
            <Divider key={`${valueAndDate}-divider`} />
          ) : null}
        </div>
      );
    });

    setMeasurementComponents(measurementsToReturn);
  }, [data]);

  return (
    <StyledTile onClick={onClick}>
      {isLoading ? (
        <ChartCardLoading />
      ) : (
        <>
          <ChartTitle>{title}</ChartTitle>
          {isEmptyState ? (
            <StyledSubtitle>No data for selected dates</StyledSubtitle>
          ) : null}

          <BkvChartDataContainer>{measurementComponents}</BkvChartDataContainer>
          <ChartSpecificValues values={latestValue} />
        </>
      )}
    </StyledTile>
  );
}
