import { useEffect, useRef } from "react";
import CacheBuster from "react-cache-buster";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useRoutes } from "react-router-dom";
import { ReactFlowProvider } from "reactflow";

import packageJson from "../package.json";
import { Modal } from "./components/Modal";
import { ToastProvider } from "./contexts/ToastContext";
import { useAuth } from "./hooks/useAuth";
import { useIdleTimeout } from "./hooks/useIdleTimeout";
import { useModal } from "./hooks/useModal";
import { globalStyles } from "./layout/styles/global";
import { routes } from "./routes/routes";
import "./config/i18n";
import { css } from "./styles";

const containerStyles = css({
  width: "100%",
  height: "100vh",
  fontFamily: "$openSans",
});

// TODO: try to remove all the any values.
// TODO: Improve all the types from the app
// TODO: Add history and redirects

function App() {
  const routeElements = useRoutes(routes);
  const { isModalOpen, modalContent, closeModal, optionsModal } = useModal();

  const { isAuthenticated, signOut } = useAuth();
  const idleTime: number =
    Number(String(process.env.REACT_APP_IDLE_TIMEOUT_IN_MINUTES)) || 15;

  const componentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!isModalOpen && componentRef.current) {
      componentRef.current.blur();
    }
  }, [isModalOpen]);

  useIdleTimeout({
    onIdle: () => {
      if (isAuthenticated) {
        signOut();
      }
    },
    idleTime,
  });

  globalStyles();

  return (
    <CacheBuster
      currentVersion={packageJson.version}
      isEnabled
      isVerboseMode={false}
      metaFileDirectory="."
    >
      <ToastProvider>
        <div className={containerStyles()} ref={componentRef}>
          <Modal
            open={isModalOpen}
            optionsModal={optionsModal}
            onOpenChange={() => {
              if (isModalOpen) {
                closeModal();
              }
            }}
          >
            {modalContent}
          </Modal>
          <ReactFlowProvider>
            <DndProvider backend={HTML5Backend}>{routeElements}</DndProvider>
          </ReactFlowProvider>
        </div>
      </ToastProvider>
    </CacheBuster>
  );
}
export default App;
