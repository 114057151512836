import { IPromsTableData } from "../../../../../@types/Proms";
import { ReactComponent as PromsEmptyState } from "../../../../../assets/images/proms-empty-state.svg";
import { TableSkeleton } from "../../../../../components/TableSkeleton";
import { TableSorted } from "../../../../../components/TableSorted";
import { useModal } from "../../../../../hooks/useModal";
import { promsTableColumns } from "../promsTableColumns";
import { PromDetailsModal } from "./components/PromDetailsModal";
import {
  ImageContainer,
  NoContentMessage,
  PromsTableContainer,
  PromsTableInnerContainer,
} from "./PatientPromsTable.style";

type PatientNotesTableProps = {
  patientId: number;
  content: IPromsTableData[];
  isLoading?: boolean;
  isError?: boolean;
  refreshTable: () => void;
};

export function PatientPromsTable({
  patientId,
  isLoading,
  isError,
  content,
  refreshTable,
}: PatientNotesTableProps) {
  const { openModal } = useModal();

  if (isLoading) {
    return <TableSkeleton columnsNumber={4} />;
  }

  if (isError) {
    return (
      <ImageContainer>
        <p>
          Error getting PROMs, please{" "}
          <button type="button" onClick={refreshTable}>
            try again
          </button>
          . If the issue continues contact support.
        </p>
      </ImageContainer>
    );
  }

  const handleRowClick = async (rowData: IPromsTableData) => {
    openModal(<PromDetailsModal patientId={patientId} formData={rowData} />, {
      width: "auto",
      maxHeight: "740px",
      minHeight: "683px",
      maxWidth: "98vw",
      showCloseButton: true,
    });
  };

  if (content && content.length > 0) {
    return (
      <PromsTableContainer>
        <PromsTableInnerContainer>
          <TableSorted
            data={content}
            columns={promsTableColumns}
            onRowClick={handleRowClick}
          />
        </PromsTableInnerContainer>
      </PromsTableContainer>
    );
  }

  return (
    <ImageContainer>
      <PromsEmptyState width={181} height={158} />
      <NoContentMessage>
        No PROMs set up. Assign a PROM to get a more complete picture of
        patient’s health.
      </NoContentMessage>
    </ImageContainer>
  );
}
