import { useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { IArticle, IArticlesTableContent } from "../../@types/Articles";
import { Notification } from "../../@types/Notification";
import { CircleChecked } from "../../assets/icons/CircleChecked";
import { CircleUnchecked } from "../../assets/icons/CircleUncheked";
import { IOption, SelectDropdown } from "../../components/SelectDropdown";
import { AUTH_USER_DETAILS } from "../../constants/localStorageKeys";
import { useArticles } from "../../hooks/queries/articles";
import { useToast } from "../../hooks/useToast";
import { PageLayout } from "../../layout/components/PageLayout";
import { PageTitle } from "../../layout/components/PageTitle";
import { articlesSerivce } from "../../services/articlesService";
import { hospitalService } from "../../services/hospitalService";
import { getLongFormattedDate } from "../../utils/dateFormatter";
import { mixpanelActions } from "../../utils/mixpanel";
import {
  AddArticleButton,
  ArticlesContainer,
  ArticlesTableContainer,
  HospitalGroupSelectCss,
  PageTitleContainer,
  PMPHospitalGroupSelect,
} from "./CareInfo.styles";
import { ArticleBuilder } from "./components/ArticleBuilder";
import { ArticlesTable } from "./components/ArticlesTable";

export function CareInfo() {
  const { showToast } = useToast();

  const [hospitalGroupOptions, setHospitalGroupOptions] = useState<IOption[]>(
    []
  );
  const [selectedHospitalGroup, setSelectedHospitalGroup] = useState<
    string | undefined
  >(undefined);
  const [isArticleBuilderOpen, setIsArticleBuilderOpen] = useState(false);
  const [isAddArticle, setIsAddArticle] = useState(false);
  const [isPMPUser, setIsPMPUser] = useState(false);
  const [articleContent, setArticleContent] = useState<IArticle>();
  const { articles, isFetching, isRefetching, refetch } = useArticles(
    Number.parseInt(selectedHospitalGroup ?? "", 10)
  );

  useEffect(() => {
    mixpanelActions.track("PageView: Care Info");

    hospitalService.getHospitalGroups().then((hospitalGroups) => {
      if (hospitalGroups.data === null) return;

      const groupsToAdd: IOption[] = [];

      hospitalGroups.data.forEach((group) => {
        groupsToAdd.push({
          label: group.hospitalGroupName,
          value: group.hospitalGroupId.toString(),
          key: uuidv4(),
        });
      });

      setHospitalGroupOptions(groupsToAdd);
    });

    const localStorageUserDetails =
      localStorage.getItem(AUTH_USER_DETAILS) ?? "";
    const parsedUserDetails = JSON.parse(localStorageUserDetails);

    const userEmail = parsedUserDetails?.userEmail as string;

    setIsPMPUser(userEmail.includes("patientmpower"));
  }, []);

  const parsedArticles = useMemo(() => {
    if (!articles || articles.length === 0) return [];

    return articles?.map((article) => {
      return {
        id: article.id,
        title: article.title,
        cardImage: <div>{article.cardImage}</div>,
        numberOfReads: article.numberOfReads,
        tags: article.tags,
        lastEdited: getLongFormattedDate(article.lastEdited),
        author: article.author,
        wards: article.wards,
        published: (
          <div style={{ marginLeft: "30px" }}>
            {" "}
            {article.published ? <CircleChecked /> : <CircleUnchecked />}
          </div>
        ),
      } as IArticlesTableContent;
    });
  }, [articles]);

  const handleAddArticle = () => {
    mixpanelActions.track("PageView: ContentTab");
    setIsAddArticle(true);
    setArticleContent(undefined);
    setIsArticleBuilderOpen(true);
  };

  const handleOnRowClick = async (rowContent: IArticlesTableContent) => {
    const response = await articlesSerivce.getById(rowContent.id);

    if (response.data) {
      mixpanelActions.track(`PageView: ContentTab[${response.data.title}]`);
      setIsAddArticle(false);
      setArticleContent(response.data);
      setIsArticleBuilderOpen(true);
    }
  };

  const handleOnBuilderClose = (
    notification?: Notification,
    refetchTable?: boolean
  ) => {
    if (notification) {
      showToast(notification.message, notification.type);
    }

    if (refetchTable) {
      refetch();
    }

    window.onpopstate = null;

    setIsArticleBuilderOpen(false);
  };

  if (isArticleBuilderOpen) {
    return (
      <ArticleBuilder
        isAddArticle={isAddArticle}
        content={articleContent}
        hospitalGrouId={parseInt(selectedHospitalGroup ?? "", 10)}
        onClose={handleOnBuilderClose}
      />
    );
  }

  return (
    <PageLayout>
      <ArticlesContainer>
        <PageTitleContainer>
          <PageTitle>Articles</PageTitle>
        </PageTitleContainer>

        <ArticlesTableContainer>
          {isPMPUser ? (
            <PMPHospitalGroupSelect>
              <SelectDropdown
                options={hospitalGroupOptions ?? []}
                height={30}
                placeholder="Select hospital"
                optionFilterProp="label"
                value={selectedHospitalGroup}
                onValueChange={(value: string | string[]) => {
                  setSelectedHospitalGroup(value.toString());
                }}
                className={HospitalGroupSelectCss()}
              />
            </PMPHospitalGroupSelect>
          ) : null}
          <AddArticleButton onClick={handleAddArticle}>
            Add article
          </AddArticleButton>
          <ArticlesTable
            content={parsedArticles}
            isLoading={isFetching || isRefetching}
            onRowClick={handleOnRowClick}
          />
        </ArticlesTableContainer>
      </ArticlesContainer>
    </PageLayout>
  );
}
