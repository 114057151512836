import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  ISurveyAnswer,
  ISurveyQuestionOption,
  IOxygenQuestion,
} from "../../../../../../../@types/Surveys";
import { RadioButton } from "../../../../../../../components/RadioButton";
import { useDynamicsurveys } from "../../../../../../../hooks/useDynamicSurveys";
import { RadioButtonContainer } from "../SingleSelectQuestionDisplay/SingleSelectQuestionDisplay.styles";
import { FormAnswerField } from "../TextQuestionDisplay/TextQuestionDisplay.styles";
import {
  SubQuestionContainer,
  SubQestionDescription,
  FiveQuestionsOptionsSelectionContainer,
  ClickableOption,
} from "./FiveQuestionsAboutSupOxDisplay.styles";
import { fiveQuestionsOptions } from "./Options";

interface IFiveQuestionProps {
  questionId?: string;
  setIsFiveQuestionsValidated?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function FiveQuestionsAboutSupOxDisplay({
  questionId,
  setIsFiveQuestionsValidated,
}: IFiveQuestionProps) {
  const [selectedIndexQuestion1, setSelectedIndexQuestion1] =
    useState<number>();
  const [selectedIndexQuestion2, setSelectedIndexQuestion2] =
    useState<number>();
  const [selectedIndexQuestion3, setSelectedIndexQuestion3] =
    useState<number>();
  const [selectedIndexQuestion4, setSelectedIndexQuestion4] =
    useState<number>();
  const [selectedIndexQuestion5, setSelectedIndexQuestion5] =
    useState<number>();

  const [indexesOfQuestionsToDisplay, setIndexesOfQuestionsToDisplay] =
    useState<number[]>([0]);

  const [renderSelects, setRenderSelects] = useState<boolean>(false);

  const [initialSelectedValuesAreLoaded, setInitialSelectedValuesAreLoaded] =
    useState<boolean>(false);

  const { surveyAnswerArray, updateSurveyAnswerArray } = useDynamicsurveys();

  const { t } = useTranslation();

  useEffect(() => {
    const storedAnswer = surveyAnswerArray.find(
      (surveyAnswer) => surveyAnswer.surveyQuestionId === questionId
    );

    if (storedAnswer !== undefined && storedAnswer.answerValue !== "") {
      const optionsValuesList = storedAnswer.answerValue.split("|");
      const optionsValuesListParsed = optionsValuesList.map(Number);
      optionsValuesListParsed.forEach((parsedOption, index) => {
        if (parsedOption) {
          switch (index) {
            case 0:
              setSelectedIndexQuestion1(
                fiveQuestionsOptions[index].options.findIndex(
                  (option: any) => option.value === parsedOption
                )
              );
              break;
            case 1:
              setSelectedIndexQuestion2(
                fiveQuestionsOptions[index].options.findIndex(
                  (option: any) => option.value === parsedOption
                )
              );
              break;
            case 2:
              setSelectedIndexQuestion3(
                fiveQuestionsOptions[index].options.findIndex(
                  (option: any) => option.value === parsedOption
                )
              );
              break;
            case 3:
              setSelectedIndexQuestion4(
                fiveQuestionsOptions[index].options.findIndex(
                  (option: any) => option.value === parsedOption
                )
              );
              break;
            case 4:
              setSelectedIndexQuestion5(
                fiveQuestionsOptions[index].options.findIndex(
                  (option: any) => option.value === parsedOption
                )
              );
              break;
            default:
          }
        }
      });
    }
    setRenderSelects(true);
  }, []);

  useEffect(() => {
    if (renderSelects === true) setInitialSelectedValuesAreLoaded(true);
  }, [renderSelects]);

  useEffect(() => {
    if (initialSelectedValuesAreLoaded === true) {
      setSelectedIndexQuestion2(undefined);
      setSelectedIndexQuestion3(undefined);
      setSelectedIndexQuestion4(undefined);
      setSelectedIndexQuestion5(undefined);
    }
  }, [selectedIndexQuestion1]);

  useEffect(() => {
    if (initialSelectedValuesAreLoaded === true) {
      setSelectedIndexQuestion3(undefined);
      setSelectedIndexQuestion4(undefined);
      setSelectedIndexQuestion5(undefined);
    }
  }, [selectedIndexQuestion2]);

  const handleClick = (questionIndex: number, optionIndex: number) => {
    switch (questionIndex) {
      case 0:
        if (selectedIndexQuestion1 !== optionIndex)
          setSelectedIndexQuestion1(optionIndex);
        else {
          setSelectedIndexQuestion1(undefined);
        }
        break;
      case 1:
        if (selectedIndexQuestion2 !== optionIndex)
          setSelectedIndexQuestion2(optionIndex);
        else {
          setSelectedIndexQuestion2(undefined);
        }
        break;
      case 2:
        if (selectedIndexQuestion3 !== optionIndex)
          setSelectedIndexQuestion3(optionIndex);
        else {
          setSelectedIndexQuestion3(undefined);
        }
        break;
      case 3:
        if (selectedIndexQuestion4 !== optionIndex)
          setSelectedIndexQuestion4(optionIndex);
        else {
          setSelectedIndexQuestion4(undefined);
        }
        break;
      case 4:
        if (selectedIndexQuestion5 !== optionIndex)
          setSelectedIndexQuestion5(optionIndex);
        else {
          setSelectedIndexQuestion5(undefined);
        }
        break;

      default:
    }
  };

  const isItChecked = (questionIndex: number, optionIndex: number): boolean => {
    switch (questionIndex) {
      case 0:
        if (selectedIndexQuestion1 === optionIndex) return true;
        return false;
      case 1:
        if (selectedIndexQuestion2 === optionIndex) return true;
        return false;
      case 2:
        if (selectedIndexQuestion3 === optionIndex) return true;
        return false;
      case 3:
        if (selectedIndexQuestion4 === optionIndex) return true;
        return false;
      case 4:
        if (selectedIndexQuestion5 === optionIndex) return true;
        return false;
      default:
        return false;
    }
  };

  const handleConditionalRender = () => {
    const updatedIndexesToDispaly = [];
    if (selectedIndexQuestion1 !== undefined) {
      if (fiveQuestionsOptions[0].options[selectedIndexQuestion1].value === 1) {
        if (selectedIndexQuestion2 !== undefined) {
          switch (
            fiveQuestionsOptions[1].options[selectedIndexQuestion2].value
          ) {
            case 1:
              updatedIndexesToDispaly.push(0, 1, 2);
              setIndexesOfQuestionsToDisplay(updatedIndexesToDispaly);
              break;
            case 2:
              updatedIndexesToDispaly.push(0, 1, 3);
              setIndexesOfQuestionsToDisplay(updatedIndexesToDispaly);
              break;
            case 3:
              updatedIndexesToDispaly.push(0, 1, 2, 3);
              setIndexesOfQuestionsToDisplay(updatedIndexesToDispaly);
              break;
            case 4:
              updatedIndexesToDispaly.push(0, 1, 2, 3, 4, 5);
              setIndexesOfQuestionsToDisplay(updatedIndexesToDispaly);
              break;
            default:
          }
        } else {
          updatedIndexesToDispaly.push(0, 1);
          setIndexesOfQuestionsToDisplay(updatedIndexesToDispaly);
        }
      } else {
        updatedIndexesToDispaly.push(0);
        setIndexesOfQuestionsToDisplay(updatedIndexesToDispaly);
      }
    } else {
      updatedIndexesToDispaly.push(0);
      setIndexesOfQuestionsToDisplay(updatedIndexesToDispaly);
    }
  };

  useEffect(() => {
    handleConditionalRender();
  }, [selectedIndexQuestion1, selectedIndexQuestion2]);

  const handleSubmit = (): string => {
    const valuesArray = [];
    let valuesInString = "";
    if (selectedIndexQuestion1 !== undefined)
      valuesArray.push(
        fiveQuestionsOptions[0].options[selectedIndexQuestion1].value.toString()
      );
    else {
      valuesArray.push("no_answer");
    }
    if (selectedIndexQuestion2 !== undefined)
      valuesArray.push(
        fiveQuestionsOptions[1].options[selectedIndexQuestion2].value.toString()
      );
    else {
      valuesArray.push("no_answer");
    }
    if (selectedIndexQuestion3 !== undefined)
      valuesArray.push(
        fiveQuestionsOptions[2].options[selectedIndexQuestion3].value.toString()
      );
    else {
      valuesArray.push("no_answer");
    }
    if (selectedIndexQuestion4 !== undefined)
      valuesArray.push(
        fiveQuestionsOptions[3].options[selectedIndexQuestion4].value.toString()
      );
    else {
      valuesArray.push("no_answer");
    }
    if (selectedIndexQuestion5 !== undefined)
      valuesArray.push(
        fiveQuestionsOptions[4].options[selectedIndexQuestion5].value.toString()
      );
    else {
      valuesArray.push("no_answer");
    }

    valuesInString = `${valuesArray[0]}|${valuesArray[1]}|${valuesArray[2]}|${valuesArray[3]}|${valuesArray[4]}`;

    return valuesInString;
  };

  const checkIfIsValidated = () => {
    if (selectedIndexQuestion1 === 0) {
      return true;
    }
    if (selectedIndexQuestion1 === 1) {
      if (
        selectedIndexQuestion2 === 0 &&
        selectedIndexQuestion3 !== undefined
      ) {
        return true;
      }
      if (
        selectedIndexQuestion2 === 1 &&
        selectedIndexQuestion4 !== undefined
      ) {
        return true;
      }
      if (
        selectedIndexQuestion2 === 2 &&
        selectedIndexQuestion3 !== undefined &&
        selectedIndexQuestion4 !== undefined
      ) {
        return true;
      }
      if (
        selectedIndexQuestion2 === 3 &&
        selectedIndexQuestion3 !== undefined &&
        selectedIndexQuestion4 !== undefined &&
        selectedIndexQuestion5 !== undefined
      ) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    const answerUpdate: ISurveyAnswer = {
      surveyQuestionId: questionId ?? "",
      answerValue: handleSubmit(),
    };
    updateSurveyAnswerArray(answerUpdate);
    if (setIsFiveQuestionsValidated)
      setIsFiveQuestionsValidated(checkIfIsValidated());
  }, [
    selectedIndexQuestion1,
    selectedIndexQuestion2,
    selectedIndexQuestion3,
    selectedIndexQuestion4,
    selectedIndexQuestion5,
  ]);

  return (
    <FormAnswerField>
      {renderSelects &&
        fiveQuestionsOptions.map(
          (question: IOxygenQuestion, questionIndex: number) => (
            <React.Fragment key={questionIndex}>
              {indexesOfQuestionsToDisplay.find(
                (questionToDisplay) => questionToDisplay === questionIndex
              ) !== undefined && (
                <SubQuestionContainer>
                  <SubQestionDescription>
                    {`${t(question.description)}`}
                  </SubQestionDescription>
                  {question.options.map(
                    (option: ISurveyQuestionOption, optionIndex: number) => (
                      <React.Fragment key={optionIndex}>
                        <FiveQuestionsOptionsSelectionContainer>
                          <ClickableOption
                            onClick={() => {
                              handleClick(questionIndex, optionIndex);
                            }}
                          >
                            <RadioButtonContainer>
                              <RadioButton
                                onClick={() => {}}
                                checked={isItChecked(
                                  questionIndex,
                                  optionIndex
                                )}
                              />
                            </RadioButtonContainer>
                            <p
                              style={{
                                paddingTop: "8px",
                                textAlign: "justify",
                                textJustify: "inter-word",
                              }}
                            >
                              {`${t(option.label)}`}
                            </p>
                          </ClickableOption>
                        </FiveQuestionsOptionsSelectionContainer>
                      </React.Fragment>
                    )
                  )}
                </SubQuestionContainer>
              )}
            </React.Fragment>
          )
        )}
    </FormAnswerField>
  );
}
