import { FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import {
  IRangeOption,
  ISocreStrategyOption,
  ISurvey,
  ISurveyDisplayRules,
  ISurveySection,
} from "../../../@types/Surveys";
import { FixedTranslationsLanguageCodes } from "../../../@types/Translations";
import { IOption } from "../../../components/SelectDropdown";
import { GraphTypes } from "../../../constants/surveys";
import {
  BuilderLayout,
  ISectionButton,
} from "../../../layout/components/BuilderLayout/BuilderLayout";
import { surveysService } from "../../../services/surveysService";
import { translationService } from "../../../services/translationsService";
import { parseSurveyTranslationResponseToDisplay } from "../../../utils/translationsUtils";
import { SurveyBuilderContentTab } from "./Components/ContentTab";
import { DisplaySettingsFormik } from "./Components/DisplayTab";
import { BodyContainer, ScrollableContainer } from "./SurveyBuilder.styles";

type sectionType = "display" | "content";

export type LayoutStateValues = {
  Sections: ISurveySection[];
  SurveyDescription: string;
  SurveyTitle: string;
  Domains: string[];
  HasScoring: boolean;
  ScoreStrategy: string;
};

export interface IDisplayRulesOptions {
  displayRulesTypes: IOption[];
  endComparators: IOption[];
  startComparators: IOption[];
}

export function SurveyBuilder() {
  const [loadingTranslations, setLoadingTranslations] =
    useState<boolean>(false);
  const [loadingSurvey, setLoadingSurvey] = useState<boolean>(false);
  const [loadingDomains, setLoadingDomains] = useState<boolean>(true);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  const [selectedSection, setSelectedSection] =
    useState<sectionType>("content");
  const [surveyTitle, setSurveyTitle] = useState<string>(
    "Insert the survey title..."
  );
  const [surveyDescription, setSurveyDescription] = useState<string>(
    "Insert the survey description here..."
  );
  const [surveyCode, setSurveyCode] = useState<string>();
  const [sections, setSections] = useState<ISurveySection[]>([
    {
      order: 1,
      title: "Insert section title here...",
      questions: [],
    },
  ]);
  const [scoreStrategy, setScoreStrategy] = useState<string>("");
  const [hasScoring, setHasScoring] = useState(false);
  const [domains, setDomains] = useState<string[]>([]);
  const [displayRules, setDisplayRules] = useState<ISurveyDisplayRules>({
    type: GraphTypes.tableView,
  });
  const [scoreStrategiesOptions, setScoreStrategiesOptions] = useState<
    IOption[]
  >([]);
  const [displayRulesOptions, setDisplayRulesOptions] =
    useState<IDisplayRulesOptions>({
      displayRulesTypes: [],
      endComparators: [],
      startComparators: [],
    });
  const actionRef = React.createRef<FormikProps<ISurveyDisplayRules>>();

  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { surveyId } = useParams();

  const handleCancel = () => {
    navigate("/surveys");
  };

  const handleFlowSectionClick = async (section: string) => {
    if (section === "content") {
      setSelectedSection("content");
    } else {
      setSelectedSection("display");
    }
  };

  const sectionButtons: ISectionButton[] = [
    {
      isSelected: selectedSection === "content",
      onButtonClick: handleFlowSectionClick,
      label: "Content",
      name: "content",
    },
    {
      isSelected: selectedSection === "display",
      onButtonClick: handleFlowSectionClick,
      label: "Display",
      name: "display",
    },
  ];

  const getSurveyDisplaySettings = async () => {
    const [displayRulesResponse, comparatorsResponse] = await Promise.all([
      surveysService.getSurveyDisplayRules(),
      surveysService.getSurveyDisplayRulesComparators(),
    ]);

    const displayRulesTypes = displayRulesResponse.data.map((displayRule) => ({
      label: displayRule.name,
      value: displayRule.type,
      key: uuidv4(),
    }));

    const endComparators = comparatorsResponse.data
      .filter((comparator) => comparator.includes("<") && comparator !== "=")
      .map((comparator) => ({
        label: comparator,
        value: comparator,
        key: uuidv4(),
      }));

    const startComparators = comparatorsResponse.data
      .filter((comparator) => !comparator.includes("<"))
      .map((comparator) => ({
        label: comparator,
        value: comparator,
        key: uuidv4(),
      }));

    setDisplayRulesOptions({
      displayRulesTypes,
      endComparators,
      startComparators,
    });
  };

  useEffect(() => {
    i18n.changeLanguage();
    getSurveyDisplaySettings();
    if (surveyId !== undefined) {
      setLoadingTranslations(true);
      setLoadingSurvey(true);
      (async () => {
        const survey: ISurvey = (await surveysService.getSurveyById(surveyId))
          .data;
        setSurveyTitle(survey.name);
        setSurveyDescription(survey.description ?? "");
        if (survey.code) {
          setSurveyCode(survey.code);
        }
        setSections(survey.sections);
        setHasScoring(
          survey.scoreStrategy !== "" && survey.scoreStrategy !== undefined
        );
        setScoreStrategy(survey.scoreStrategy ?? "");

        if (survey.displayRules) {
          if (survey.displayRules.ranges && survey.displayRules.ranges.length) {
            survey.displayRules.ranges.sort(
              (a: IRangeOption, b: IRangeOption) => a.start - b.start
            );
          }

          setDisplayRules(survey.displayRules);
        }

        const savedDomains = new Set<string>();

        survey.sections.forEach((section) => {
          section.questions.forEach((question) => {
            if (question.domain === undefined) return;

            savedDomains.add(question.domain);
          });
        });

        setDomains(Array.from(savedDomains));
        setLoadingSurvey(false);
      })();
    }

    (async () => {
      const scoreStrategiesOptions: ISocreStrategyOption[] = (
        await surveysService.getScoreStrategiesOptions()
      ).data;

      const strategyOptionsToAdd: IOption[] = scoreStrategiesOptions.map(
        (scoreStrategy) => {
          return {
            value: scoreStrategy.type,
            label: scoreStrategy.name,
            key: scoreStrategy.type,
          };
        }
      );

      setScoreStrategiesOptions(strategyOptionsToAdd);
      setLoadingDomains(false);
    })();

    translationService
      .getSurveysFixedTranslationsByLanguageCode(
        i18n.language as FixedTranslationsLanguageCodes
      )
      .then((translations) => {
        if (!translations.data) return;
        const resourcesToAdd = parseSurveyTranslationResponseToDisplay(
          translations.data
        );
        i18n.addResourceBundle(i18n.language, "translation", resourcesToAdd);
        setLoadingTranslations(false);
      });
  }, []);

  const parseSectionsToSubmit = () => {
    const updatedSections = [...sections];
    let questionOrder = 0;

    sections.forEach((section, sectionIndex) => {
      updatedSections[sectionIndex].title = section.title.trim();

      section.questions.forEach((question, questionIndex) => {
        questionOrder += 1;
        updatedSections[sectionIndex].questions[questionIndex].order =
          questionOrder;
        updatedSections[sectionIndex].questions[questionIndex].text =
          question.text.trim();
        updatedSections[sectionIndex].questions[questionIndex].description =
          question.description.trim();

        if (question.attributes) {
          const attributesUpdate = question.attributes.filter(
            (attribute) =>
              !(attribute.name === "Intervals" && attribute.value === "")
          );
          updatedSections[sectionIndex].questions[questionIndex].attributes =
            attributesUpdate;
        }
      });
    });

    return updatedSections;
  };

  const handleSubmit = async () => {
    actionRef.current?.submitForm();
    const isValid = actionRef?.current?.isValid;

    if (
      !isValid ||
      (scoreStrategy === "" &&
        actionRef?.current?.values.type !== GraphTypes.tableView)
    )
      return;

    const updatedSections = parseSectionsToSubmit();
    const updatedDisplayRules = actionRef.current?.values;

    setLoadingSubmit(true);

    if (!surveyId) {
      const finalSurvey: ISurvey = {
        name: surveyTitle.trim(),
        description: surveyDescription.trim(),
        sections: updatedSections,
        scoreStrategy,
        displayRules: updatedDisplayRules,
      };

      await surveysService.addSurvey(finalSurvey).then(() => {
        setLoadingSubmit(false);
      });
    } else {
      const finalSurvey: ISurvey = {
        id: surveyId,
        name: surveyTitle.trim(),
        description: surveyDescription.trim(),
        sections: updatedSections,
        scoreStrategy,
        code: surveyCode ?? undefined,
        displayRules: updatedDisplayRules,
      };

      await surveysService.editSurvey(finalSurvey).then(() => {
        setLoadingSubmit(false);
      });
    }

    navigate("/surveys");
  };

  const updateSurveyStates = <K extends keyof LayoutStateValues>(
    state: K,
    value: LayoutStateValues[K]
  ) => {
    switch (state) {
      case "Sections":
        setSections(value as ISurveySection[]);
        break;
      case "SurveyDescription":
        setSurveyDescription(value as string);
        break;
      case "SurveyTitle":
        setSurveyTitle(value as string);
        break;
      case "Domains":
        setDomains(value as string[]);
        break;
      case "HasScoring":
        setHasScoring(value as boolean);
        break;
      case "ScoreStrategy":
        setScoreStrategy(value as string);
        break;
      default:
        break;
    }
  };

  const renderSurveyBuilderTabs = () => {
    return (
      <>
        <SurveyBuilderContentTab
          display={selectedSection === "content"}
          updateSurveyStates={updateSurveyStates}
          sections={sections}
          domains={domains}
          scoreStrategiesOptions={scoreStrategiesOptions}
          hasScoring={hasScoring}
          scoreStrategy={scoreStrategy}
          surveyDescription={surveyDescription}
          surveyTitle={surveyTitle}
        />
        <ScrollableContainer
          style={{ display: selectedSection === "display" ? "" : "none" }}
        >
          <BodyContainer>
            <DisplaySettingsFormik
              sections={sections}
              ref={actionRef}
              displayRules={displayRules}
              displayRulesOptions={displayRulesOptions}
              hasScoreStrategy={scoreStrategy !== ""}
            />
          </BodyContainer>
        </ScrollableContainer>
      </>
    );
  };

  return (
    <BuilderLayout
      handleOnSave={handleSubmit}
      handleOnCancel={handleCancel}
      isLayoutLoading={loadingTranslations || loadingDomains || loadingSurvey}
      location="Surveys"
      lastEdited={new Date().toString()}
      sectionButtons={sectionButtons}
      isWhiteButtonLoading={loadingSubmit}
    >
      {renderSurveyBuilderTabs()}
    </BuilderLayout>
  );
}
