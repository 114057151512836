import { ColumnDef } from "@tanstack/react-table";
import { Tooltip } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useMemo, useRef, useState } from "react";

import { Notification } from "../../@types/Notification";
import {
  BillingPatientListConfig,
  BillingPatientTableContent,
  IBillablePatient,
} from "../../@types/Patient";
import { IPortalPreferences } from "../../@types/Preferences";
import { Dots } from "../../assets/icons/Dots";
import { TableSettings } from "../../assets/icons/TableSettings";
import { CustomIconButton } from "../../components/CustomIconButton";
import { DatePickerInput } from "../../components/DatePicker";
import { Dropdown, DropdownTextItem } from "../../components/Dropdown";
import { DropdownRouter } from "../../components/DropdownRouter";
import { ActionContainer } from "../../components/TableActionDropdownButton/TableActionDropdownButton.styles";
import { TableSkeleton } from "../../components/TableSkeleton";
import {
  AUTH_PORTAL_PREFERENCES,
  PATIENT_IDS,
} from "../../constants/localStorageKeys";
import { useBillablePatientList } from "../../hooks/queries/patients";
import useIsMobile from "../../hooks/useIsMobile";
import { useModal } from "../../hooks/useModal";
import { useToast } from "../../hooks/useToast";
import { useUserPreferences } from "../../hooks/useUserPreferences";
import { PageLayout } from "../../layout/components/PageLayout";
import {
  MobilePageTitleContainer,
  PageTitle,
} from "../../layout/components/PageTitle";
import { getRelativeTimeSinceDate } from "../../utils/dateFormatter";
import { mixpanelActions } from "../../utils/mixpanel";
import {
  DatePickerButton,
  DatepickerContainer,
  ExportButton,
  LayoutCss,
  PatientCountContainer,
  PatientListHeaderContainer,
  PatientListTableContainer,
  TableSettingsContainer,
} from "./BillingPatientList.styles";
import { BillingPatientListConfigModal } from "./Components/BillingPatientListConfigModal";
import { BillingTable } from "./Components/BillingTable";
import { defaultTableColumns } from "./Components/BillingTable/tableColumns";
import { ReportExportModal } from "./Components/ReportExportModal";

export function BillingPatientList() {
  const [tableColumns, setTableColumns] =
    useState<ColumnDef<any, string>[]>(defaultTableColumns);
  const [patientListConfig, setPatientListConfig] =
    useState<BillingPatientListConfig>({
      pmpId: false,
      name: true,
      signUpDate: true,
      dob: true,
      lastUsed: true,
      hospitalPatientId: true,
      numberOfBillableDays: true,
    });
  const [refreshTable, setRefreshTable] = useState(false);

  const [fromDate, setFromDate] = useState<Dayjs>(dayjs().startOf("month"));
  const [toDate, setToDate] = useState<Dayjs>(dayjs());
  const [calendarOpen, setCalendarOpen] = useState(false);

  const datePickerRef = useRef<HTMLDivElement>(null);
  const { isMobile } = useIsMobile();
  const { openModal, closeModal } = useModal();
  const { changePortalPreferences } = useUserPreferences();
  const { showToast } = useToast();

  const options: string[] = ["Export patient report"];

  const { patients, isLoading, isRefetching, refetch } = useBillablePatientList(
    fromDate,
    toDate
  );

  const handleColumns = (tableConfig: BillingPatientListConfig) => {
    const columnsToAdd: ColumnDef<any, string>[] = [];
    const temporaryTableConfig = { ...tableConfig };

    if (temporaryTableConfig.pmpId) {
      columnsToAdd.push({
        accessorKey: "pmpId",
        header: () => "pMp ID",
        cell: (info) => info.renderValue(),
        sortingFn: "basic",
      });
    }

    if (temporaryTableConfig.name) {
      columnsToAdd.push({
        accessorKey: "name",
        header: () => "Name",
        cell: (info) => info.renderValue(),
        size: 220,
        sortingFn: "text",
      });
    }

    if (temporaryTableConfig.hospitalPatientId) {
      columnsToAdd.push({
        accessorKey: "hospitalPatientId",
        header: () => "Hospital ID",
        cell: (info) => info.renderValue(),
        size: 160,
        sortingFn: "text",
      });
    }

    if (temporaryTableConfig.signUpDate) {
      columnsToAdd.push({
        accessorKey: "signUpDate",
        header: () => "Sign up date",
        cell: (info) => info.renderValue(),
        size: 160,
        sortingFn: (rowA, rowB) => {
          return (
            rowB.original.signUpdateParsedDate -
            rowA.original.signUpdateParsedDate
          );
        },
      });
    }

    if (temporaryTableConfig.dob) {
      columnsToAdd.push({
        accessorKey: "dob",
        header: () => "DOB",
        cell: (info) => info.renderValue(),
        size: 160,
        enableSorting: false,
      });
    }

    if (temporaryTableConfig.numberOfBillableDays) {
      columnsToAdd.push({
        accessorKey: "numberOfBillableDays",
        header: () => "No. of days recording data",
        cell: (info) => info.renderValue(),
        size: 160,
        sortingFn: "basic",
      });
    }

    if (temporaryTableConfig.lastUsed) {
      columnsToAdd.push({
        accessorKey: "lastUsed",
        header: () => "Last used",
        cell: (info) => info.renderValue(),
        size: 160,
        sortingFn: (rowA, rowB) => {
          return rowB.original.lastUsedDate - rowA.original.lastUsedDate;
        },
      });
    }

    columnsToAdd.push({
      accessorKey: "action",
      header: () => "",
      cell: (info) => info.renderValue(),
      size: 160,
      enableSorting: false,
    });

    setTableColumns(columnsToAdd);
    setPatientListConfig(temporaryTableConfig);

    const portalPreferences = localStorage.getItem(AUTH_PORTAL_PREFERENCES);

    if (portalPreferences) {
      const parsedPortalPreferences = portalPreferences
        ? (JSON.parse(portalPreferences) as IPortalPreferences)
        : undefined;

      if (parsedPortalPreferences) {
        const configJson = JSON.stringify(temporaryTableConfig);
        parsedPortalPreferences.billingTableColumnPreferences = configJson;

        const portalPreferencesJson = JSON.stringify(parsedPortalPreferences);
        localStorage.setItem(AUTH_PORTAL_PREFERENCES, portalPreferencesJson);
        changePortalPreferences(parsedPortalPreferences);
      }
    }

    setRefreshTable(true);
    setTimeout(() => {
      setRefreshTable(false);
    }, 10);
  };

  const handleOnBillingPatientListConfigClose = async (
    tableConfig: BillingPatientListConfig,
    notification?: Notification,
    refreshTable?: boolean
  ) => {
    closeModal();

    if (refreshTable) {
      if (tableConfig) {
        handleColumns(tableConfig);
      }
    }

    if (notification !== undefined) {
      showToast(notification.message, notification.type);
    }
  };

  const handleTableConfigClick = () => {
    openModal(
      <BillingPatientListConfigModal
        columnsConfig={patientListConfig}
        onClose={handleOnBillingPatientListConfigClose}
      />,
      {
        width: "655px",
        maxHeight: "628px",
        showCloseButton: true,
      }
    );
  };

  const handleExportModalClose = (notification?: Notification) => {
    closeModal();

    if (notification !== undefined) {
      showToast(notification.message, notification.type);
    }
  };

  const handleExportClick = (
    patient?: IBillablePatient,
    isBillingReport?: boolean
  ) => {
    openModal(
      <ReportExportModal
        patient={patient}
        startDate={fromDate}
        endDate={toDate}
        isBillingReport={isBillingReport}
        onClose={handleExportModalClose}
      />,
      {
        width: "611px",
        maxHeight: "450px",
        showCloseButton: true,
      }
    );
  };

  function getBillingTableHeader() {
    if (isMobile) {
      return (
        <MobilePageTitleContainer>
          <PageTitle>Patients</PageTitle>

          <DropdownRouter childRoutesOf="PatientList" />
        </MobilePageTitleContainer>
      );
    }

    return <PageTitle>Data recorded with smart device</PageTitle>;
  }

  const handleOnChangeDateWithDatePicker = (dateRange: any) => {
    if (dateRange.startDate && dateRange.endDate) {
      setCalendarOpen(false);
    } else {
      return;
    }

    const parsedEndDate = dayjs(dateRange.endDate).endOf("day");

    setFromDate(dayjs(dateRange.startDate));
    setToDate(parsedEndDate);

    setTimeout(() => {
      refetch();
    }, 0);
  };

  const getDatePickerText = () => {
    const sameYear = fromDate.year() === toDate.year();

    if (sameYear) {
      return `${fromDate.format("MMM DD")} - ${toDate.format("MMM DD, YYYY")}`;
    }
    return `${fromDate.format("MMM DD, YYYY")} - ${toDate.format(
      "MMM DD, YYYY"
    )}`;
  };

  const handleClickOutsideDatePicker = (event: any) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target)
    ) {
      setCalendarOpen(false);
    }
  };

  useEffect(() => {
    if (calendarOpen) {
      document.addEventListener("mousedown", handleClickOutsideDatePicker);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideDatePicker);
    };
  }, [calendarOpen]);

  const parsePatientsToTable = useMemo(() => {
    if (!patients) return [];

    const patientIds: number[] = [];
    let lastUsedContent = "";

    const patientList = patients.map((patient: IBillablePatient) => {
      patientIds.push(patient.pmpId);

      if (patient.lastUsed !== "-") {
        lastUsedContent = getRelativeTimeSinceDate(patient.lastUsed);
      } else if (patient.isPasswordSet === true) {
        lastUsedContent = "No data added";
      } else {
        lastUsedContent = "Password not set";
      }

      const getLasUsedDate = (lastUsed: any, lastUsedContent: any) => {
        if (lastUsed !== "-")
          return dayjs(patient.lastUsed, "MMM D, YYYY, hh:mma").toDate();
        if (lastUsedContent === "No data added") return 1;
        if (lastUsedContent === "Password not set") return 0;
        return undefined;
      };

      return {
        pmpId: patient.pmpId,
        name: patient.name,
        signUpDate: patient.signUpDate,
        signUpdateParsedDate: dayjs(
          patient.signUpDate,
          "MMM D, YYYY, hh:mma"
        ).toDate(),
        dob: patient.dob.split(",").slice(0, 2).join(",").trim(),
        lastUsed: lastUsedContent,
        lastUsedDate: getLasUsedDate(patient.lastUsed, lastUsedContent),
        hospitalPatientId: patient.hospitalPatientId,
        numberOfBillableDays: patient.numberOfBillableDays,
        action: (
          <div>
            <ActionContainer
              className="actionContainer"
              onClick={(e) => e.stopPropagation()}
            >
              <Dropdown
                trigger={
                  <CustomIconButton>
                    <Dots />
                  </CustomIconButton>
                }
                value=""
                onValueChange={(val: string) => {
                  if (val === options[0]) {
                    handleExportClick(patient);
                  }
                }}
              >
                {options.map((option) => (
                  <DropdownTextItem key={option} text={option} />
                ))}
              </Dropdown>
            </ActionContainer>
          </div>
        ),
      } as unknown as BillingPatientTableContent;
    });

    // Update the patients IDs list to use for patient navigation
    localStorage.setItem(PATIENT_IDS, JSON.stringify(patientIds));

    return patientList;
  }, [patients]);

  useEffect(() => {
    mixpanelActions.track("Visited Screen: Billing table");
    const portalPreferences = localStorage.getItem(AUTH_PORTAL_PREFERENCES);
    const parsedPortalPreferences = portalPreferences
      ? (JSON.parse(portalPreferences) as IPortalPreferences)
      : undefined;

    if (
      parsedPortalPreferences?.billingTableColumnPreferences &&
      parsedPortalPreferences?.billingTableColumnPreferences !== ""
    ) {
      const parsedPatientListConfig = JSON.parse(
        parsedPortalPreferences?.billingTableColumnPreferences
      ) as BillingPatientListConfig;

      setPatientListConfig(parsedPatientListConfig);
      handleColumns(parsedPatientListConfig);
    } else {
      handleColumns(patientListConfig);
    }

    if (patients) {
      refetch();
    }
  }, []);

  return (
    <PageLayout className={LayoutCss()}>
      <PatientListHeaderContainer>
        {getBillingTableHeader()}
        {!isLoading && !isRefetching && (
          <>
            <PatientCountContainer>({patients?.length})</PatientCountContainer>

            <Tooltip
              title="Configure which columns are shown"
              arrow={false}
              placement="right"
              styles={{
                body: {
                  padding: "6px 12px",
                  minHeight: "40px",
                  width: "max-content",
                  minWidth: "220px",
                  borderRadius: "4px !important",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "'Open Sans', sans-serif",
                },
              }}
            >
              <TableSettingsContainer
                role="button"
                tabIndex={0}
                onClick={handleTableConfigClick}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    handleTableConfigClick();
                  }
                }}
              >
                <TableSettings />
              </TableSettingsContainer>
            </Tooltip>
          </>
        )}
        {calendarOpen && (
          <DatepickerContainer ref={datePickerRef}>
            <DatePickerInput
              initialDateRange={{
                startDate: fromDate.toDate() ?? null,
                endDate: toDate.toDate() ?? null,
              }}
              onDateChange={handleOnChangeDateWithDatePicker}
            />
          </DatepickerContainer>
        )}
        <DatePickerButton
          onClick={() => {
            setCalendarOpen((prevState) => !prevState);
          }}
        >
          {getDatePickerText()}
        </DatePickerButton>
        <ExportButton onClick={() => handleExportClick(undefined, true)}>
          Export
        </ExportButton>
      </PatientListHeaderContainer>
      <PatientListTableContainer>
        {!isLoading && !isRefetching && !refreshTable ? (
          <BillingTable
            isLoading={isLoading || isRefetching}
            content={parsePatientsToTable}
            columns={tableColumns}
          />
        ) : (
          <TableSkeleton columnsNumber={7} rowsNumber={6} />
        )}
      </PatientListTableContainer>
    </PageLayout>
  );
}
