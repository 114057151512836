import { useState } from "react";

import { useSelectedPoint } from "../selectedPointStore";

const RADIUS_SELECTED = 6.9;
const RADIUS_UNSELECTED = 3;

export function CustomModalChartDot(props: any) {
  const [isActive, setIsActive] = useState(false);

  const { cy, cx, color, onDotClick, payload, selectedSpirometryTestId } =
    props;

  if (cy === null && cy === null) return null;

  const selectedValue = useSelectedPoint();

  const isSelected =
    (selectedValue?.cx === cx && selectedValue?.cy === cy) ||
    isActive ||
    payload.spirometryTestIds?.includes(
      selectedValue === null ? selectedSpirometryTestId : undefined
    );

  return (
    <g
      data-chart-x={cx}
      data-chart-y={cy}
      style={{ cursor: "pointer" }}
      onClick={
        onDotClick ? () => onDotClick({ ...payload, cx, cy }) : undefined
      }
      onTouchStart={
        onDotClick ? () => onDotClick({ ...payload, cx, cy }) : undefined
      }
      onMouseOver={() => setIsActive(true)}
      onMouseOut={() => setIsActive(false)}
      data-radius={isSelected ? RADIUS_UNSELECTED : RADIUS_SELECTED}
    >
      {!isSelected ? (
        <circle
          cx={cx}
          cy={cy}
          r={RADIUS_UNSELECTED}
          fill={color}
          style={{ cursor: "pointer" }}
        />
      ) : (
        <>
          <circle cx={cx} cy={cy} r={RADIUS_SELECTED / 2} fill={color} />
          <circle
            cx={cx}
            cy={cy}
            r={RADIUS_SELECTED}
            fill="none"
            stroke={color}
            strokeWidth={2}
            strokeOpacity={0.6}
            style={{ cursor: "pointer" }}
          />
        </>
      )}
    </g>
  );
}
