import { Tooltip } from "antd";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { useLocation } from "react-router-dom";

import {
  ISectionProps,
  PatientAlertsTableContent,
} from "../../../../@types/Patient";
import { ChevronDown } from "../../../../assets/icons/ChevronDown";
import { ChevronUp } from "../../../../assets/icons/ChevronUp";
import { Dots } from "../../../../assets/icons/Dots";
import { IncreaseArrow } from "../../../../assets/icons/IncreaseArrow";
import { AlertsStatus } from "../../../../components/AlertStatus";
import { CustomIconButton } from "../../../../components/CustomIconButton";
import { Dropdown, DropdownTextItem } from "../../../../components/Dropdown";
import { ActionContainer } from "../../../../components/TableActionDropdownButton/TableActionDropdownButton.styles";
import { queryClient } from "../../../../config/query";
import { getPatientAlertsData } from "../../../../hooks/queries/patients";
import useIsMobile from "../../../../hooks/useIsMobile";
import { useModal } from "../../../../hooks/useModal";
import { useToast } from "../../../../hooks/useToast";
import { PageTitle } from "../../../../layout/components/PageTitle";
import { alertsService } from "../../../../services/alertsService";
import { getLongFormattedDate } from "../../../../utils/dateFormatter";
import { mixpanelActions } from "../../../../utils/mixpanel";
import { PatientAlertsTable } from "../PatientAlertsTable";
import { CreateTriggerModal } from "../PatientAlertsTable/components/CreateTriggerModal";
import { ResolveTriggerModal } from "../ResolveTriggerModal";
import { NewDataCOntainer, TitleContainer } from "./AlertsSection.styles";

export function AlertsSection({
  patient,
  updateColapsedSections,
  collapsedSection,
}: ISectionProps) {
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [alertsToResolve, setAlertsToResolve] = useState(false);

  const [showNewData, setShowNewData] = useState<boolean>(false);

  const { isMobile } = useIsMobile();
  const { openModal, closeModal } = useModal();
  const location = useLocation();
  const { showToast } = useToast();

  const { patientAlertTable, isError, isFetching, refetch, isRefetching } =
    getPatientAlertsData(patient.id);

  const updateDropdownOptions = async () => {
    let updateDropdown = alertsToResolve;

    patientAlertTable?.forEach((patientAlert: PatientAlertsTableContent) => {
      if (patientAlert.alertStatus == null) {
        updateDropdown = true;
      } else {
        updateDropdown = updateDropdown || false;
      }
    });

    setAlertsToResolve(updateDropdown);
  };

  const handleUpdateDropdown = () => {
    setAlertsToResolve(false);
  };

  const handleRefreshTable = () => {
    refetch();
  };

  const handleTimeFrame = (timeframe: string | null) => {
    if (timeframe === null) {
      return "Any reading";
    }

    if (timeframe.toString() === "1") {
      return `${timeframe} day`;
    }

    return `${timeframe} days`;
  };

  useEffect(() => {
    refetch();
  }, [location]);

  useEffect(() => {
    updateDropdownOptions().then();

    if (
      collapsedSection.value === true &&
      !isFetching &&
      !isRefetching &&
      patientAlertTable.length > 0
    ) {
      const mostRecentAlertDate = dayjs(patientAlertTable[0].timeOfAlert);
      const collapsedDate = dayjs(collapsedSection.collapsedDate);

      if (mostRecentAlertDate > collapsedDate) {
        setShowNewData(true);
      }
    }
  }, [patientAlertTable]);

  const parsePatientAlertsToTable = useMemo(() => {
    if (!patientAlertTable) return [];

    const options: any[] = [
      "Mark all as resolved",
      "Mark as resolved",
      "Edit trigger",
    ];
    const handleOnChangeDropdownVisibility = () => {
      setIsDropDownOpen(!isDropDownOpen);
    };

    const handleNotification = (message: string) => {
      showToast(message, "success");
    };

    const handleCloseModal = (
      isMultiple: boolean,
      resolved: boolean,
      type: string
    ) => {
      if (resolved) {
        if (isMultiple) {
          mixpanelActions.track("User Action: Resolve All Alerts");
          handleNotification("All alerts successfully resolved");
        } else {
          mixpanelActions.track("User Action: Resolve Alert");
          handleNotification(`${type} alert successfully resolved`);
        }
        setAlertsToResolve(false);
        refetch();
      }
      closeModal();
    };

    const markAllResolved = () => {
      const idList: string[] = [];
      patientAlertTable.forEach((element) => {
        if (element.alertStatus === null) {
          idList.push(element.id);
        }
      });
      openModal(
        <ResolveTriggerModal
          patientId={patient.id}
          alertIds={idList}
          type="Multiple selected"
          triggered="Multiple selected"
          onClose={(resolved) => handleCloseModal(true, resolved, "")}
        />,
        {
          width: "auto",
          height: "auto",
          showCloseButton: true,
        }
      );
    };

    const markAsResolved = (patientAlert: PatientAlertsTableContent) => {
      if (patientAlert.alertStatus !== "Resolved") {
        openModal(
          <ResolveTriggerModal
            patientId={patient.id}
            alertIds={[patientAlert.id]}
            type={patientAlert.type}
            triggered={patientAlert.timeOfAlert.toString()}
            onClose={(showNotification, success) =>
              showNotification
                ? handleCloseModal(false, success ?? false, patientAlert.type)
                : closeModal()
            }
          />,
          {
            width: "auto",
            height: "auto",
            showCloseButton: true,
          }
        );
      }
    };

    const editTrigger = (alertId: string) => {
      const handleNotification = (
        notificationMessage: string,
        notificationType: "success" | "warning" | "error"
      ) => {
        showToast(notificationMessage, notificationType);
      };

      alertsService.getTriggerBytAlertId(alertId).then((trigger) => {
        const { data } = trigger;

        openModal(
          <CreateTriggerModal
            content={data}
            isEdit
            measurementAlertId={data.measurementAlertId}
            patientId={patient.id}
            setParentNotification={handleNotification}
            onClose={() => {
              closeModal();
              queryClient.refetchQueries({
                queryKey: [`triggers-table-${patient.id}`],
              });
            }}
          />,
          {
            width: "auto",
            height: "auto",
            showCloseButton: true,
          }
        );
      });
    };

    return patientAlertTable.map((patientAlert: PatientAlertsTableContent) => {
      const isActive = patientAlert.alertStatus == null;

      const parsedContent = ReactHtmlParser(patientAlert.alertNote ?? "");

      const htmlTagRegex = /(<([^>]+)>)/gi;
      const noteWithouTags = patientAlert.alertNote
        ?.replace(htmlTagRegex, "")
        .slice(0, 20);

      const handleTriggerText = (triggerText: string) => {
        if (triggerText.includes("+")) {
          const triggerWithoutPlus = triggerText.replace("+", "");

          return (
            <div
              style={{
                display: "flex",
                gap: "2px",
                marginLeft: -4,
              }}
            >
              <IncreaseArrow /> {triggerWithoutPlus}
            </div>
          );
        }

        return triggerText;
      };

      return {
        id: patientAlert.id,
        type: patientAlert.type,
        trigger: handleTriggerText(patientAlert.trigger),
        timeFrame: handleTimeFrame(patientAlert.timeFrame),
        spirometryAcceptance: patientAlert.spirometryAcceptance || "-",
        value: patientAlert.value || "-",
        timeOfAlert: getLongFormattedDate(patientAlert.timeOfAlert),
        alertStatus: isActive ? (
          <AlertsStatus>Active</AlertsStatus>
        ) : (
          "Resolved"
        ),
        resolvedBy: patientAlert.resolvedBy || "-",
        hcpNotification: patientAlert.hcpNotification,
        created: patientAlert.created,
        alertNote:
          patientAlert.alertNote !== null &&
          patientAlert.alertNote !== undefined ? (
            <div>
              <Tooltip
                title={<div>{parsedContent}</div>}
                arrow={false}
                placement="bottom"
                styles={{
                  body: {
                    padding: "6px 12px",
                    minHeight: "40px",
                    width: "fit-content",
                    maxWidth: "220px",
                    borderRadius: "4px !important",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "'Open Sans', sans-serif",
                  },
                }}
              >
                {`${noteWithouTags}${
                  noteWithouTags && noteWithouTags.length === 20 ? "..." : ""
                }`}
              </Tooltip>
            </div>
          ) : (
            "-"
          ),
        action: (
          <div>
            {alertsToResolve && isActive ? (
              <ActionContainer
                className="actionContainer"
                onClick={(e) => e.stopPropagation()}
              >
                <Dropdown
                  trigger={
                    <CustomIconButton>
                      <Dots />
                    </CustomIconButton>
                  }
                  onOpenChange={handleOnChangeDropdownVisibility}
                  onValueChange={(val: string) => {
                    if (val === options[0]) {
                      markAllResolved();
                    }
                    if (val === options[1]) {
                      markAsResolved(patientAlert);
                    }
                    if (val === options[2]) {
                      editTrigger(patientAlert.id);
                    }
                  }}
                >
                  {options.map((option) => (
                    <DropdownTextItem key={option} text={option} />
                  ))}
                </Dropdown>
              </ActionContainer>
            ) : (
              ""
            )}
          </div>
        ),
      } as PatientAlertsTableContent;
    });
  }, [patientAlertTable]);

  const handleCollapseSection = () => {
    if (collapsedSection.value === true) {
      setShowNewData(false);
    }
    updateColapsedSections("alertsSection");
  };

  return (
    <>
      {!isMobile && (
        <TitleContainer>
          <PageTitle>Alerts</PageTitle>
          {showNewData && <NewDataCOntainer>(new data)</NewDataCOntainer>}
          <CustomIconButton
            style={{ marginLeft: "2px" }}
            onClick={handleCollapseSection}
          >
            {collapsedSection.value ? <ChevronUp /> : <ChevronDown />}
          </CustomIconButton>
        </TitleContainer>
      )}
      <div
        style={{
          height: collapsedSection.value ? "0" : "",
          overflow: collapsedSection.value ? "hidden" : "",
        }}
      >
        <PatientAlertsTable
          patientId={patient.id}
          isLoading={isFetching && !isRefetching}
          isError={isError}
          content={parsePatientAlertsToTable}
          updateDropdown={handleUpdateDropdown}
          refreshTable={handleRefreshTable}
          showManageTriggersButton={!collapsedSection.value}
        />
      </div>
    </>
  );
}
