import { useQueryClient } from "@tanstack/react-query";
import { AccessorKeyColumnDef } from "@tanstack/react-table";
import { Modal, Tooltip } from "antd";
import { ReactElement, useEffect, useState } from "react";

import {
  IArrhythmiaStatus,
  IPatientMeasurementData,
} from "../../../../../../@types/Measurements";
import { IStaffPreferences } from "../../../../../../@types/Preferences";
import { Dots } from "../../../../../../assets/icons/Dots";
import { ReactComponent as NoMessagesImage } from "../../../../../../assets/images/woman-computer.svg";
import { Button } from "../../../../../../components/Button";
import { CustomIconButton } from "../../../../../../components/CustomIconButton";
import Dropdown, {
  DropdownTextItem,
} from "../../../../../../components/Dropdown/Dropdown";
import { createColumn, Table } from "../../../../../../components/Table";
import { ActionContainer } from "../../../../../../components/TableActionDropdownButton/TableActionDropdownButton.styles";
import { Tag } from "../../../../../../components/Tag";
import { AUTH_STAFF_PREFERENCES } from "../../../../../../constants/localStorageKeys";
import {
  fibriCheckColorDictionary,
  archivableSpirometryMeasurements,
  tooltipTitles,
} from "../../../../../../constants/measurements";
import { useToast } from "../../../../../../hooks/useToast";
import { measurementsService } from "../../../../../../services/measurementsService";
import { mixpanelActions } from "../../../../../../utils/mixpanel";
import { ButtonContainer } from "../../../../../Appointments/components/AppointmentsTable/components/DeleteAppointmentModal.styles";
import { CancelButton } from "../CustomOptionsBar/CustomOptionsBar.styles";
import {
  confirmModalCss,
  ContentContainer,
  ImageContainer,
  NoContentMessage,
  TableContainer,
} from "./ModalMeasurementsTable.styles";

interface IModalMeasurements {
  data: Map<string, any[]>;
  measurementType: string;
  patientId?: string;
  isPinnedGraph?: boolean;
  unit?: string;
}

interface IMeasuremntTableData {
  id: number;
  measurement: string;
  source: string;
  time: string;
  unformattedTime: number;
  action: ReactElement;
  archivedBy?: string;
  arrhythmia_status?: ReactElement;
  bkv_status?: ReactElement;
  bkv_value?: string;
}

export function ModalMeasurementsTable({
  data,
  measurementType,
  patientId,
  isPinnedGraph,
  unit,
}: IModalMeasurements) {
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  if (data.size < 1) {
    return (
      <ImageContainer>
        <NoMessagesImage width={181} height={158} />
        <NoContentMessage>
          {measurementType === "arrhythmia_status" || measurementType === "bkv"
            ? "No measurements to show with the current data range."
            : "No measurements to show with the current filters and/or data range."}
        </NoContentMessage>
      </ImageContainer>
    );
  }

  const mapKeys = Array.from(data.keys());
  let header = tooltipTitles.get(mapKeys[0].split("|")[0]) ?? "";

  const [tableData] = useState<IMeasuremntTableData[]>([]);
  const [modalMeasurementsTableColumns, setModalMeasurementsTableColumns] =
    useState<AccessorKeyColumnDef<any, string>[]>([]);
  const [confirmModalData, setConfirmModalData] =
    useState<IPatientMeasurementData>();
  const [archiveMeasurementValue, setArchivedMeasurementValue] =
    useState<number>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const staffPreferences = localStorage.getItem(AUTH_STAFF_PREFERENCES);
    const parsedStaffPreferences = JSON.parse(
      staffPreferences ?? "{}"
    ) as IStaffPreferences;

    const tempModalMeasurementsTableColumns = [
      ...(measurementType !== "bkv"
        ? [
            createColumn({
              keyName: "measurement",
              header: () => header,
              cell: (info) => info.renderValue(),
              size: 100,
            }),
          ]
        : []),
      createColumn({
        keyName: "source",
        header: () => "Source",
        cell: (info) => info.renderValue(),
        size: 100,
      }),
      createColumn({
        keyName: "time",
        header: () => "Date",
        cell: (info) => info.renderValue(),
        size: 120,
      }),
    ];

    if (measurementType === "bkv") {
      tempModalMeasurementsTableColumns.push(
        createColumn({
          keyName: "bkv_status",
          header: () => "Status",
          cell: (info) => info.renderValue(),
          size: 100,
        })
      );

      tempModalMeasurementsTableColumns.push(
        createColumn({
          keyName: "bkv_value",
          header: () => "Value",
          cell: (info) => info.renderValue(),
          size: 100,
        })
      );
    }

    if (parsedStaffPreferences.archiveMeasurements) {
      const columnsWithAction = [
        ...tempModalMeasurementsTableColumns,
        createColumn({
          keyName: "action",
          header: () => "",
          cell: (info) => info.renderValue(),
          size: 1,
        }),
      ];

      setModalMeasurementsTableColumns(columnsWithAction);
    } else {
      setModalMeasurementsTableColumns(tempModalMeasurementsTableColumns);
    }
  }, []);

  const handleArrhythmiaTag = (measurement: any) => {
    const parsedObject = JSON.parse(measurement.arrhythmia_status);

    const arrhythmiaStatus: IArrhythmiaStatus = {
      key: parsedObject.Key,
      color: parsedObject.Color,
    };

    const formattedKey = arrhythmiaStatus.key
      .replace(/_/g, " ")
      .replace(/^./, (char) => char.toUpperCase());

    const tagColor = fibriCheckColorDictionary[arrhythmiaStatus.color] as
      | "success"
      | "info"
      | "tertiary"
      | "error"
      | "warning";

    return <Tag variant={tagColor}>{formattedKey}</Tag>;
  };

  const handleBkvTag = (measurement: any) => {
    const isNumber = !Number.isNaN(Number(measurement.bkv));
    const isNotDetected = measurement.bkv.toLowerCase() === "not detected";

    const tagColor = isNotDetected ? "success" : "error";
    const value = !isNumber ? measurement.bkv : " - ";

    return isNumber ? undefined : <Tag variant={tagColor}>{value}</Tag>;
  };

  const handleBkvValue = (measurement: any) => {
    const isNumber = !Number.isNaN(Number(measurement.bkv));

    const value = !isNumber ? "-" : `${measurement.bkv} ${unit}`;

    return value;
  };

  const handleArchiveMeasurement = async (
    measurement: IPatientMeasurementData,
    archive: boolean
  ) => {
    mixpanelActions.track("User Action: Archive value", {
      Event: tooltipTitles.get(measurementType),
    });

    const result = await measurementsService.archivePatientMeasurement(
      measurement.id,
      archive
    );

    if (result.status >= 200 && result.status < 300) {
      showToast(
        `Value successfully ${archive ? "archived" : "unarchived"}`,
        "success"
      );

      queryClient.refetchQueries({
        queryKey: [`${measurementType}-${patientId}-true`],
      });
    } else {
      showToast(
        `Something went wrong while ${
          archive ? "archiving" : "unarchiving"
        } value, please try again`,
        "error"
      );
    }
  };

  mapKeys.forEach((key) => {
    const measurementType = key.split("|")[0];

    if (measurementType === "arrhythmia_status" && header !== "BPM") {
      header = "BPM";

      modalMeasurementsTableColumns.push(
        createColumn({
          keyName: "arrhythmia_status",
          header: () => "Status",
          cell: (info) => info.renderValue(),
          size: 200,
        })
      );
    }

    data.get(key)?.forEach((measurement) => {
      if (measurementType === "bp_systolic") {
        header = "Systolic/Diastolic";

        const DiastolicMeasurement = data
          .get(`bp_diastolic|${measurement.source.toLowerCase()}`)
          ?.find((element) => element.id === measurement.id);

        tableData.push({
          id: measurement.id,
          measurement: `${measurement[measurementType]}/${
            DiastolicMeasurement ? DiastolicMeasurement.bp_diastolic : "-"
          }`,
          source: measurement.source_name,
          time: measurement.time,
          unformattedTime: measurement.unformattedTime,
          archivedBy: measurement.archived_by,
          action: (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ActionContainer className="actionContainer">
                <Dropdown
                  trigger={
                    <CustomIconButton>
                      <Dots />
                    </CustomIconButton>
                  }
                  value=""
                  onValueChange={(val: string) => {
                    if (val === "Archive value") {
                      handleArchiveMeasurement(measurement, true);
                    }
                    if (val === "Unarchive value") {
                      handleArchiveMeasurement(measurement, false);
                    }
                  }}
                >
                  {measurement.archived_by === undefined ||
                  measurement.archived_by === "" ? (
                    <DropdownTextItem key="archiveValue" text="Archive value" />
                  ) : (
                    <DropdownTextItem
                      key="unarchiveValue"
                      text="Unarchive value"
                    />
                  )}
                </Dropdown>
              </ActionContainer>
            </div>
          ),
        });

        return;
      }

      if (measurementType === "bp_diastolic") {
        return;
      }

      tableData.push({
        id: measurement.id,
        measurement:
          measurementType !== "arrhythmia_status"
            ? measurement[key.split("|")[0]]
            : measurement.heart_rate,
        source: measurement.source_name,
        time: measurement.time,
        unformattedTime: measurement.unformattedTime,
        archivedBy: measurement.archived_by,
        arrhythmia_status:
          measurementType === "arrhythmia_status"
            ? handleArrhythmiaTag(measurement)
            : undefined,
        bkv_status:
          measurementType === "bkv" ? handleBkvTag(measurement) : undefined,
        bkv_value:
          measurementType === "bkv" ? handleBkvValue(measurement) : undefined,
        action: (
          <div style={{ marginLeft: "50%" }}>
            <ActionContainer className="actionContainer">
              <Dropdown
                trigger={
                  <CustomIconButton>
                    <Dots />
                  </CustomIconButton>
                }
                value=""
                onValueChange={(val: string) => {
                  if (val === "Archive value") {
                    if (
                      archivableSpirometryMeasurements.includes(measurementType)
                    ) {
                      setArchivedMeasurementValue(measurement[measurementType]);
                      setConfirmModalData(measurement);
                    } else {
                      handleArchiveMeasurement(measurement, true);
                    }
                  }
                  if (val === "Unarchive value") {
                    handleArchiveMeasurement(measurement, false);
                  }
                }}
              >
                {measurement.archived_by === undefined ||
                measurement.archived_by === "" ? (
                  <div>
                    <Tooltip
                      title={
                        measurement.source === "eMedRenal"
                          ? "EMed values cannot be deleted"
                          : undefined
                      }
                      zIndex={3002}
                      color="#4B4B4B"
                      arrow={false}
                      styles={{
                        body: {
                          minHeight: "40px",
                          minWidth: "220px",
                          borderRadius: "4px !important",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        },
                      }}
                    >
                      <div>
                        <DropdownTextItem
                          key="archiveValue"
                          text="Archive value"
                          disabled={measurement.source === "eMedRenal"}
                        />
                      </div>
                    </Tooltip>
                  </div>
                ) : (
                  <DropdownTextItem
                    key="unarchiveValue"
                    text="Unarchive value"
                  />
                )}
              </Dropdown>
            </ActionContainer>
          </div>
        ),
      });
    });
  });

  tableData.sort((a, b) => b.unformattedTime - a.unformattedTime);

  const handleConfirmModalClose = async (confirm: boolean) => {
    if (confirm && confirmModalData) {
      setIsLoading(true);
      await handleArchiveMeasurement(confirmModalData, true);
      setIsLoading(false);
    }

    setConfirmModalData(undefined);
  };

  return (
    <>
      <TableContainer isPinnedGraph={isPinnedGraph}>
        <Table
          fontWeight={400}
          hasBorder={false}
          data={tableData}
          columns={modalMeasurementsTableColumns}
        />
      </TableContainer>

      {confirmModalData !== undefined ? (
        <Modal
          title="Archive measurement?"
          open
          zIndex={3001}
          footer={null}
          width={610}
          height={400}
          className={confirmModalCss()}
          centered
          destroyOnClose
          closeIcon={false}
        >
          <ContentContainer>
            <p
              style={{
                padding: "12px 0 0 0",
                fontWeight: "400",
                color: "#939393",
                marginLeft: "5px",
              }}
            >
              Are you sure you want to archive{" "}
              {`${archiveMeasurementValue} ${unit} `}
              recorded on {confirmModalData.time} ? All other measurements from
              this blow will be archived.
            </p>
            <ButtonContainer>
              <Button
                label="Archive"
                type="button"
                onClick={() => handleConfirmModalClose(true)}
                isLoading={isLoading}
              />

              <CancelButton
                isModal
                onClick={() => handleConfirmModalClose(false)}
              >
                Cancel
              </CancelButton>
            </ButtonContainer>
          </ContentContainer>
        </Modal>
      ) : null}
    </>
  );
}
