import dayjs from "dayjs";
import { useEffect, useState } from "react";

import { CustomPromsLegend } from "../CustomPromsLegend/CustomPromsLegend";
import {
  ChartLegendContainer,
  Container,
  DateLabel,
  Divider,
  MainValueContainer,
  MainValueContainerFirst,
  MainValuesContainer,
  NumberLabel,
  TextLabel,
} from "./CustomPromsGraphLegend.style";

type CustomPromsGraphLegendProps = {
  mainValues: any;
  empty: boolean;
  showValues: boolean;
  ranges: any;
};

export function CustomPromsGraphLegend({
  mainValues,
  empty,
  showValues,
  ranges,
}: CustomPromsGraphLegendProps) {
  const { min, max, average, minValueDate, maxValueDate } = mainValues;

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setDisabled(!min && !max && !average);
  }, [mainValues]);

  const chartLegendContainer = (
    <ChartLegendContainer paddingLeft={!showValues}>
      <CustomPromsLegend ranges={ranges} />
    </ChartLegendContainer>
  );

  const mainValuesContainer = (
    <MainValuesContainer>
      <MainValueContainerFirst>
        <NumberLabel disabled={disabled}>{average ?? "-"}</NumberLabel>
        <TextLabel disabled={disabled}>Average</TextLabel>
      </MainValueContainerFirst>
      <MainValueContainerFirst>
        <NumberLabel disabled={disabled}>{max ?? "-"}</NumberLabel>
        <TextLabel disabled={disabled}>Highest</TextLabel>
        <DateLabel disabled={disabled}>
          {dayjs(maxValueDate).format("MMM DD, YYYY") || "-"}
        </DateLabel>
      </MainValueContainerFirst>
      <MainValueContainer>
        <NumberLabel disabled={disabled}>{min ?? "-"}</NumberLabel>
        <TextLabel disabled={disabled}>Lowest</TextLabel>
        <DateLabel disabled={disabled}>
          {dayjs(minValueDate).format("MMM DD, YYYY") || "-"}
        </DateLabel>
      </MainValueContainer>
    </MainValuesContainer>
  );

  return (
    <div style={{ position: "relative" }}>
      <Container>
        {showValues && mainValuesContainer}
        {!empty && <Divider />}
        {chartLegendContainer}
      </Container>
    </div>
  );
}
