import axios, { AxiosError, AxiosResponse } from "axios";

import {
  AddMeasurementType,
  GetPatientMeasurementProps,
  IMeasurementTypeOptions,
  IPatientMeasurementAPIResponse,
  MeasurementsConfig,
  MeasurementType,
} from "../@types/Measurements";
import { api } from "./api";

export const measurementsService = {
  async getPatientMeasurement({
    type,
    toDate,
    fromDate,
    patientId,
    filter = "",
    hideFvcRejected = false,
    hideFev1Rejected = false,
    hideFvcUsable = false,
    hideFev1Usable = false,
  }: GetPatientMeasurementProps): Promise<
    AxiosResponse<IPatientMeasurementAPIResponse>
  > {
    const url = `/Portal/PatientMeasurements/`;
    const serverResponse = await api.get<IPatientMeasurementAPIResponse>(url, {
      params: {
        field: type,
        patientId,
        fromDate,
        toDate,
        filter,
        hideFvcRejected,
        hideFev1Rejected,
        hideFvcUsable,
        hideFev1Usable,
      },
    });

    return serverResponse;
  },

  async getPatientMeasurementConfigs(
    patientId: number,
    section: string
  ): Promise<AxiosResponse<MeasurementsConfig[]>> {
    const url = `/Portal/PatientMeasurements/${patientId}/Configs/${section}`;

    const serverResponse = await api.get<MeasurementsConfig[]>(url, {});

    return serverResponse;
  },

  async getMeasurementTypeConfig(
    measurementType: string
  ): Promise<AxiosResponse<MeasurementsConfig>> {
    const url = `Portal/PatientMeasurements/GetConfig/${measurementType}`;

    const serverResponse = await api.get<MeasurementsConfig>(url, {});

    return serverResponse;
  },

  async getPatientMeasurementTypes(
    patientId: string
  ): Promise<AxiosResponse<MeasurementType[]>> {
    const url = "/Portal/MeasurementTypes";

    const serverResponse = await api.get<MeasurementType[]>(url, {
      params: {
        patientId,
      },
    });

    return serverResponse;
  },

  async getMeasurementTypesByWards(
    wardIds?: number[],
    isWorkflows?: boolean
  ): Promise<AxiosResponse<IMeasurementTypeOptions[]>> {
    const url = "/Portal/MeasurementTypes/ByWards";

    const serverResponse = await api.post(url, {
      wards: wardIds,
      isWorkflows,
    });

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async addPatientMeasurement(
    addMeasurementObj: AddMeasurementType
  ): Promise<AxiosResponse> {
    const url = "/Portal/PatientMeasurements/AddData";

    const serverResponse = await api
      .post(url, addMeasurementObj)
      .then((response: AxiosResponse | AxiosError) => {
        if (axios.isAxiosError(response)) {
          return response.response;
        }
        return response;
      });

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async archivePatientMeasurement(
    measurementId: number,
    archive: boolean
  ): Promise<AxiosResponse> {
    const url = "/Portal/PatientMeasurements";

    const serverResponse = await api.put(url, {
      measurementId,
      archive,
    });

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },
};
