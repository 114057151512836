import { useQueryClient } from "@tanstack/react-query";
import { Tooltip } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Notification } from "../../../../@types/Notification";
import { PatientMessagesTableContent } from "../../../../@types/Patient";
import { CircleDoubleTick } from "../../../../assets/icons/CircleDoubleTick";
import { CircleTick } from "../../../../assets/icons/CircleTick";
import { CircleX } from "../../../../assets/icons/CircleX";
import { ReactComponent as NoMessagesImage } from "../../../../assets/images/woman-computer.svg";
import { Table } from "../../../../components/Table";
import { TableSkeleton } from "../../../../components/TableSkeleton";
import {
  getPatientMessagesData,
  getPatientRegisteredDevices,
} from "../../../../hooks/queries/messages";
import { useModal } from "../../../../hooks/useModal";
import { useToast } from "../../../../hooks/useToast";
import { getLongFormattedDate } from "../../../../utils/dateFormatter";
import { mixpanelActions } from "../../../../utils/mixpanel";
import { desktopTableColumns } from "../MessagesSection/patientMessagesTableColumns";
import { CreateMessageModal } from "./components/CreateMessageModal";
import { ViewMessageModal } from "./components/ViewMessageModal";
import {
  ImageContainer,
  ManageMessagesTitle,
  MessagesTableContainer,
  MessagesTableInnerContainer,
  NoContentMessage,
  TooltipContainer,
  tooltipStyles,
} from "./PatientMessagesTable.style";

type PatientMessagesTableProps = {
  patientId: number;
  patientPhoneNumber: string | undefined;
  content: PatientMessagesTableContent[];
  isLoading?: boolean;
  isError?: boolean;
  highlightedRows: boolean[];
  refreshTable: () => void;
  showSendMessageButton: boolean;
};

export function PatientMessagesTable({
  patientId,
  patientPhoneNumber,
  isLoading,
  isError,
  content,
  highlightedRows,
  showSendMessageButton,
  refreshTable,
}: PatientMessagesTableProps) {
  const [searchParams] = useSearchParams();
  const { openModal, closeModal } = useModal();
  const { refetch } = getPatientMessagesData(patientId); // Used to refresh if data added/edited/removed
  const [hasRegisteredDevices, setHasRegisteredDevices] = useState(false);

  const patientRegisteredDevices = getPatientRegisteredDevices(patientId);
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  useEffect(() => {
    if (
      patientRegisteredDevices &&
      patientRegisteredDevices.PatientRegisteredDevices &&
      patientRegisteredDevices.PatientRegisteredDevices.length > 0
    ) {
      setHasRegisteredDevices(true);
    }
  }, [patientRegisteredDevices]);

  const parseMessagesToTable = useMemo(() => {
    if (!content) return [];

    return content.map((message: any) => {
      let isSentIcon = (
        <Tooltip
          title="Not sent"
          zIndex={3002}
          color="#4B4B4B"
          arrow={false}
          placement="topLeft"
          styles={{
            body: tooltipStyles,
          }}
        >
          <TooltipContainer>
            <CircleX width={34} height={22} color="#EB5A7B" />
          </TooltipContainer>
        </Tooltip>
      );

      if (message.isAnswered === true) {
        let dt = "Unknown";
        if (message.responseDate) {
          dt = getLongFormattedDate(message.responseDate);
        }
        const tooltipContent = (
          <div
            style={{
              textAlign: "center",
            }}
          >
            <h4 style={{ fontWeight: 500 }}>Responded</h4>
            <p>{dt}</p>
          </div>
        );
        isSentIcon = (
          <Tooltip
            title={tooltipContent}
            zIndex={3002}
            color="#4B4B4B"
            arrow={false}
            placement="topLeft"
            styles={{
              body: tooltipStyles,
            }}
          >
            <TooltipContainer>
              <CircleDoubleTick width={34} height={22} color="#58C488" />
            </TooltipContainer>
          </Tooltip>
        );
      } else if (message.isRead === true) {
        let dt = "Unknown";
        if (message.readDate) {
          dt = getLongFormattedDate(message.readDate);
        }
        const tooltipContent = (
          <div
            style={{
              textAlign: "center",
            }}
          >
            <h4 style={{ fontWeight: 500 }}>Read</h4>
            <p>{dt}</p>
          </div>
        );
        isSentIcon = (
          <Tooltip
            title={tooltipContent}
            zIndex={3002}
            color="#4B4B4B"
            arrow={false}
            placement="topLeft"
            styles={{
              body: tooltipStyles,
            }}
          >
            <TooltipContainer>
              <CircleDoubleTick width={34} height={22} color="#58C488" />
            </TooltipContainer>
          </Tooltip>
        );
      } else if (message.isDelivered === true) {
        let dt = "Unknown";
        if (message.deliveredDate) {
          dt = getLongFormattedDate(message.deliveredDate);
        }
        const tooltipContent = (
          <div
            style={{
              textAlign: "center",
            }}
          >
            <h4 style={{ fontWeight: 500 }}>Delivered</h4>
            <p>{dt}</p>
          </div>
        );
        isSentIcon = (
          <Tooltip
            title={tooltipContent}
            zIndex={3002}
            color="#4B4B4B"
            arrow={false}
            placement="topLeft"
            styles={{
              body: tooltipStyles,
            }}
          >
            <TooltipContainer>
              <CircleTick width={34} height={22} color="#58C488" />
            </TooltipContainer>
          </Tooltip>
        );
      } else if (message.isSent === true) {
        let dt = "Unknown";
        if (message.sentDate) {
          dt = getLongFormattedDate(message.sentDate);
        }
        const tooltipContent = (
          <div
            style={{
              textAlign: "center",
            }}
          >
            <h4 style={{ fontWeight: 500 }}>Sent</h4>
            <p>{dt}</p>
          </div>
        );
        isSentIcon = (
          <Tooltip
            title={tooltipContent}
            zIndex={3002}
            color="#4B4B4B"
            arrow={false}
            placement="topLeft"
            styles={{
              body: tooltipStyles,
            }}
          >
            <TooltipContainer>
              <CircleTick width={34} height={22} color="#B5B5B6" />
            </TooltipContainer>
          </Tooltip>
        );
      }

      return {
        id: message.id,
        title: message.title,
        sentBy: message.sentBy,
        response: message.response,
        createdBy: message.createdBy,
        responseType: message.responseType,
        responseOptions: message.responseOptions,
        body: message.body,
        isSent: isSentIcon,
        isRead: message.isRead,
        responseDate: message.responseDate,
        isAnswered: message.isAnswered,
        freeResponse: message.freeResponse,
        lastMessage: message.lastMessage,
        sent: message.sent,
      } as unknown as PatientMessagesTableContent;
    });
  }, [content]);

  const handleCreateNoteClose = () => {
    closeModal();
    // Delay 200ms in order to allow the message status to update
    setTimeout(() => {
      refetch();
    }, 200);
  };

  const handleOnClose = async (
    notification?: Notification,
    refreshTable?: boolean
  ) => {
    closeModal();

    if (refreshTable) {
      refetch();
    }

    if (notification !== undefined) {
      showToast(notification.message, notification.type);
    }
  };

  const handleRowClick = async (
    patientMessageContent: PatientMessagesTableContent
  ) => {
    if (!patientMessageContent.isRead) {
      queryClient.setQueryData(
        [`patient-messages-table-${patientId}`, patientId],
        (oldData: any) => {
          return oldData.map((message: PatientMessagesTableContent) =>
            message.id === patientMessageContent.id
              ? { ...message, isRead: true }
              : message
          );
        }
      );
    }

    openModal(
      <ViewMessageModal
        message={patientMessageContent}
        messageOptions={patientMessageContent.responseOptions}
        onClose={handleOnClose}
      />,
      {
        showCloseButton: true,
        width: "auto",
        height: "auto",
      }
    );
  };

  const handleCloseModal = (
    notificationMessage: string,
    notificationType: "success" | "warning" | "error"
  ) => {
    if (notificationMessage && notificationType) {
      showToast(notificationMessage, notificationType);
    }

    closeModal();
  };

  const handleNewMessage = () => {
    mixpanelActions.track(`User Action: ClickedNewMessage`);
    openModal(
      <CreateMessageModal
        patientId={patientId}
        patientPhoneNumber={patientPhoneNumber}
        hasRegisteredDevices={hasRegisteredDevices}
        setNotification={handleCloseModal}
        onClose={handleCreateNoteClose}
      />,
      {
        showCloseButton: true,
        width: "auto",
        height: "auto",
      }
    );
  };

  useEffect(() => {
    if (
      window.location.pathname.substring(
        window.location.pathname.lastIndexOf("/") + 1
      ) === "messages"
    ) {
      const messageId = searchParams.get("id");
      if (messageId) {
        const message = content.find((message) => message.id === messageId);
        if (message) {
          handleRowClick(message);
        }
      }
    }
  }, []);

  if (isLoading) {
    return <TableSkeleton columnsNumber={5} />;
  }

  if (isError) {
    return (
      <ImageContainer>
        <p>
          Error getting messages, please{" "}
          <button type="button" onClick={refreshTable}>
            try again
          </button>
          . If the issue continues contact support.
        </p>
      </ImageContainer>
    );
  }

  if (content && content.length > 0) {
    return (
      <>
        {showSendMessageButton && (
          <ManageMessagesTitle onClick={handleNewMessage}>
            Send message
          </ManageMessagesTitle>
        )}
        <MessagesTableContainer>
          <MessagesTableInnerContainer>
            <Table
              data={parseMessagesToTable}
              columns={desktopTableColumns}
              highlightedRows={highlightedRows}
              onRowClick={handleRowClick}
              highlightColor="#EDF7F7"
            />
          </MessagesTableInnerContainer>
        </MessagesTableContainer>
      </>
    );
  }

  return (
    <>
      {showSendMessageButton && (
        <ManageMessagesTitle onClick={handleNewMessage}>
          Send message
        </ManageMessagesTitle>
      )}
      <ImageContainer>
        <NoMessagesImage width={181} height={158} />
        <NoContentMessage>
          Your patient has not added any patient messages yet. Messages will
          appear here.
        </NoContentMessage>
      </ImageContainer>
    </>
  );
}
