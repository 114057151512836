import { useSyncExternalStore } from "react";

let selectedPoint: any = null;
const listeners = new Set<() => void>();

export const setSelectedPoint = (point: any) => {
  selectedPoint = point;
  listeners.forEach((fn) => fn());
};

export const useSelectedPoint = () => {
  return useSyncExternalStore(
    (subscribe) => {
      listeners.add(subscribe);
      return () => listeners.delete(subscribe);
    },
    () => selectedPoint
  );
};
