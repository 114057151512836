import { css, styled } from "../../../../../../../styles";
import { mediumTextFieldClassName, selectCss } from "../../DisplayTab.styles";

export const Divider = styled("div", {
  width: "875px",
  height: "1px",

  flexShrink: 0,
  borderRadius: "0.5px",
  background: "#E6E6E6",

  "@sm": {
    marginTop: "25px",
    width: "95%",
    marginLeft: "22px",
    transform: "rotate(0deg)",
  },
});

export const AnswerPlotSelectCss = css(selectCss, {
  width: "274px !important",
  height: "42px",
});

export const popupOptionsCss = css({
  zIndex: 3001,
  pointerEvents: "auto",
  fontFamily: "$openSans !important",

  ".ant-select-item-option-selected": {
    backgroundColor: "$primary-20 !important",

    svg: {
      color: "$primary",
    },
  },

  ".ant-select-item-option-content": {
    fontFamily: "$openSans",
    color: "$black !important",
    fontWeight: "500 !important",
  },
});

export const answerPlotTextFieldCss = css(mediumTextFieldClassName, {
  width: "189px !important",
});
