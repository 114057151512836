import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { PatientAlertsTableContent } from "../../../../@types/Patient";
import { ReactComponent as ErrorCheckingAlertsImage } from "../../../../assets/images/error-checking-alerts.svg";
import { ReactComponent as NoAlertTriggeredImage } from "../../../../assets/images/no-alert-triggered.svg";
import { Table } from "../../../../components/Table";
import { TableSkeleton } from "../../../../components/TableSkeleton";
import { getPatientAlertsData } from "../../../../hooks/queries/patients";
import { useModal } from "../../../../hooks/useModal";
import { useToast } from "../../../../hooks/useToast";
import { mixpanelActions } from "../../../../utils/mixpanel";
import { desktopTableColumns } from "../AlertsSection/patientAlertsTableColumns";
import { ResolveTriggerModal } from "../ResolveTriggerModal";
import { ManageTriggersModal } from "./components/ManageTriggersModal";
import {
  AlertsTableContainer,
  AlertsTableInnerContainer,
  ImageContainer,
  ManageTriggersTitle,
  NoContentMessage,
} from "./PatientAlertsTable.style";

type PatientAlertTableProps = {
  patientId: number;
  content: PatientAlertsTableContent[];
  isLoading?: boolean;
  isError?: boolean;
  updateDropdown: () => void;
  refreshTable: () => void;
  showManageTriggersButton: boolean;
};

export function PatientAlertsTable({
  patientId,
  isLoading,
  isError,
  content,
  showManageTriggersButton,
  updateDropdown,
  refreshTable,
}: PatientAlertTableProps) {
  const { openModal, closeModal } = useModal();
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const [highlightedRows, setHighlightedRows] = useState<any>([]);
  const { refetch } = getPatientAlertsData(patientId);

  useEffect(() => {
    const rows: any[] = [];

    if (content && content.length > 0) {
      content.forEach((row) => {
        rows.push(row.alertStatus !== "Resolved");
      });
    }

    setHighlightedRows(rows);
  }, [content]);

  const handleCloseModal = (type: string, success?: boolean) => {
    if (success) {
      showToast(`${type} alert successfully resolved`, "success");

      mixpanelActions.track("User Action: Resolve Alert");

      updateDropdown();
      refetch();
      queryClient.refetchQueries({
        queryKey: [`patient-notes-table-${patientId}`, patientId],
      });
    } else if (!success) {
      showToast(`${type} alert failed to be resolved`, "error");
    }

    closeModal();
  };

  const handleRowClick = (patientAlertContent: PatientAlertsTableContent) => {
    if (patientAlertContent.alertStatus !== "Resolved") {
      openModal(
        <ResolveTriggerModal
          patientId={patientId}
          alertIds={[patientAlertContent.id]}
          type={patientAlertContent.type}
          triggered={patientAlertContent.timeOfAlert.toString()}
          onClose={(success) =>
            handleCloseModal(patientAlertContent.type, success)
          }
        />,
        {
          width: "auto",
          height: "auto",
          showCloseButton: true,
        }
      );
    }
  };

  const handleChildNotification = (
    notificationMessage: string,
    notificationType: "success" | "warning" | "error"
  ) => {
    if (notificationMessage && notificationType) {
      showToast(notificationMessage, notificationType);
    }
  };

  const handleManageTriggers = () => {
    openModal(
      <ManageTriggersModal
        setNotification={handleChildNotification}
        patientId={patientId}
      />,
      {
        width: "100%",
        height: "100%",
        showCloseButton: true,
      }
    );
  };

  if (isLoading) {
    return <TableSkeleton columnsNumber={7} />;
  }

  if (isError) {
    return (
      <ImageContainer>
        <ErrorCheckingAlertsImage />
        <p>
          Error checking alerts, please{" "}
          <button type="button" onClick={refreshTable}>
            try again
          </button>
          . If the issue continues contact support.
        </p>
      </ImageContainer>
    );
  }

  if (content && content.length > 0) {
    return (
      <>
        {showManageTriggersButton && (
          <ManageTriggersTitle onClick={handleManageTriggers}>
            Manage triggers
          </ManageTriggersTitle>
        )}
        <AlertsTableContainer>
          <AlertsTableInnerContainer>
            <Table
              data={content}
              columns={desktopTableColumns}
              onRowClick={handleRowClick}
              highlightedRows={highlightedRows}
              highlightColor={[
                "rgba(250, 209, 217, 0.48)",
                "rgba(250, 209, 217, 0.28)",
              ]}
            />
          </AlertsTableInnerContainer>
        </AlertsTableContainer>
      </>
    );
  }

  return (
    <>
      {showManageTriggersButton && (
        <ManageTriggersTitle onClick={handleManageTriggers}>
          Manage triggers
        </ManageTriggersTitle>
      )}
      <ImageContainer>
        <NoAlertTriggeredImage width={181} height={158} />
        <NoContentMessage>
          No alerts triggered yet. Ensure thresholds are correct in Manage
          triggers.
        </NoContentMessage>
      </ImageContainer>
    </>
  );
}
