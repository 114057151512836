import { useState } from "react";

const RADIUS_SELECTED = 6.9;
const RADIUS_UNSELECTED = 3;

export function CustomPromsGraphDot(props: any) {
  const [isActive, setIsActive] = useState(false);

  const {
    cy,
    cx,
    color,
    onDotClick,
    payload,
    selectedId,
    onMouseOver,
    onMouseOut,
  } = props;

  if (cy === null && cy === null) return null;

  const isSelected = isActive || payload?.id === selectedId;

  return (
    <g
      data-chart-x={cx}
      data-chart-y={cy}
      style={{ cursor: "pointer" }}
      onClick={
        onDotClick ? () => onDotClick({ ...payload, cx, cy }) : undefined
      }
      onTouchStart={
        onDotClick ? () => onDotClick({ ...payload, cx, cy }) : undefined
      }
      onMouseOver={() => {
        setIsActive(true);
        onMouseOver();
      }}
      onMouseOut={() => {
        setIsActive(false);
        onMouseOut();
      }}
      data-radius={isSelected ? RADIUS_UNSELECTED : RADIUS_SELECTED}
    >
      {!isSelected ? (
        <circle
          cx={cx}
          cy={cy}
          onClick={
            onDotClick ? () => onDotClick({ ...payload, cx, cy }) : undefined
          }
          onTouchStart={
            onDotClick ? () => onDotClick({ ...payload, cx, cy }) : undefined
          }
          r={RADIUS_UNSELECTED}
          fill={color}
          style={{ cursor: "pointer" }}
        />
      ) : (
        <>
          <circle
            onClick={
              onDotClick ? () => onDotClick({ ...payload, cx, cy }) : undefined
            }
            onTouchStart={
              onDotClick ? () => onDotClick({ ...payload, cx, cy }) : undefined
            }
            cx={cx}
            cy={cy}
            r={RADIUS_SELECTED / 2}
            fill={color}
          />
          <circle
            cx={cx}
            cy={cy}
            onClick={
              onDotClick ? () => onDotClick({ ...payload, cx, cy }) : undefined
            }
            onTouchStart={
              onDotClick ? () => onDotClick({ ...payload, cx, cy }) : undefined
            }
            r={RADIUS_SELECTED}
            fill="none"
            stroke={color}
            strokeWidth={2}
            strokeOpacity={0.6}
            style={{ cursor: "pointer" }}
          />
        </>
      )}
    </g>
  );
}
