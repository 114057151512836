import { getIn, useFormik } from "formik";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import {
  IQuestionDisplayProps,
  ISurveyAnswer,
} from "../../../../../../../@types/Surveys";
import { TextArea } from "../../../../../../../components/TextArea";
import { TextField } from "../../../../../../../components/TextField";
import { useDynamicsurveys } from "../../../../../../../hooks/useDynamicSurveys";
import {
  FormAnswerField,
  textInputCss,
  Title,
  Description,
} from "./TextQuestionDisplay.styles";

export function TextQuestionDisplay({
  title,
  description,
  type,
  questionId,
}: IQuestionDisplayProps) {
  const { surveyAnswerArray, updateSurveyAnswerArray } = useDynamicsurveys();

  const { t } = useTranslation();

  const textQuestionDisplayValidation = yup.object().shape({
    answer: yup
      .string()
      .required(t("survey_fixed_translations_validation_required")),
  });

  const textFormik = useFormik({
    initialValues: {
      answer: "",
    },
    onSubmit: () => {},
    validationSchema: textQuestionDisplayValidation,
  });

  useEffect(() => {
    const storedAnswer = surveyAnswerArray.find(
      (surveyAnswer) => surveyAnswer.surveyQuestionId === questionId
    );
    if (storedAnswer !== undefined)
      textFormik.setFieldValue("answer", storedAnswer.answerValue);
  }, []);

  const shouldShowErrorMessage = () => {
    return textFormik.touched.answer ? getIn(textFormik.errors, "answer") : "";
  };

  const updateAnswerArray = (e: any) => {
    const answerUpdate: ISurveyAnswer = {
      surveyQuestionId: questionId ?? "",
      answerValue: textFormik.values.answer,
    };
    updateSurveyAnswerArray(answerUpdate);
    textFormik.handleBlur(e);
  };

  return (
    <FormAnswerField>
      <Title> {title}</Title>
      <Description> {description}</Description>
      {type === "Text" && (
        <TextField
          isMobile
          name="answer"
          value={textFormik.values.answer}
          label=""
          backgroudColor="white"
          className={textInputCss()}
          onChange={textFormik.handleChange}
          onBlur={textFormik.handleBlur}
          onKeyUp={updateAnswerArray}
          onTouchEnd={updateAnswerArray}
          errorMessage={shouldShowErrorMessage()}
        />
      )}
      {type === "MultilineText" && (
        <TextArea
          isMobile
          name="answer"
          value={textFormik.values.answer}
          cols={10000}
          className={textInputCss()}
          onChange={textFormik.handleChange}
          rows={3}
          backgroudColor="white"
          onBlur={textFormik.handleBlur}
          onKeyUp={updateAnswerArray}
          onTouchEnd={updateAnswerArray}
          errorMessage={shouldShowErrorMessage()}
        />
      )}
    </FormAnswerField>
  );
}
