import { css, styled } from "../../../../../../../styles";
import { mediumTextFieldClassName, selectCss } from "../../DisplayTab.styles";

export const RangeSettingsContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",

  gap: "4px",
});

export const SubmitContainer = styled("div", {
  display: "flex",
  alignItems: "center",
});

// CSS

export const smallTextFieldClassName = css(mediumTextFieldClassName, {
  width: "98px !important",
});

export const smallSelectCss = css(selectCss, {
  width: "68px !important",

  "ant-select-item-option-content": {
    fontFamily: "monospace !important",
  },
});

export const selectPopUpCss = css({
  "ant-select-item-option-content": {
    fontFamily: "monospace !important",
  },
});
