import { css, styled } from "../../../../../../styles";

export const TableContainer = styled("div", {
  width: "1000px",
  height: "550px",

  display: "flex",
  alignItems: "center",
  marginLeft: "-24px",

  fontSize: "14px",

  th: {
    zIndex: 1,
  },

  variants: {
    isPinnedGraph: {
      true: {
        width: "104%",
      },
    },
  },
});

export const ImageContainer = styled("div", {
  overflowY: "overlay",
  maxHeight: "100%",

  zIndex: 1000,

  borderRadius: "8px",

  width: "100%",
  minHeight: "426px",

  backgroundColor: "$white",
  color: "$black",

  boxSizing: "border-box",

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  rowGap: "10px",

  p: {
    width: "418px",
    marginTop: "30px",
    textAlign: "center",

    button: {
      background: "none",
      color: "#7562A8",
      border: "none",
      padding: 0,
      font: "inherit",
      cursor: "pointer",
      outline: "inherit",
      fontWeight: "600",
    },
  },
});

export const NoContentMessage = styled("p", {
  marginTop: "10px !important",

  fontFamily: "$openSans",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "21px",
});

export const ContentContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  alignItems: "flex-start",

  height: "150px",
});

// CSS

export const confirmModalCss = css({
  fontFamily: "$openSans !important",
  animationDuration: "0s !important",

  ".ant-modal-content": {
    animation: "none !important",
    animationDuration: "0s !important",
    opacity: "1 !important",
    padding: "$12 $14 !important",
  },

  ".ant-modal-title": {
    fontSize: "$text-xl",
    color: "$black",
    fontWeight: "500",
    marginLeft: "5px",
  },

  ".ant-modal-footer": {
    display: "flex",
    gap: "12px",
  },
});
