import { Spin } from "antd";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { FormikProps, useFormik } from "formik";
import React, { useMemo, useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";

import { IPortalPreferences } from "../../../../@types/Preferences";
import {
  IAddProtocol,
  IAddProtocolActions,
  IProtocolActions,
  IProtocolServerResponse,
} from "../../../../@types/Protocol";
import { IOption, SelectDropdown } from "../../../../components/SelectDropdown";
import { TextField } from "../../../../components/TextField";
import {
  AUTH_PORTAL_PREFERENCES,
  NOTIFICATION,
} from "../../../../constants/localStorageKeys";
import { BuilderLayout } from "../../../../layout/components/BuilderLayout/BuilderLayout";
import { measurementsService } from "../../../../services/measurementsService";
import { protocolService } from "../../../../services/protocol.service";
import { wardsService } from "../../../../services/wardsService";
import { mixpanelActions } from "../../../../utils/mixpanel";
import { randomIntFromInterval } from "../../../../utils/random";
import { spinCss } from "../../../CareInfo/components/ArticleViewer/ArticleViewer.styles";
import { ActionSection } from "./components/ActionSection";
import { ErrorMessage } from "./components/ActionSection/ActionSection.styles";
import {
  AddActionButton,
  BodyContainer,
  LoadingContainer,
  ScrollableContainer,
  Section,
  SectionItem,
  SectionTitle,
  selectCss,
  textFieldClassName,
} from "./ProtocolBuilder.styles";
import "dayjs/locale/en-ie";

dayjs.extend(LocalizedFormat);
dayjs.extend(localeData);

export interface IOptionGroup {
  label: string;
  options: IOption[];
}

interface IActionRef {
  id: number;
  ref: React.RefObject<FormikProps<IProtocolActions>>;
}

let actionOptions: IOptionGroup[] = [];

const typeOptions: IOption[] = [
  {
    label: "Measurement",
    value: "Measurement",
    key: uuidv4(),
  },
  {
    label: "Survey",
    value: "Survey",
    key: uuidv4(),
  },
];

export function ProtocolBuilder() {
  const navigate = useNavigate();

  const { protocolId } = useParams();
  const [protocol, setProtocol] = useState<IProtocolServerResponse>();

  const [isMounted, setIsMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingWardMeasurements, setIsLoadingWardMeasurements] =
    useState(false);
  const [whiteButtonIsLoading, setWhiteButtonIsLoading] = useState(false);
  const [purpleButtonIsLoading, setPurpleButtonIsLoading] = useState(false);

  const [actionsComponents, setActionsComponents] = useState<JSX.Element[]>([]);

  const [wardOptions, setWardOptions] = useState<IOption[]>([]);
  const [intervalOptions, setIntervalOptions] = useState<IOption[]>([]);

  const [longDateFormat, setLongDateFormat] = useState<string | undefined>(
    undefined
  );

  const addActionRef = useRef<HTMLDivElement>(null);
  const actionRefs = useRef<IActionRef[]>([]);

  useEffect(() => {
    setIsMounted(true);

    actionOptions = [];

    const wardsToAdd: IOption[] = [];

    wardsService.getAvailableWards().then((wards) => {
      if (wards.data == null) return;

      wards.data?.forEach((wardData) => {
        wardsToAdd.push({
          label: wardData.hospitalName,
          value: wardData.hospitalId.toString(),
          key: uuidv4(),
        });
      });

      setWardOptions(wardsToAdd);
    });

    protocolService.getSurveyOptions().then((surveyOptions) => {
      if (surveyOptions.data == null) return;

      wardsToAdd.forEach((ward) => {
        const optionToAdd: IOptionGroup = {
          label: `Survey ${ward.value}`,
          options: [],
        };

        surveyOptions.data?.forEach((option) => {
          if (option.hospitalId.toString() === ward.value)
            optionToAdd.options.push({
              label: option.surveyDisplayName,
              value: option.surveyId,
              key: uuidv4(),
            });
        });

        actionOptions.push(optionToAdd);
      });

      setIsLoading(false);
    });

    protocolService.getIntervalOptions().then((serverOptions) => {
      if (serverOptions.data == null) return;

      const optionsToAdd: IOption[] = [];

      serverOptions.data?.forEach((option) => {
        optionsToAdd.push({
          label: option.displayName,
          value: option.value,
          key: uuidv4(),
        });
      });

      setIntervalOptions(optionsToAdd);
    });

    const portalPreferences = localStorage.getItem(AUTH_PORTAL_PREFERENCES);

    if (portalPreferences) {
      const parsedPortalPreferences = portalPreferences
        ? (JSON.parse(portalPreferences) as IPortalPreferences)
        : undefined;

      if (parsedPortalPreferences) {
        const { countryCode } = parsedPortalPreferences;
        if (countryCode !== "US") {
          dayjs.locale("en-ie");
        }

        setLongDateFormat(
          `${dayjs.localeData().longDateFormat("L")} ${dayjs
            .localeData()
            .longDateFormat("LT")}`
        );
      }
    }

    return () => {
      setIsMounted(false);
    };
  }, []);

  const handleCancel = () => {
    mixpanelActions.track("User Action: Cancel protocol");
    navigate("/protocol");
  };

  const validateActions = () => {
    let areActionsValid = true;

    actionRefs.current.forEach((actionRef) => {
      actionRef.ref.current?.submitForm();

      areActionsValid =
        areActionsValid &&
        (actionRef.ref.current?.isValid ?? false) &&
        (actionRef.ref.current?.isSubmitting ?? false);
    });

    return areActionsValid;
  };

  const handleOnSubmitForm = async ({
    id,
    protocolName,
    hospitalId,
    published,
  }: any) => {
    if (!validateActions()) return;

    const actions: IAddProtocolActions[] = [];

    actionRefs.current.forEach((actionRef) => {
      actions.push({
        ...actionRef.ref.current?.values,
        startDate: actionRef.ref.current?.values.startDate?.toISOString(),
        endDate: actionRef.ref.current?.values.endDate?.toISOString(),
      });
    });

    const request: IAddProtocol = {
      id,
      protocolName,
      hospitalId,
      published,
      actions,
    };

    if (published) {
      mixpanelActions.track("User Action: Publish protocol");
    } else {
      mixpanelActions.track("User Action: Save protocol");
    }

    if (!protocolId) {
      const result = await protocolService.addProtocol(request);

      if (result.status >= 200 && result.status < 300) {
        const notification = {
          show: true,
          message: "Successfully added protocol to patient group",
          type: "success",
          width: "max-content",
        };

        localStorage.setItem(NOTIFICATION, JSON.stringify(notification));
      } else {
        const notification = {
          show: true,
          message: "Error creating protocol, please try again",
          type: "error",
          width: "max-content",
        };

        localStorage.setItem(NOTIFICATION, JSON.stringify(notification));
      }
    } else {
      const result = await protocolService.updateProtocol(request);

      if (result.status >= 200 && result.status < 300) {
        const notification = {
          show: true,
          message: "Protocol successfully updated",
          type: "success",
          width: "max-content",
        };

        localStorage.setItem(NOTIFICATION, JSON.stringify(notification));
      } else {
        const notification = {
          show: true,
          message: "Error updating protocol, please try again",
          type: "error",
          width: "max-content",
        };

        localStorage.setItem(NOTIFICATION, JSON.stringify(notification));
      }
    }

    setPurpleButtonIsLoading(false);
    setWhiteButtonIsLoading(false);

    navigate("/protocol");
  };

  const formValidationSchema = yup.object().shape({
    protocolName: yup
      .string()
      .max(50)
      .required("Please enter a protocol name."),
    hospitalId: yup.string().required("Please select a ward."),
  });

  const formik = useFormik<IAddProtocol>({
    initialValues: {
      id: undefined,
      protocolName: "",
      hospitalId: undefined,
      published: false,
    },
    onSubmit: handleOnSubmitForm,
    validationSchema: formValidationSchema,
  });

  const handleRemoveAction = (id: number) => {
    setActionsComponents((prevComponents) => {
      const activeComponent = prevComponents.find(
        (component) => component.props.isSectionActive
      );

      const tempComponents = prevComponents.filter((x) => x.props.id !== id);

      actionRefs.current = actionRefs.current.filter((ref) => ref.id !== id);

      if (tempComponents.length <= 1) {
        return tempComponents.map((component) =>
          React.cloneElement(component, {
            isCloseButtonVisible: false,
            isSectionActive: true,
          })
        );
      }

      const wasActiveRemoved = activeComponent?.props.id === id;

      if (wasActiveRemoved) {
        return tempComponents.map((component, index) =>
          React.cloneElement(component, {
            isCloseButtonVisible: true,
            isSectionActive: index === tempComponents.length - 1,
          })
        );
      }

      return tempComponents.map((component) =>
        React.cloneElement(component, {
          isCloseButtonVisible: true,
        })
      );
    });

    mixpanelActions.track("User Action: Delete Action");
  };

  const handleActiveSection = (id: number) => {
    setActionsComponents((prevComponents) =>
      prevComponents.map((component) =>
        React.cloneElement(component, {
          isSectionActive: component.props.id === id,
        })
      )
    );
  };

  const wardsDropdown = useMemo(() => {
    if (!isMounted) return null;

    return (
      <SelectDropdown
        placeholder="Select wards"
        className={selectCss()}
        width={248}
        height={42}
        value={formik.values.hospitalId?.toString()}
        onValueChange={(value) => {
          formik.setFieldValue("hospitalId", value as string);

          const newId = Date.now();

          const actionRef = React.createRef<FormikProps<IProtocolActions>>();

          const initialActionComponent = (
            <ActionSection
              ref={actionRef}
              key={newId}
              id={newId}
              hospitalId={formik.values.hospitalId}
              intervalOptions={intervalOptions}
              typeOptions={typeOptions}
              actionOptions={actionOptions}
              longDateFormat={longDateFormat}
              isCloseButtonVisible={false}
              isSectionActive
              disableNotEditableFields
              onRemove={handleRemoveAction}
              handleActiveSection={handleActiveSection}
            />
          );

          actionRefs.current = [{ id: newId, ref: actionRef }];

          setActionsComponents([initialActionComponent]);
        }}
        options={wardOptions}
      />
    );
  }, [formik.values.hospitalId, wardOptions, isMounted]);

  useEffect(() => {
    if (formik.values.hospitalId === undefined) return;

    setIsLoadingWardMeasurements(true);

    measurementsService
      .getMeasurementTypesByWards([formik.values.hospitalId])
      .then((measurementTypeOptions) => {
        if (measurementTypeOptions.data == null) return;

        const options: IOption[] = [];

        measurementTypeOptions.data?.forEach((measurementType) => {
          options?.push({
            label: measurementType.measurementTypeDisplayName,
            value: measurementType.measurementType,
            key: uuidv4(),
          });
        });

        const optionToAdd: IOptionGroup = {
          label: "Measurement",
          options,
        };

        const measuremntsIndex = actionOptions.findIndex(
          (x) => x.label === "Measurement"
        );

        if (measuremntsIndex >= 0) {
          actionOptions[measuremntsIndex] = optionToAdd;
        } else {
          actionOptions.push(optionToAdd);
        }

        setIsLoadingWardMeasurements(false);
      });
  }, [formik.values.hospitalId]);

  useEffect(() => {
    if ((isLoading && actionOptions) || protocolId !== undefined) return;

    const newId = Date.now();

    const actionRef = React.createRef<FormikProps<IProtocolActions>>();

    const initialActionComponent = (
      <ActionSection
        ref={actionRef}
        key={newId}
        id={newId}
        hospitalId={formik.values.hospitalId}
        intervalOptions={intervalOptions}
        typeOptions={typeOptions}
        actionOptions={actionOptions}
        longDateFormat={longDateFormat}
        isCloseButtonVisible={false}
        isSectionActive
        disableNotEditableFields
        onRemove={handleRemoveAction}
        handleActiveSection={handleActiveSection}
      />
    );

    actionRefs.current = [{ id: newId, ref: actionRef }];

    setActionsComponents([initialActionComponent]);
  }, [isLoading, formik.values.hospitalId, protocolId]);

  const handleAddAction = () => {
    const newId = Date.now();

    const actionRef = React.createRef<FormikProps<IProtocolActions>>();

    const newActionComponent = (
      <ActionSection
        ref={actionRef}
        key={newId}
        id={newId}
        hospitalId={formik.values.hospitalId}
        intervalOptions={intervalOptions}
        typeOptions={typeOptions}
        actionOptions={actionOptions}
        longDateFormat={longDateFormat}
        isCloseButtonVisible
        isSectionActive
        disableNotEditableFields
        onRemove={handleRemoveAction}
        handleActiveSection={handleActiveSection}
      />
    );

    setActionsComponents((prevComponents) => {
      const updatedComponents = prevComponents.map((component) => {
        return React.cloneElement(component, {
          isCloseButtonVisible: true,
          isSectionActive: false,
        });
      });

      actionRefs.current = [
        ...actionRefs.current,
        { id: newId, ref: actionRef },
      ];
      return [...updatedComponents, newActionComponent];
    });

    mixpanelActions.track("User Action: + Add Action");

    setTimeout(() => {
      addActionRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  const handleOnSaveProtocol = (isPublished: boolean) => {
    formik.setFieldValue("published", isPublished);

    const areActionsValid = validateActions();

    if (areActionsValid && formik.isValid) {
      setPurpleButtonIsLoading(true);
      setWhiteButtonIsLoading(true);
    }

    formik.handleSubmit();
  };

  useEffect(() => {
    if (
      protocolId &&
      wardOptions.length &&
      intervalOptions.length &&
      actionOptions
    ) {
      protocolService.getProtocolById(protocolId).then((serverProtocol) => {
        const protocol = serverProtocol.data;

        setProtocol(protocol);

        formik.setValues({
          id: protocol.id,
          protocolName: protocol.protocolName,
          hospitalId: protocol.hospitalId,
          published: protocol.published,
        });

        protocol.actions.forEach((action, index) => {
          const newId = randomIntFromInterval(1, 1000000);

          const actionRef = React.createRef<FormikProps<IProtocolActions>>();
          const newActionComponent = (
            <ActionSection
              ref={actionRef}
              key={newId}
              id={newId}
              hospitalId={protocol.hospitalId}
              intervalOptions={intervalOptions}
              typeOptions={typeOptions}
              actionOptions={actionOptions}
              longDateFormat={longDateFormat}
              content={action}
              isCloseButtonVisible={protocol.actions.length > 1}
              isSectionActive={index === 0}
              onRemove={handleRemoveAction}
              handleActiveSection={handleActiveSection}
            />
          );

          setActionsComponents((prevComponents) => {
            actionRefs.current = [
              ...actionRefs.current,
              { id: newId, ref: actionRef },
            ];

            return [...prevComponents, newActionComponent];
          });
        });
      });
    }
  }, [protocolId, wardOptions, intervalOptions]);

  return (
    <BuilderLayout
      location="Protocols"
      isPurpleButtonLoading={purpleButtonIsLoading}
      isWhiteButtonLoading={whiteButtonIsLoading}
      isLayoutLoading={isLoading}
      handleOnCancel={handleCancel}
      lastEdited={protocol?.updated || protocol?.created}
      handleOnSave={handleOnSaveProtocol}
      isPublished={protocol?.published ?? false}
    >
      <ScrollableContainer>
        <BodyContainer>
          <SectionTitle>{protocolId ? "Edit" : "New"} protocol</SectionTitle>

          <Section>
            <SectionItem>
              <p>Protocol name *</p>
              <TextField
                label=""
                placeholder="Name"
                name="protocolName"
                value={formik.values.protocolName}
                onChange={formik.handleChange}
                className={textFieldClassName()}
                backgroudColor="white"
              />
              <ErrorMessage>
                {formik?.touched.protocolName
                  ? formik?.errors.protocolName ?? ""
                  : ""}
              </ErrorMessage>
            </SectionItem>

            <SectionItem>
              <p>Ward *</p>
              {wardsDropdown}
              <ErrorMessage isWards>
                {formik?.touched.hospitalId
                  ? formik?.errors.hospitalId ?? ""
                  : ""}
              </ErrorMessage>
            </SectionItem>
          </Section>

          {formik.values.hospitalId !== undefined &&
          !isLoadingWardMeasurements ? (
            <>
              <SectionTitle isActionTitle>Actions</SectionTitle>

              {actionsComponents}

              <AddActionButton
                onClick={handleAddAction}
                ref={addActionRef}
                tabIndex={0}
              >
                <p>+</p> Add action
              </AddActionButton>
            </>
          ) : null}

          {isLoadingWardMeasurements ? (
            <LoadingContainer>
              <Spin className={spinCss()} size="large" />
            </LoadingContainer>
          ) : null}
        </BodyContainer>
      </ScrollableContainer>
    </BuilderLayout>
  );
}
