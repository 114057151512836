import { Form, Formik, FormikProps, getIn } from "formik";
import { forwardRef, useEffect } from "react";
import * as yup from "yup";

import {
  IRangeOption,
  ISurveyDisplayRules,
  ISurveySection,
} from "../../../../../@types/Surveys";
import { SelectDropdown } from "../../../../../components/SelectDropdown";
import { TextField } from "../../../../../components/TextField";
import { GraphTypes } from "../../../../../constants/surveys";
import { getRandomHexColor } from "../../../../../utils/colorGenerator";
import { IDisplayRulesOptions } from "../../SurveyBuilder";
import { AnswerPlotForm } from "./Components/AnswerPlotForm";
import { TotalScoreWithLegendForm } from "./Components/TotalScoreWithLegendForm";
import {
  DisplayRulescontainer,
  DisplaySubTitle,
  DisplayTitle,
  MultipleInputsRow,
  RowElement,
  selectCss,
  mediumTextFieldClassName,
  infoTextFieldClassName,
  ErrorMessage,
  WarningMessage,
} from "./DisplayTab.styles";

type RangeOptionFormik = Partial<IRangeOption>;

interface ISurveyDisplayBaseProps {
  displayRulesOptions: IDisplayRulesOptions;
  hasScoreStrategy: boolean;
  sections: ISurveySection[];
}

interface ISurveyDisplayFormikProps extends ISurveyDisplayBaseProps {
  displayRules: ISurveyDisplayRules;
}

interface ISurveyDisplayProps extends ISurveyDisplayBaseProps {
  displaySettingsFormik: FormikProps<ISurveyDisplayRules>;
}

function SurveyBuilderDisplayTab({
  displayRulesOptions,
  sections,
  displaySettingsFormik,
  hasScoreStrategy,
}: ISurveyDisplayProps) {
  const defaultRange = {
    start: undefined,
    end: undefined,
    color: getRandomHexColor(),
    name: undefined,
    startComparator: undefined,
    endComparator: undefined,
  };

  const defaultLabel = {
    labelIds: undefined,
    text: undefined,
  };

  const defaultLegend = {
    legendIds: undefined,
    text: undefined,
    color: getRandomHexColor(),
  };

  const shouldShowErrorMessage = (field: "yAxisMin" | "yAxisMax") => {
    return displaySettingsFormik.touched[field] ||
      displaySettingsFormik.touched.ranges
      ? getIn(displaySettingsFormik.errors, field)
      : "";
  };

  const addRange = () => {
    displaySettingsFormik.setFieldValue("ranges", [
      ...(displaySettingsFormik.values.ranges ?? []),
      defaultRange,
    ]);
  };

  const addLabel = () => {
    displaySettingsFormik.setFieldValue("yAxisLabels", [
      ...(displaySettingsFormik.values.yAxisLabels ?? []),
      defaultLabel,
    ]);
  };

  const addLegend = () => {
    displaySettingsFormik.setFieldValue("legends", [
      ...(displaySettingsFormik.values.legends ?? []),
      defaultLegend,
    ]);
  };

  const resetTotalScoreGraphValues = () => {
    const updatedDisplayRules = displaySettingsFormik.values;
    updatedDisplayRules.yAxisMin = undefined;
    updatedDisplayRules.yAxisMax = undefined;
    updatedDisplayRules.ranges = undefined;
    displaySettingsFormik.setValues(updatedDisplayRules);
  };

  const resetAnswerPlotValues = () => {
    const updatedDisplayRules = displaySettingsFormik.values;
    updatedDisplayRules.dataSourceType = undefined;
    updatedDisplayRules.legends = undefined;
    updatedDisplayRules.yAxisLabels = undefined;
    displaySettingsFormik.setValues(updatedDisplayRules);
  };

  const handleGraphTypeChange = () => {
    const { type } = displaySettingsFormik.values;
    if (type === GraphTypes.totalScoreGraphWithLegend) {
      resetAnswerPlotValues();
      if (!displaySettingsFormik.values.ranges?.length) {
        addRange();
      }
    } else if (type === GraphTypes.answerPlot) {
      resetTotalScoreGraphValues();

      if (!displaySettingsFormik.values.yAxisLabels?.length) {
        addLabel();
      }
      if (!displaySettingsFormik.values.legends?.length) {
        addLegend();
      }
    } else if (type === GraphTypes.tableView) {
      resetAnswerPlotValues();
      resetTotalScoreGraphValues();
    }
  };

  const renderGraphTypeForm = () => {
    switch (displaySettingsFormik.values.type) {
      case GraphTypes.totalScoreGraphWithLegend:
        return (
          <TotalScoreWithLegendForm
            displaySettingsFormik={displaySettingsFormik}
            displayRulesOptions={displayRulesOptions}
            addRangeOption={addRange}
          />
        );
      case GraphTypes.answerPlot:
        return (
          <AnswerPlotForm
            displaySettingsFormik={displaySettingsFormik}
            sections={sections}
            addLabel={addLabel}
            addLegend={addLegend}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    handleGraphTypeChange();
  }, [displaySettingsFormik.values.type]);

  return (
    <>
      <DisplayTitle>Display rules</DisplayTitle>
      <DisplayRulescontainer>
        <MultipleInputsRow>
          <RowElement>
            <DisplaySubTitle>Graph type *</DisplaySubTitle>
            <SelectDropdown
              className={selectCss()}
              width={248}
              height={42}
              showSearch
              optionFilterProp="label"
              value={displaySettingsFormik.values.type}
              onValueChange={(value) => {
                displaySettingsFormik.setFieldValue("type", value.toString());
              }}
              options={displayRulesOptions.displayRulesTypes}
            />
            {!hasScoreStrategy &&
              displaySettingsFormik.values.type !== GraphTypes.tableView && (
                <WarningMessage>
                  Please add a score strategy in the Content tab
                </WarningMessage>
              )}
          </RowElement>
          {displaySettingsFormik.values.type ===
            GraphTypes.totalScoreGraphWithLegend && (
            <>
              <RowElement>
                <DisplaySubTitle>Y-axis min *</DisplaySubTitle>
                <TextField
                  label=""
                  placeholder="min"
                  name="yAxisMin"
                  value={displaySettingsFormik.values.yAxisMin}
                  onChange={displaySettingsFormik.handleChange}
                  className={mediumTextFieldClassName()}
                  backgroudColor="white"
                  onBlur={displaySettingsFormik.handleBlur}
                />
                <ErrorMessage>
                  {shouldShowErrorMessage("yAxisMin")}
                </ErrorMessage>
              </RowElement>
              <RowElement>
                <DisplaySubTitle>Y-axis max *</DisplaySubTitle>
                <TextField
                  label=""
                  placeholder="max"
                  name="yAxisMax"
                  value={displaySettingsFormik.values.yAxisMax}
                  onChange={displaySettingsFormik.handleChange}
                  className={mediumTextFieldClassName()}
                  backgroudColor="white"
                  onBlur={displaySettingsFormik.handleBlur}
                />
                <ErrorMessage>
                  {shouldShowErrorMessage("yAxisMax")}
                </ErrorMessage>
              </RowElement>
            </>
          )}
        </MultipleInputsRow>
        <RowElement>
          <DisplaySubTitle>HCP Information</DisplaySubTitle>
          <TextField
            label=""
            placeholder="Add information or definition that is available on the portal"
            name="hcpInformation"
            value={displaySettingsFormik.values.hcpInformation}
            onChange={displaySettingsFormik.handleChange}
            className={infoTextFieldClassName()}
            backgroudColor="white"
          />
        </RowElement>
        <RowElement>
          <DisplaySubTitle>Patient Information</DisplaySubTitle>
          <TextField
            label=""
            placeholder="Add information or definition that is available on the patient app"
            name="patientInformation"
            value={displaySettingsFormik.values.patientInformation}
            onChange={displaySettingsFormik.handleChange}
            className={infoTextFieldClassName()}
            backgroudColor="white"
          />
        </RowElement>

        {renderGraphTypeForm()}
      </DisplayRulescontainer>
    </>
  );
}

export const DisplaySettingsFormik = forwardRef<
  FormikProps<ISurveyDisplayRules>,
  ISurveyDisplayFormikProps
>(({ displayRules, displayRulesOptions, hasScoreStrategy, sections }, ref) => {
  const handleOnSubmitForm = () => {};

  const checkOverlap = (
    range1: RangeOptionFormik,
    range2: RangeOptionFormik
  ) => {
    if (
      range1.start === undefined ||
      range1.startComparator === undefined ||
      range1.end === undefined ||
      range1.endComparator === undefined ||
      range2.start === undefined ||
      range2.startComparator === undefined
    ) {
      return false;
    }

    if (
      range1.start === range2.start &&
      !(
        (range1.startComparator === ">" && range2.startComparator === "=") ||
        (range1.startComparator === "=" && range2.startComparator === ">")
      )
    ) {
      return true;
    }
    if (range1.startComparator === "=" && range2.startComparator !== "=") {
      return false;
    }

    if (range1.startComparator === "=" && range2.startComparator === "=") {
      return false;
    }

    if (
      range2.start < range1.end ||
      (range2.start === range1.end &&
        range1.endComparator.includes("=") &&
        range2.startComparator !== ">")
    ) {
      return true;
    }
    return false;
  };

  const rangesValidationSchema = yup.object().shape({
    start: yup.number().when("$type", {
      is: GraphTypes.totalScoreGraphWithLegend,
      then: yup
        .number()
        .typeError("Value must be a number")
        .lessThan(yup.ref("$yAxisMax"), "Value must be smaller than Y-axis max")
        .min(yup.ref("$yAxisMin"), "Value can't be smaller than Y-axis min")
        .required("Value required"),
      otherwise: yup.number(),
    }),

    end: yup.number().when(["$type", "startComparator"], {
      is: (type: string, startComparator: string) =>
        type === GraphTypes.totalScoreGraphWithLegend &&
        startComparator !== "=",
      then: yup
        .number()
        .typeError("Value must be a number")
        .moreThan(yup.ref("$yAxisMin"), "Value must be bigger than Y-axis min")
        .max(yup.ref("$yAxisMax"), "Value can't be bigger than Y-axis max")
        .moreThan(yup.ref("start"), "Value has to be greater than start value")
        .required("Value required"),
      otherwise: yup.number(),
    }),

    startComparator: yup.string().when("$type", {
      is: GraphTypes.totalScoreGraphWithLegend,
      then: yup.string().required("Comparator required"),
      otherwise: yup.string(),
    }),

    endComparator: yup.string().when(["$type", "startComparator"], {
      is: (type: string, startComparator: string) =>
        type === GraphTypes.totalScoreGraphWithLegend &&
        startComparator !== "=",
      then: yup.string().required("Comparator required"),
      otherwise: yup.string(),
    }),

    color: yup.string().when("$type", {
      is: GraphTypes.totalScoreGraphWithLegend,
      then: yup.string().required("Color required"),
      otherwise: yup.string(),
    }),

    name: yup.string().when("$type", {
      is: GraphTypes.totalScoreGraphWithLegend,
      then: yup.string().required("Name required"),
      otherwise: yup.string(),
    }),
  });

  const formValidationSchema = yup.object().shape({
    type: yup.string(),
    hcpInformation: yup.string(),
    patientInformation: yup.string(),
    yAxisMin: yup.number().when("type", {
      is: GraphTypes.totalScoreGraphWithLegend,
      then: yup
        .number()
        .typeError("Must be a number")
        .min(0, "Y-axis min can't be negative")
        .required("Required"),
      otherwise: yup.number().nullable(),
    }),
    yAxisMax: yup.number().when("type", {
      is: GraphTypes.totalScoreGraphWithLegend,
      then: yup
        .number()
        .typeError("Must be a number")
        .moreThan(0, "Must be greater than 0")
        .moreThan(yup.ref("yAxisMin"), "Must be bigger than Y-axis min")
        .required("Required"),
      otherwise: yup.number().nullable(),
    }),
    ranges: yup.array().when("type", {
      is: GraphTypes.totalScoreGraphWithLegend,
      then: yup
        .array()
        .of(rangesValidationSchema)
        .required("An array is required")
        .min(1, "At least one range option required")
        .test(
          "no-overlapping-ranges",
          "Intervals cannot overlap",
          function overlapTest(ranges) {
            if (!ranges || ranges.length < 2) return true;

            const validRanges = ranges.filter(
              (range) =>
                typeof range.start === "number" && typeof range.end === "number"
            );

            if (validRanges.length < 2) return true;

            const sortedRanges = [...validRanges].sort(
              (a, b) => (a.start ?? 0) - (b.start ?? 0)
            );

            for (let i = 0; i < sortedRanges.length - 1; i += 1) {
              const current = sortedRanges[i];
              const next = sortedRanges[i + 1];

              if (checkOverlap(current, next)) {
                return false;
              }
            }
            return true;
          }
        ),
      otherwise: yup.array(),
    }),
    dataSourceType: yup.string().when("type", {
      is: GraphTypes.answerPlot,
      then: yup.string().required("Required"),
      otherwise: yup.string(),
    }),
    yAxisLabels: yup.array().when("type", {
      is: GraphTypes.answerPlot,
      then: yup
        .array()
        .of(
          yup.object().shape({
            labelIds: yup
              .array()
              .min(1, "Select at least one")
              .required("Required"),
            text: yup.string().required("Required"),
          })
        )
        .required("An array is required")
        .min(1, "At least one label option required"),
      otherwise: yup.array(),
    }),
    legends: yup.array().when("type", {
      is: GraphTypes.answerPlot,
      then: yup
        .array()
        .of(
          yup.object().shape({
            legendIds: yup
              .array()
              .min(1, "Select at least one")
              .required("Required"),
            text: yup.string().required("Required"),
            color: yup.string().required("Required"),
          })
        )
        .required("An array is required")
        .min(1, "At least one label option required"),
      otherwise: yup.array(),
    }),
  });
  return (
    <Formik<ISurveyDisplayRules>
      innerRef={ref}
      initialValues={displayRules}
      onSubmit={handleOnSubmitForm}
      validationSchema={formValidationSchema}
      enableReinitialize
    >
      {(formik) => (
        <Form>
          <SurveyBuilderDisplayTab
            sections={sections}
            hasScoreStrategy={hasScoreStrategy}
            displaySettingsFormik={formik}
            displayRulesOptions={displayRulesOptions}
          />
        </Form>
      )}
    </Formik>
  );
});
