import { DatePicker } from "antd";
import { PickerLocale } from "antd/es/date-picker/generatePicker";
import enGB from "antd/es/date-picker/locale/en_GB";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  IQuestionDisplayProps,
  ISurveyAnswer,
} from "../../../../../../../@types/Surveys";
import { useDynamicsurveys } from "../../../../../../../hooks/useDynamicSurveys";
import {
  Title,
  Description,
} from "../TextQuestionDisplay/TextQuestionDisplay.styles";
import {
  EuropeConfig,
  NorthAmericaConfig,
  NorthAmericaCountryCodes,
} from "./DatePickerConfig";
import {
  dateInputCss,
  datePopUptCss,
  DateAnswerField,
} from "./DateQuestiondisplay.styles";

export function DateQuestionDisplay({
  title,
  description,
  questionId,
}: IQuestionDisplayProps) {
  const [answer, setAnswer] = useState<string>();
  const [renderDatePicker, setRenderDatePicker] = useState<boolean>(false);

  const [customLocale, setCustomLocale] = useState<PickerLocale>(enGB);

  const { surveyAnswerArray, updateSurveyAnswerArray } = useDynamicsurveys();

  dayjs.extend(updateLocale);

  const { t, i18n } = useTranslation();

  const isEurope = !NorthAmericaCountryCodes.includes(i18n.language);

  const updateAnswerArray = (newDate: string) => {
    const answerUpdate: ISurveyAnswer = {
      surveyQuestionId: questionId ?? "",
      answerValue: newDate?.toString() ?? "",
    };
    updateSurveyAnswerArray(answerUpdate);
  };

  const setTranslations = () => {
    setCustomLocale((prevState) => ({
      ...prevState,
      lang: {
        ...prevState.lang,
        shortMonths: [
          t("survey_fixed_translations_date_picker_month_jan"),
          t("survey_fixed_translations_date_picker_month_feb"),
          t("survey_fixed_translations_date_picker_month_mar"),
          t("survey_fixed_translations_date_picker_month_apr"),
          t("survey_fixed_translations_date_picker_month_may"),
          t("survey_fixed_translations_date_picker_month_jun"),
          t("survey_fixed_translations_date_picker_month_jul"),
          t("survey_fixed_translations_date_picker_month_aug"),
          t("survey_fixed_translations_date_picker_month_sep"),
          t("survey_fixed_translations_date_picker_month_oct"),
          t("survey_fixed_translations_date_picker_month_nov"),
          t("survey_fixed_translations_date_picker_month_dec"),
        ],
        shortWeekDays: [
          t("survey_fixed_translations_date_picker_week_sun"),
          t("survey_fixed_translations_date_picker_week_mon"),
          t("survey_fixed_translations_date_picker_week_tue"),
          t("survey_fixed_translations_date_picker_week_wed"),
          t("survey_fixed_translations_date_picker_week_thu"),
          t("survey_fixed_translations_date_picker_week_fri"),
          t("survey_fixed_translations_date_picker_week_sat"),
        ],
        today: t("survey_fixed_translations_date_picker_today"),
      },
    }));

    dayjs.updateLocale(dayjs.locale(), {
      weekStart: isEurope
        ? EuropeConfig.weekStart
        : NorthAmericaConfig.weekStart,
    });
  };

  useEffect(() => {
    const storedAnswer = surveyAnswerArray.find(
      (answerValue) => answerValue.surveyQuestionId === questionId
    );
    if (storedAnswer?.answerValue !== "") {
      const dateUpdate = new Date(
        dayjs(storedAnswer?.answerValue).toDate()
      ).toDateString();
      setAnswer(dateUpdate);
    }

    setRenderDatePicker(true);
    setTranslations();
  }, []);

  return (
    <DateAnswerField>
      <Title> {title}</Title>
      <Description> {description}</Description>

      {renderDatePicker && (
        <DatePicker
          format={
            isEurope ? EuropeConfig.dateFormat : NorthAmericaConfig.dateFormat
          }
          locale={customLocale}
          className={dateInputCss()}
          enterKeyHint="done"
          id="Picker"
          inputReadOnly
          placeholder={t("survey_fixed_translations_date_picker_choose_date")}
          placement="bottomRight"
          value={answer ? dayjs(answer) : null}
          popupClassName={datePopUptCss()}
          onChange={(date) => {
            if (date) {
              const newDate = new Date(date.toDate()).toISOString();
              setAnswer(newDate);
              updateAnswerArray(newDate);
            } else {
              setAnswer(undefined);
              updateAnswerArray("");
            }
          }}
        />
      )}
    </DateAnswerField>
  );
}
