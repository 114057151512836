import { Tooltip } from "antd";
import dayjs from "dayjs";
import { ReactElement, useEffect, useState } from "react";

import { IArrhythmiaStatus } from "../../../../../../@types/Measurements";
import {
  ChartTitle,
  StyledSubtitle,
  StyledTile,
} from "../../../../../../components/ChartCard/ChartCard.styles";
import {
  ChartCardValue,
  ChartData,
} from "../../../../../../components/ChartCard/ChartCard.types";
import { ChartCardLoading } from "../../../../../../components/ChartCard/ChartCardLoading";
import { ChartSpecificValues } from "../../../../../../components/ChartCard/ChartSpecificValues/ChartSpecificValues";
import { Tag } from "../../../../../../components/Tag";
import { fibriCheckColorDictionary } from "../../../../../../constants/measurements";
import {
  Divider,
  FibricheckChartDataContainer,
  FibricheckMeasurement,
} from "./FibriCheckChartCard.styles";

export type FibricheckChartCardProps = {
  data: Map<string, ChartData[]> | Map<string, any[]>;
  isLoading?: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
};

export function FibricheckChartCard({
  data,
  isLoading,
  onClick,
}: FibricheckChartCardProps) {
  const [latestValue, setLatestVaule] = useState<ChartCardValue[]>([]);
  const [measurementComponents, setMeasurementComponents] = useState<
    ReactElement[]
  >([]);

  const isEmptyState = !data || !data.size;

  useEffect(() => {
    const lastMeasurements = !isEmptyState
      ? Array.from(data.values())
          .flat()
          .sort((a: any, b: any) => b.unformattedTime - a.unformattedTime)
          .slice(0, 3)
      : [];

    const measurementsToReturn: any[] = [];

    lastMeasurements.forEach((measurement, index) => {
      const bpmAndDate = `${measurement.heart_rate} bpm - ${dayjs(
        measurement.unformattedTime
      ).format("MMM DD - HH:mm")}`;

      const parsedObject = JSON.parse(measurement.arrhythmia_status);

      const arrhythmiaStatus: IArrhythmiaStatus = {
        key: parsedObject.Key,
        color: parsedObject.Color,
      };

      const formattedKey = arrhythmiaStatus.key
        .replace(/_/g, " ")
        .replace(/^./, (char) => char.toUpperCase());

      if (!isEmptyState && index === 0) {
        setLatestVaule([{ label: "Latest", value: formattedKey }]);
      }

      const tagColor = fibriCheckColorDictionary[arrhythmiaStatus.color] as
        | "success"
        | "info"
        | "tertiary"
        | "error"
        | "warning";

      measurementsToReturn.push(
        <div key={bpmAndDate} style={{ width: "100%" }}>
          <FibricheckMeasurement>
            <p>{bpmAndDate}</p>
            <Tooltip
              title={formattedKey}
              arrow={false}
              placement="top"
              zIndex={3000}
              styles={{
                body: {
                  padding: "6px 12px",
                  minHeight: "40px",
                  width: "max-content",
                  borderRadius: "4px !important",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "'Open Sans', sans-serif",
                },
              }}
            >
              <div>
                <Tag variant={tagColor} clickable={false}>
                  {formattedKey}
                </Tag>
              </div>
            </Tooltip>
          </FibricheckMeasurement>

          {index !== lastMeasurements.length - 1 ? (
            <Divider key={`${bpmAndDate}-divider`} />
          ) : null}
        </div>
      );
    });

    setMeasurementComponents(measurementsToReturn);
  }, [data]);

  return (
    <StyledTile onClick={onClick}>
      {isLoading ? (
        <ChartCardLoading />
      ) : (
        <>
          <ChartTitle>Arrhythmia (FibriCheck)</ChartTitle>
          {isEmptyState ? (
            <StyledSubtitle>No data for selected dates</StyledSubtitle>
          ) : null}

          <FibricheckChartDataContainer>
            {measurementComponents}
          </FibricheckChartDataContainer>

          <ChartSpecificValues values={latestValue} />
        </>
      )}
    </StyledTile>
  );
}
