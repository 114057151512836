import { Spin } from "antd";
import { ReactNode } from "react";

import { Button } from "../../../components/Button";
import { getLongFormattedDate } from "../../../utils/dateFormatter";
import {
  BuilderLayoutContainer,
  ButtonsContainer,
  CancelButton,
  Header,
  purpleButton,
  SectionButton,
  SectionsContainer,
  SectionsHeader,
  spinCss,
  Underline,
  whiteButton,
} from "./BuilderLayout.styles";

type LocationType = "Workflows" | "Protocols" | "Articles" | "Surveys";

export interface ISectionButton {
  isSelected: boolean;
  label: string;
  onButtonClick: (section: string) => void;
  name: string;
}

// export interface ISectionButtonSurveys extends ISectionButton {
//     onB
// }

interface IBuilderLayoutPropsBase {
  hasDashboardLayout?: boolean;
  children: ReactNode;
  isLayoutLoading?: boolean;
  isWhiteButtonLoading?: boolean;
  isPurpleButtonLoading?: boolean;
  lastEdited?: string;
  location: LocationType;
  handleOnCancel: () => void;
  isPublished?: boolean;
  handleOnSave: (isPublished: boolean) => void;
  sectionButtons?: ISectionButton[];
}

interface IWorkflowBuilder extends IBuilderLayoutPropsBase {
  location: "Workflows";
  handleOnSave: (publish: boolean, isSave?: boolean) => void;
}

interface IProtocolBuilder extends IBuilderLayoutPropsBase {
  location: "Protocols";
  isPublished: boolean;
}

interface IArticleBuilder extends IBuilderLayoutPropsBase {
  location: "Articles";
  isPublished: boolean;
}

interface ISurveyBuilder extends IBuilderLayoutPropsBase {
  location: "Surveys";
  handleOnSave: () => void;
}

type IBuilderLayoutProps =
  | IWorkflowBuilder
  | IProtocolBuilder
  | ISurveyBuilder
  | IArticleBuilder;

export function BuilderLayout({
  lastEdited,
  location,
  isLayoutLoading,
  isPurpleButtonLoading,
  hasDashboardLayout,
  sectionButtons,
  children,
  isWhiteButtonLoading,
  isPublished,
  handleOnCancel,
  handleOnSave,
}: IBuilderLayoutProps) {
  const getPurpleButtonLabel = () => {
    if ((location === "Protocols" || location === "Articles") && isPublished)
      return "Unpublish";
    return "Save";
  };

  const getWhiteButtonLabel = () => {
    if (location === "Surveys") {
      return "Save";
    }
    if (isPublished) {
      return "Publish changes";
    }
    return "Publish";
  };

  const handleOnClickPurpleButton = () => {
    if (location === "Protocols" || location === "Articles") {
      handleOnSave(false);
    } else {
      handleOnSave(isPublished ?? false, true);
    }
  };

  return (
    <BuilderLayoutContainer hasDashboardLayout={hasDashboardLayout}>
      {isLayoutLoading ? (
        <Spin className={spinCss()} fullscreen size="large" />
      ) : (
        <>
          <Header>
            {lastEdited && (
              <p>* Last edited {getLongFormattedDate(lastEdited)}</p>
            )}

            <ButtonsContainer>
              <CancelButton onClick={handleOnCancel} tabIndex={0}>
                Cancel
              </CancelButton>
              {location !== "Surveys" ? (
                <Button
                  disabled={isPurpleButtonLoading}
                  label={getPurpleButtonLabel()}
                  type="submit"
                  onClick={handleOnClickPurpleButton}
                  className={purpleButton()}
                  isLoading={isPurpleButtonLoading}
                />
              ) : null}

              <Button
                disabled={isWhiteButtonLoading}
                label={getWhiteButtonLabel()}
                type="button"
                onClick={() => handleOnSave(true)}
                className={whiteButton()}
                isLoading={isWhiteButtonLoading}
              />
            </ButtonsContainer>
          </Header>
          {sectionButtons && (
            <SectionsHeader>
              <SectionsContainer>
                {sectionButtons.map((button, index) => (
                  <SectionButton
                    key={index}
                    onClick={() => button.onButtonClick(button.name)}
                    onKeyDown={(e) =>
                      e.key === "Enter" && button.onButtonClick(button.name)
                    }
                    tabIndex={0}
                  >
                    <p>{button.label}</p>
                    <Underline isActive={button.isSelected} />{" "}
                  </SectionButton>
                ))}
              </SectionsContainer>
            </SectionsHeader>
          )}

          {children}
        </>
      )}
    </BuilderLayoutContainer>
  );
}
