import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Notification } from "../../../../@types/Notification";
import { IProtocolResponse } from "../../../../@types/Protocol";
import { ProtocolsEmptyState } from "../../../../assets/icons/ProtocolsEmptyState";
import { Table } from "../../../../components/Table";
import { TableSkeleton } from "../../../../components/TableSkeleton";
import { NOTIFICATION } from "../../../../constants/localStorageKeys";
import { useToast } from "../../../../hooks/useToast";
import { ImageContainer } from "../../../PatientPage/components/PatientAlertsTable/PatientAlertsTable.style";
import { AddProtocolButton } from "../../ProtocolList.styles";
import { protocolListColumns } from "../../ProtocolListColumns";

type ProtocolListTableProps = {
  content: IProtocolResponse[];
  isLoading?: boolean;
  refetch(): void;
};

export function ProtocolListTable({
  content,
  isLoading = false,
  refetch,
}: ProtocolListTableProps) {
  const navigate = useNavigate();
  const { showToast } = useToast();

  const handleRowClick = async (content: any) => {
    navigate(`/protocol/builder/${content.id}`);
  };

  const handleAddProtocol = () => {
    navigate("/protocol/builder");
  };

  useEffect(() => {
    const notification = localStorage.getItem(NOTIFICATION);
    const parsedNotification = JSON.parse(notification ?? "{}") as Notification;

    if (notification) {
      refetch();
      showToast(parsedNotification.message, parsedNotification.type);
      localStorage.removeItem(NOTIFICATION);
    }
  }, []);

  if (isLoading) {
    return <TableSkeleton columnsNumber={8} />;
  }

  if (content && content.length > 0) {
    return (
      <>
        <AddProtocolButton onClick={handleAddProtocol}>
          Create protocol
        </AddProtocolButton>
        <Table
          data={content}
          onRowClick={handleRowClick}
          emptyStateText="No protocols"
          columns={protocolListColumns}
          showMaxContent
        />
      </>
    );
  }

  return (
    <>
      <AddProtocolButton onClick={handleAddProtocol}>
        Create protocol
      </AddProtocolButton>
      <ImageContainer style={{ height: "800px", minHeight: "30px" }}>
        <ProtocolsEmptyState />
        <p>
          You have not added any protocols yet. <span>Add protocols </span>here
          to increase patient engagement with PROMs and spirometry.
        </p>
      </ImageContainer>
    </>
  );
}
