import { useEffect, useState } from "react";

const FOCUS_MODE_LEGEND_RADIUS = 4;

const DEFAULT_RADIUS = 2.5;
const ACTIVE_RADIUS = 3;

export function CircleDot(props: any) {
  const { cx, cy, fill, lineOpacity, legend, focusMode, onDotClick } = props;

  const [active, setActive] = useState(false);
  const [radius, setRadius] = useState(
    legend && focusMode ? FOCUS_MODE_LEGEND_RADIUS : DEFAULT_RADIUS
  );

  useEffect(() => {
    if (!legend) setRadius(active ? ACTIVE_RADIUS : DEFAULT_RADIUS);
  }, [active]);

  return (
    <svg
      x={cx && cx - 8}
      y={cy && cy - 8}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      onMouseOver={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      cursor="pointer"
      onClick={onDotClick}
    >
      {legend && focusMode && (
        <rect
          x="2"
          y="6"
          width="12"
          height="4"
          fill={fill}
          opacity={lineOpacity}
        />
      )}
      <circle cx="8" cy="8" r={radius} fill={fill} />
    </svg>
  );
}
