interface IDatePickerConfig {
  weekStart: number;
  dateFormat: string;
}

export const NorthAmericaConfig: IDatePickerConfig = {
  weekStart: 0,
  dateFormat: "MM-DD-YYYY",
};

export const EuropeConfig: IDatePickerConfig = {
  weekStart: 1,
  dateFormat: "DD-MM-YYYY",
};

export const NorthAmericaCountryCodes: string[] = ["en-US", "en-CA"];
