import { CircleDot } from "../../../../../../../../../assets/icons/CircleDot";
import { DiamondDot } from "../../../../../../../../../assets/icons/DiamondDot";
import { RectangleDot } from "../../../../../../../../../assets/icons/RectangleDot";
import { SquareDot } from "../../../../../../../../../assets/icons/SquareDot";
import { StarDot } from "../../../../../../../../../assets/icons/StarDot";
import { TriangleDot } from "../../../../../../../../../assets/icons/TriangleDot";

export function CustomPromsScatterDot(props: any) {
  const { index, fill, surveyId, onDotClick } = props;

  const getShape = () => {
    switch (index % 6) {
      case 0:
        return (
          <CircleDot
            fill={fill}
            onClick={() => onDotClick ?? onDotClick(surveyId)}
            {...props}
          />
        );
      case 1:
        return (
          <RectangleDot
            fill={fill}
            onClick={() => onDotClick ?? onDotClick(surveyId)}
            {...props}
          />
        );
      case 2:
        return (
          <SquareDot
            fill={fill}
            onClick={() => onDotClick ?? onDotClick(surveyId)}
            {...props}
          />
        );
      case 3:
        return (
          <TriangleDot
            fill={fill}
            onClick={() => onDotClick ?? onDotClick(surveyId)}
            {...props}
          />
        );
      case 4:
        return (
          <StarDot
            fill={fill}
            onClick={() => onDotClick ?? onDotClick(surveyId)}
            {...props}
          />
        );
      case 5:
        return (
          <DiamondDot
            fill={fill}
            onClick={() => onDotClick ?? onDotClick(surveyId)}
            {...props}
          />
        );
      default:
        return (
          <CircleDot
            fill={fill}
            onClick={() => onDotClick ?? onDotClick(surveyId)}
            {...props}
          />
        );
    }
  };

  return <>{getShape()}</>;
}
