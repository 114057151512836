import { styled } from "../../../../styles";

export const SectionsHeader = styled("div", {
  width: "100%",
  height: "62px",
  marginTop: "62px",
  backgroundColor: "$gray-20",
  padding: "0 40px 0 68px",
  boxShadow: "0px 0px 4px 1px rgba(0, 0, 0, 0.16)",
  zIndex: 100,
});

export const SectionsContainer = styled("div", {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "42px",
});

export const SectionButton = styled("div", {
  color: "$gray-90",
  fontFamily: "$openSans",
  fontSize: "16px",
  fontWeight: "500",
  lineHeight: "22px",
  marginTop: "20px",

  cursor: "pointer",

  "&:hover": {
    div: {
      backgroundColor: "$primary-20",
    },
  },

  "&:focus": {
    outline: "none",

    div: {
      backgroundColor: "$primary-20",
    },
  },
});

export const Underline = styled("div", {
  width: "100%",
  height: "5px",

  borderRadius: "2px",
  marginTop: "15px",

  variants: {
    isActive: {
      true: {
        backgroundColor: "$primary-30 !important",
      },
    },
  },
});

export const BodyContainer = styled("div", {
  minWidth: "1020px",
  width: "60%",
  height: "fit-content",
  minHeight: "calc(100vh - 124px)",

  backgroundColor: "$white",
  border: "1px solid $gray-40",

  padding: "50px 48px 48px 48px",
});

export const ScrollableContainer = styled("div", {
  width: "100vw",
  height: "calc(100vh - 124px)",
  overflowY: "scroll",

  display: "flex",
  justifyContent: "center",
});

export const ButtonsContainer = styled("div", {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: "12px",

  position: "absolute",
  right: "40px",
});

export const CancelButton = styled("div", {
  width: "110px !important",
  height: "38px",

  padding: "0px 40px",
  borderRadius: "20px",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "22px",
  color: "$white",

  cursor: "pointer",

  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.15)",
  },
});
