import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import {
  IQuestionInfoForConditionalJump,
  ISurveyQuestion,
  ISurveySection,
} from "../../../../../@types/Surveys";
import { BlackPlus } from "../../../../../assets/icons/BlackPlus";
import { Close } from "../../../../../assets/icons/Close";
import { Pencil } from "../../../../../assets/icons/Pencil";
import { Button } from "../../../../../components/Button";
import { Checkbox } from "../../../../../components/Checkbox";
import { CustomIconButton } from "../../../../../components/CustomIconButton";
import {
  IOption,
  SelectDropdown,
} from "../../../../../components/SelectDropdown";
import { TextField } from "../../../../../components/TextField";
import {
  ScoreStrategiesWithFixedDomains,
  KbildDomains,
  LpfSymptomsDomains,
} from "../../../../../constants/surveys";
import { useModal } from "../../../../../hooks/useModal";
import { PageTitle } from "../../../../../layout/components/PageTitle";
import { LayoutStateValues } from "../../SurveyBuilder";
import { AddQuestionModal } from "./Components/AddQuestionModal";
import { DateQuestionDisplay } from "./Components/DateQuestionDisplay";
import { EditSurveyInfoModal } from "./Components/EditSurveyInfoModal";
import { FiveQuestionsAboutSupOxDisplay } from "./Components/FiveQuestionsAboutSupOxDisplay";
import { InformativeQuestionDisplay } from "./Components/InformativeQuestionDisplay";
import { MultiSelectQuestionDisplay } from "./Components/MultiSelectQuestionDisplay";
import { SingleSelectQuestionDisplay } from "./Components/SingleSelectQuestionDisplay";
import { SliderQuestionDisplay } from "./Components/SliderQuestionDisplay";
import { TextQuestionDisplay } from "./Components/TextQuestionDisplay";
import {
  FormContainer,
  FormInfoContainer,
  QuestionsContainer,
  Question,
  SeparationLine,
  FormTitle,
  SectionDropdownContainer,
  AddSectionContainer,
  SectionTitle,
  ScoreContainer,
  ScoringToogleContainer,
  DomainContainer,
  AddDomainContainer,
  DomainList,
  CloseContainer,
  ListItem,
} from "./ContentTab.styles";
import { vasConfig } from "./VasConfig";

interface ISurveyBuilderContentTabProps {
  display: boolean;
  sections: ISurveySection[];
  domains: string[];
  scoreStrategiesOptions: IOption[];
  scoreStrategy: string;
  hasScoring: boolean;
  surveyTitle: string;
  surveyDescription: string;
  updateSurveyStates: <K extends keyof LayoutStateValues>(
    state: K,
    value: LayoutStateValues[K]
  ) => void;
}

export function SurveyBuilderContentTab({
  sections,
  domains,
  display,
  hasScoring,
  scoreStrategy,
  surveyTitle,
  surveyDescription,
  scoreStrategiesOptions,
  updateSurveyStates,
}: ISurveyBuilderContentTabProps) {
  const [sectionSelected, setSectionSelected] = useState<string>("Section 1");
  const [editSectionTitle, setEditSectionTitle] = useState<boolean>(false);
  const [newSectionTitle, setNewSectionTitle] = useState<string>("");
  const [questionListforConditional, setQuestionListforConditional] = useState<
    IQuestionInfoForConditionalJump[]
  >([]);
  const [domainToAdd, setDomainToAdd] = useState<string>("");
  const [domainError, setDomainError] = useState<string>("");

  const { openModal, closeModal } = useModal();

  const updateSectionsList = (
    question: ISurveyQuestion,
    questionIndex: number,
    sectionIndex: number
  ) => {
    const updatedSections = [...sections];

    if (questionIndex !== -1) {
      updatedSections[sectionIndex].questions[questionIndex] = question;
    } else {
      updatedSections[sectionIndex].questions.push(question);
    }
    updateSurveyStates("Sections", updatedSections);
  };

  const updateQuestionIdList = () => {
    const updatedQuestionIdList: IQuestionInfoForConditionalJump[] = [];
    sections.forEach((section) => {
      section.questions.forEach((question) => {
        updatedQuestionIdList.push({
          id: question.id,
          question: question.text,
        });
      });
    });
    setQuestionListforConditional(updatedQuestionIdList);
  };

  useEffect(() => {
    updateQuestionIdList();
  }, [sections]);

  const sectionDropdownOptions = (): IOption[] => {
    const options: IOption[] = [];

    sections.forEach((section, index) => {
      options.push({
        label: `Section ${index + 1}`,
        value: `Section ${index + 1}`,
        key: uuidv4(),
      });
    });
    return options;
  };

  const handleRemoveQuestion = (
    questionIndex: number,
    sectionIndex: number
  ) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].questions.splice(questionIndex, 1);
    updateSurveyStates("Sections", updatedSections);
  };

  const handleEditQuestion = (
    question: ISurveyQuestion,
    questionIndex: number,
    sectionIndex: number
  ) => {
    openModal(
      <AddQuestionModal
        onClose={closeModal}
        updateSectionsList={updateSectionsList}
        question={question}
        questionIndex={questionIndex}
        sectionIndex={sectionIndex}
        questionListforConditional={questionListforConditional}
        scoreStrategy={scoreStrategy}
        domains={domains}
      />,
      {
        width: "70vw",
        maxHeight: "95vh",
        showCloseButton: true,
      }
    );
  };

  const handleAddQuestion = (sectionIndex: number) => {
    openModal(
      <AddQuestionModal
        onClose={closeModal}
        updateSectionsList={updateSectionsList}
        sectionIndex={sectionIndex}
        questionListforConditional={questionListforConditional}
        scoreStrategy={scoreStrategy}
        domains={domains}
      />,
      {
        width: "70vw",
        maxHeight: "95vh",
        showCloseButton: true,
      }
    );
  };

  const handleEditSurveyInfoClick = () => {
    openModal(
      <EditSurveyInfoModal
        onClose={closeModal}
        updateSurveyStates={updateSurveyStates}
        title={surveyTitle}
        description={surveyDescription}
      />,
      {
        width: "55vw",
        maxHeight: "95vh",
        showCloseButton: true,
      }
    );
  };

  const handleAddSection = () => {
    const updatedSections = [...sections];
    updatedSections.push({
      order: sections[sections.length - 1].order + 1,
      title: "Insert section title here...",
      questions: [],
    });
    updateSurveyStates("Sections", updatedSections);
    setSectionSelected(`Section ${updatedSections.length}`);
  };

  const handleNewSectiontitleSubmit = (sectionIndex: number) => {
    const updatedSections = [...sections];
    const newTitle = newSectionTitle;
    updatedSections[sectionIndex].title = newTitle;
    updateSurveyStates("Sections", updatedSections);
    setEditSectionTitle(false);
    setNewSectionTitle("");
  };

  const handleRemoveSection = (sectionIndex: number) => {
    const updatedSections = [...sections];
    updatedSections.splice(sectionIndex, 1);
    updateSurveyStates("Sections", updatedSections);
    setSectionSelected(`Section 1`);
  };

  const handleAddDomain = () => {
    if (domainToAdd === "") return;

    const domainAlreadyExists = domains.findIndex(
      (domain) => domain === domainToAdd
    );

    if (domainAlreadyExists > -1) {
      setDomainError("Domain already exists.");
      return;
    }

    setDomainError("");
    updateSurveyStates("Domains", [...domains, domainToAdd]);
    setDomainToAdd("");
  };

  const handleRemoveDomain = (domainIndex: number) => {
    const updatedDomains = [...domains];
    updatedDomains.splice(domainIndex, 1);
    updateSurveyStates("Domains", updatedDomains);
  };

  return (
    <FormContainer style={{ display: display ? "" : "none" }}>
      <FormInfoContainer style={{ marginBottom: "30px" }}>
        <FormTitle>{surveyTitle}</FormTitle>
        <p>{surveyDescription}</p>
        <CustomIconButton
          onClick={handleEditSurveyInfoClick}
          style={{
            position: "absolute",
            top: "3px",
            right: "3px",
          }}
        >
          <Pencil />
        </CustomIconButton>
      </FormInfoContainer>

      <SeparationLine />

      <ScoreContainer>
        <ScoringToogleContainer>
          <Checkbox
            label=""
            checked={hasScoring}
            onChange={(checked) => updateSurveyStates("HasScoring", checked)}
          />
          <p>Include Scoring</p>
        </ScoringToogleContainer>

        {hasScoring ? (
          <SelectDropdown
            placeholder="Select score strategy"
            options={scoreStrategiesOptions}
            width={300}
            value={scoreStrategy !== "" ? scoreStrategy : undefined}
            onValueChange={(value) => {
              if (value === "KBILD") {
                updateSurveyStates("Domains", KbildDomains);
              } else if (value === "LpfSymptomsScore") {
                updateSurveyStates("Domains", LpfSymptomsDomains);
              } else {
                updateSurveyStates("Domains", []);
              }
              updateSurveyStates("ScoreStrategy", value as string);
            }}
          />
        ) : null}
      </ScoreContainer>

      {hasScoring ? (
        <DomainContainer>
          {scoreStrategy.includes("Domain") || scoreStrategy === "TotalSum" ? (
            <AddDomainContainer>
              <TextField
                label=""
                placeholder="Please enter a domain"
                value={domainToAdd}
                onChange={(e) => {
                  setDomainToAdd(e.target.value);
                  setDomainError("");
                }}
                backgroudColor="white"
                errorMessage={domainError}
              />
              <Button
                type="button"
                size="small"
                label="Add domain"
                onClick={handleAddDomain}
              />
            </AddDomainContainer>
          ) : null}

          {domains.length > 0 ? (
            <DomainList>
              <p style={{ fontWeight: 600, fontSize: 15 }}>Domains</p>
              {domains?.map((domain, index) => {
                return (
                  <ListItem key={uuidv4()}>
                    &#8226; {domain}
                    <CloseContainer
                      disabled={ScoreStrategiesWithFixedDomains.includes(
                        scoreStrategy
                      )}
                      onClick={() => handleRemoveDomain(index)}
                    >
                      <Close />
                    </CloseContainer>
                  </ListItem>
                );
              })}
            </DomainList>
          ) : null}
        </DomainContainer>
      ) : null}

      <QuestionsContainer>
        <SectionDropdownContainer>
          <SelectDropdown
            options={sectionDropdownOptions()}
            width={200}
            value={sectionSelected}
            onValueChange={(value: string | string[]) => {
              setSectionSelected(value.toString());
            }}
          />

          <SeparationLine />
          <AddSectionContainer key={uuidv4()}>
            <CustomIconButton
              style={{
                border: "1px solid #DFDFDF",
              }}
              onClick={handleAddSection}
            >
              <BlackPlus />
            </CustomIconButton>
          </AddSectionContainer>
        </SectionDropdownContainer>

        {sections !== undefined &&
          sections.map(
            (section, sectionIndex) =>
              `Section ${sectionIndex + 1}` === sectionSelected && (
                <React.Fragment key={sectionIndex}>
                  <SectionTitle>
                    {editSectionTitle && (
                      <TextField
                        maxLength={50}
                        autofocus
                        label=""
                        name="title"
                        placeholder="title"
                        value={newSectionTitle}
                        onChange={(event) => {
                          setNewSectionTitle(event.target.value.toString());
                        }}
                        backgroudColor="white"
                        onKeyDown={(e) => {
                          if (e.key === "Enter")
                            handleNewSectiontitleSubmit(sectionIndex);
                        }}
                        onBlur={() => {
                          handleNewSectiontitleSubmit(sectionIndex);
                        }}
                      />
                    )}
                    {!editSectionTitle && (
                      <>
                        <PageTitle>{section.title}</PageTitle>
                        <CustomIconButton
                          onClick={() => {
                            setEditSectionTitle(!editSectionTitle);
                            setNewSectionTitle(section.title ?? "");
                          }}
                        >
                          <Pencil />
                        </CustomIconButton>
                        {sections.length > 1 && (
                          <CustomIconButton
                            onClick={() => {
                              handleRemoveSection(sectionIndex);
                            }}
                          >
                            <Close />
                          </CustomIconButton>
                        )}
                      </>
                    )}
                  </SectionTitle>
                  <SeparationLine />
                  {section.questions !== undefined &&
                    section.questions.map((question, questionIndex) => (
                      <React.Fragment key={questionIndex}>
                        <Question>
                          {(question.type === "Text" ||
                            question.type === "MultilineText") && (
                            <TextQuestionDisplay
                              title={question.text}
                              type={question.type}
                              description={question.description}
                            />
                          )}
                          {question.type === "SingleSelect" && (
                            <SingleSelectQuestionDisplay
                              title={question.text}
                              description={question.description}
                              options={question.options}
                            />
                          )}
                          {question.type === "MultiSelect" && (
                            <MultiSelectQuestionDisplay
                              title={question.text}
                              description={question.description}
                              options={question.options}
                            />
                          )}
                          {question.type === "Slider" &&
                            question.attributes && (
                              <SliderQuestionDisplay
                                verticalSliderHeight={
                                  question.attributes?.find(
                                    (attribute) =>
                                      attribute?.name === "Orientation"
                                  )?.value === "Vertical"
                                    ? 400
                                    : 0
                                }
                                title={question.text}
                                description={question.description}
                                options={question.options}
                                step={parseFloat(
                                  question.attributes.find(
                                    (attribute) => attribute?.name === "Step"
                                  )?.value
                                )}
                                orientation={
                                  question.attributes.find(
                                    (attribute) =>
                                      attribute?.name === "Orientation"
                                  )?.value
                                }
                              />
                            )}
                          {question.type === "SliderVas" &&
                            question.attributes && (
                              <SliderQuestionDisplay
                                verticalSliderHeight="300px"
                                title={question.text}
                                description={question.description}
                                options={vasConfig.options}
                                step={parseFloat(
                                  question.attributes.find(
                                    (attribute) => attribute?.name === "Step"
                                  )?.value
                                )}
                                orientation={
                                  question.attributes.find(
                                    (attribute) =>
                                      attribute?.name === "Orientation"
                                  )?.value
                                }
                              />
                            )}
                          {question.type === "Date" && (
                            <DateQuestionDisplay
                              title={question.text}
                              description={question.description}
                            />
                          )}
                          {question.type === "FiveQuestionsAboutSupOx" && (
                            <FiveQuestionsAboutSupOxDisplay />
                          )}
                          {question.type === "Informative" && (
                            <InformativeQuestionDisplay
                              HTMLText={question.text}
                            />
                          )}

                          <CustomIconButton
                            onClick={() => {
                              handleRemoveQuestion(questionIndex, sectionIndex);
                            }}
                            style={{
                              position: "absolute",
                              top: "3px",
                              right: "3px",
                            }}
                          >
                            <Close />
                          </CustomIconButton>

                          <CustomIconButton
                            onClick={() => {
                              handleEditQuestion(
                                question,
                                questionIndex,
                                sectionIndex
                              );
                            }}
                            style={{
                              position: "absolute",
                              top: "3px",
                              right: "40px",
                            }}
                          >
                            <Pencil />
                          </CustomIconButton>
                        </Question>
                        <SeparationLine />
                      </React.Fragment>
                    ))}
                  <div>
                    <CustomIconButton
                      style={{
                        width: "auto",
                        borderRadius: "20px",
                        padding: "5px 10px 5px 10px",
                      }}
                      onClick={() => {
                        handleAddQuestion(sectionIndex);
                      }}
                    >
                      <BlackPlus /> Add question
                    </CustomIconButton>
                  </div>
                </React.Fragment>
              )
          )}
      </QuestionsContainer>
    </FormContainer>
  );
}
