import dayjs from "dayjs";
import { useEffect, useState } from "react";

import { Notification } from "../../@types/Notification";
import { getUserAppointmentsData } from "../../hooks/queries/appointments";
import { useModal } from "../../hooks/useModal";
import { useToast } from "../../hooks/useToast";
import { PageLayout } from "../../layout/components/PageLayout";
import { PageTitle } from "../../layout/components/PageTitle";
import { mixpanelActions } from "../../utils/mixpanel";
import { DateFilterOptionType } from "../PatientPage/components/MeasurementModal/components/CustomOptionsBar";
import {
  AppointmentsContainer,
  CreateAppointmentContainer,
  Subtitle,
} from "./AppointmentsPage.styles";
import { AppointmentsTable } from "./components/AppointmentsTable";
import { CalendarCustomOptionsBar } from "./components/CalendarOptionsBar";
import { calendarDateFilterOptions } from "./components/CalendarOptionsBar/calendarDateFilterOptions";
import { CreateAppointmentModal } from "./components/CreateAppointmentModal";

export function AppointmentsPage() {
  document.title = "Appointments - patientMpower";
  const { openModal, closeModal } = useModal();
  const { showToast } = useToast();

  const currentDate = dayjs();
  const initialFilterDate = {
    endDate: currentDate.endOf("month"),
    label: calendarDateFilterOptions[5].label,
    startDate: calendarDateFilterOptions[5].value(currentDate),
  };

  const [selectedFilterDate, setSelectedFilterDate] =
    useState<DateFilterOptionType>(initialFilterDate);

  const { appointments, isFetching, isRefetching, refetch } =
    getUserAppointmentsData({
      startDate: selectedFilterDate.startDate.toISOString(),
      endDate: selectedFilterDate.endDate.toISOString(),
    });

  mixpanelActions.track("VisitedScreen: Appointments");

  useEffect(() => {
    refetch();
  }, [selectedFilterDate]);

  const handleOnClose = (
    notification?: Notification,
    refetchTable?: boolean
  ) => {
    if (notification !== undefined) {
      showToast(notification.message, notification.type);
    }

    if (refetchTable) {
      refetch();
    }

    closeModal();
  };

  const handleCreateAppointment = () => {
    openModal(<CreateAppointmentModal onClose={handleOnClose} />, {
      width: "100%",
      height: "100%",
      maxHeight: "99vh",
      showCloseButton: true,
    });
  };

  return (
    <PageLayout>
      <AppointmentsContainer>
        <PageTitle>Appointments</PageTitle>
        <Subtitle>{`${selectedFilterDate.startDate.format(
          "MMM D, YYYY"
        )} - ${selectedFilterDate.endDate.format("MMM D, YYYY")}`}</Subtitle>

        <CalendarCustomOptionsBar
          onChange={(selectedDateFilter) =>
            setSelectedFilterDate(selectedDateFilter)
          }
          selectedFilterDate={selectedFilterDate}
        />

        <CreateAppointmentContainer
          onClick={() => {
            handleCreateAppointment();
            mixpanelActions.track("User Action: CreateAppointmentButton");
          }}
        >
          Create appointment
        </CreateAppointmentContainer>
        <AppointmentsTable
          onAppointmentDelete={handleOnClose}
          appointments={appointments}
          isLoading={isFetching || isRefetching}
        />
      </AppointmentsContainer>
    </PageLayout>
  );
}
