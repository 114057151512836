import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import {
  IQuestionInfoForConditionalJump,
  ISurveyQuestion,
  ISurveyQuestionAttributes,
  ISurveyQuestionOption,
  QuestionTypes,
} from "../../../../../../../@types/Surveys";
import { Button } from "../../../../../../../components/Button";
import {
  IOption,
  SelectDropdown,
} from "../../../../../../../components/SelectDropdown";
import { Toggle } from "../../../../../../../components/Toggle";
import { ScoreStrategiesWithDomains } from "../../../../../../../constants/surveys";
import {
  ModalContainer,
  CancelButton,
} from "../../../../../../Settings/components/SecurityModal/SecurityModal.styles";
import { vasConfig } from "../../VasConfig";
import {
  QuestionTypeContainer,
  HandleQuestionFormContainer,
  ModalContentContainer,
  ConditionalJumpToggleContainer,
  ToggleStyleClass,
  DomainContainer,
} from "./AddQuestionModal.styles";
import { InformativeQuestion } from "./Components/InformativeQuestion";
import { OptionSelectQuestion } from "./Components/OptionSelectQuestion";
import { QuestionInfo } from "./Components/QuestionInfo";
import { SliderQuestion } from "./Components/SliderQuestion";

interface IAddQuestionModalProps {
  onClose(): void;
  updateSectionsList(
    question: ISurveyQuestion,
    index: number,
    sectionIndex: number
  ): void;
  question?: ISurveyQuestion;
  questionIndex?: number;
  sectionIndex?: number;
  questionListforConditional: IQuestionInfoForConditionalJump[];
  scoreStrategy: string;
  domains?: string[];
}

export function AddQuestionModal({
  onClose,
  updateSectionsList,
  question,
  questionIndex,
  sectionIndex,
  questionListforConditional,
  scoreStrategy,
  domains,
}: IAddQuestionModalProps) {
  const [typeSelected, setTypeSelected] = useState<QuestionTypes>();
  const [prevTypeSelected, setPrevTypeSelected] = useState<QuestionTypes>();

  const [questionOptions, setQuestionOptions] = useState<
    ISurveyQuestionOption[] | []
  >([]);
  const [questionTitle, setQuestionTitle] = useState<string>("");
  const [questionAttributes, setQuestionAttributes] = useState<
    ISurveyQuestionAttributes[]
  >([]);
  const [questionDescription, setQuestionDescription] = useState<string>("");
  const [questionId, setQuestionId] = useState<string>("");

  const [questionToEdit, setQuestionToEdit] = useState<ISurveyQuestion>();
  const [hasConditionalJump, setHasConditionalJump] = useState<boolean>(false);
  const [hasNextQuestionId, setHasNextQuestionId] = useState<boolean>(false);
  const [questionListToJump, setQuestionListToJump] = useState<IOption[]>([]);

  const [questionInfoIsValidated, setQuestionInfoIsValidated] =
    useState<boolean>(false);
  const [
    sliderQuestionDetailsAreValidated,
    setSliderQuestionDetailsAreValidated,
  ] = useState<boolean>(false);
  const [
    optionsQuestionDetailsAreValidated,
    setOptionsQuestionDetailsAreValidated,
  ] = useState<boolean>(false);
  const [triggerValidation, setTriggerValidation] = useState<boolean>();

  const questionTypeOptions: IOption[] = [
    { label: "Text", value: "Text", key: uuidv4() },
    { label: "Multiple line text", value: "MultilineText", key: uuidv4() },
    { label: "Single select", value: "SingleSelect", key: uuidv4() },
    { label: "Multiple select", value: "MultiSelect", key: uuidv4() },
    { label: "Slider", value: "Slider", key: uuidv4() },
    { label: "SliderVas", value: "SliderVas", key: uuidv4() },
    { label: "Date", value: "Date", key: uuidv4() },
    {
      label: "FiveQuestionsAboutSupOx",
      value: "FiveQuestionsAboutSupOx",
      key: uuidv4(),
    },
    { label: "Informative", value: "Informative", key: uuidv4() },
  ];

  const verifyIfQuestionIsValidated = () => {
    if (triggerValidation === undefined) {
      setTriggerValidation(true);
    } else {
      setTriggerValidation(!triggerValidation);
    }

    if (typeSelected === "SingleSelect" || typeSelected === "MultiSelect") {
      if (optionsQuestionDetailsAreValidated && questionInfoIsValidated) {
        return true;
      }
      return false;
    }

    if (typeSelected === "Slider") {
      if (sliderQuestionDetailsAreValidated && questionInfoIsValidated) {
        return true;
      }
      return false;
    }
    if (
      typeSelected === "FiveQuestionsAboutSupOx" ||
      typeSelected === "Informative"
    ) {
      return true;
    }
    if (questionInfoIsValidated === true) {
      return true;
    }
    return false;
  };
  const handleOnSubmitForm = (values: ISurveyQuestion) => {
    const updatedValues: ISurveyQuestion = values;
    if (verifyIfQuestionIsValidated()) {
      if (updatedValues.type === "SliderVas") {
        updatedValues.options = vasConfig.options;
        updatedValues.attributes = vasConfig.attributes;
      }

      values.options?.forEach((option: any, optionIndex) => {
        if (updatedValues.options) {
          updatedValues.options[optionIndex].value = parseFloat(option.value);
          updatedValues.options[optionIndex].label = option.label.trim();
          updatedValues.options[optionIndex].id = uuidv4();
        }
      });

      values.attributes?.forEach((attribute, attributesIndex) => {
        if (updatedValues.attributes) {
          updatedValues.attributes[attributesIndex].value =
            attribute.value.trim();
          updatedValues.attributes[attributesIndex].name =
            attribute.name.trim();
        }
      });
      updatedValues.id = questionId;
      updatedValues.domain =
        updatedValues.domain === "" ? undefined : updatedValues.domain;

      updateSectionsList(
        updatedValues,
        questionIndex ?? -1,
        sectionIndex ?? -1
      );
      onClose();
    }
  };

  const initialValues: ISurveyQuestion = {
    id: "",
    order: 0,
    text: "",
    description: "",
    type: "Text",
    options: [],
    attributes: [],
    nextQuestionId: "",
    domain: "",
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleOnSubmitForm,
  });

  const getListOfOptionsForJump = () => {
    const updatedQuestionListToConditionalJump: IOption[] = [];
    let skip = true;
    questionListforConditional.forEach((questionFromList) => {
      if (!skip) {
        updatedQuestionListToConditionalJump.push({
          label: questionFromList.question,
          value: questionFromList.id,
          key: uuidv4(),
        });
      }
      if (questionToEdit?.id === questionFromList.id) {
        skip = false;
      }
    });
    setQuestionListToJump(updatedQuestionListToConditionalJump);
  };

  useEffect(() => {
    if (question) {
      setTypeSelected(question.type);
      formik.setFieldValue("type", question.type);
      setQuestionToEdit(question);
      setQuestionId(question.id ?? "");
      question.options?.forEach((option) => {
        if (option.nextQuestionId !== "") setHasConditionalJump(true);
      });
      if (question.nextQuestionId) {
        setHasNextQuestionId(true);
        formik.setFieldValue(`nextQuestionId`, question.nextQuestionId);
      }
      formik.setFieldValue("domain", question.domain);
      formik.setFieldValue("code", question.code);
    } else {
      setQuestionId(uuidv4());
    }
  }, []);

  useEffect(() => {
    getListOfOptionsForJump();
  }, [questionToEdit]);

  const handleSelectedTypeChange = () => {
    if (
      !(
        (prevTypeSelected === "SingleSelect" &&
          typeSelected === "MultiSelect") ||
        (prevTypeSelected === "MultiSelect" && typeSelected === "SingleSelect")
      )
    ) {
      setQuestionOptions([]);
      setQuestionAttributes([]);
    }
    if (
      typeSelected === "FiveQuestionsAboutSupOx" ||
      typeSelected === "Informative"
    ) {
      setQuestionTitle("no_text");
      setQuestionDescription("");
    }
    if (prevTypeSelected !== undefined) {
      setHasNextQuestionId(false);
      formik.setFieldValue(`nextQuestionId`, "");
    }
  };

  const updateHasConditionalJump = (conditionalJumpFlag: boolean) => {
    if (conditionalJumpFlag) setHasConditionalJump(true);
    else {
      setHasConditionalJump(false);
      const updateQuestionToEdit = questionToEdit;

      questionToEdit?.options?.forEach((option, index) => {
        if (updateQuestionToEdit?.options)
          updateQuestionToEdit.options[index].nextQuestionId = "";
      });
      setQuestionToEdit(updateQuestionToEdit);
    }
  };

  useEffect(() => {
    handleSelectedTypeChange();
  }, [typeSelected]);

  useEffect(() => {
    formik.setFieldValue("text", questionTitle);
    formik.setFieldValue("description", questionDescription);
    formik.setFieldValue("options", questionOptions);
    formik.setFieldValue("attributes", questionAttributes);
  }, [questionOptions, questionTitle, questionDescription, questionAttributes]);

  const renderQuestionComponent = (typeOfQuestion: QuestionTypes) => {
    switch (typeOfQuestion) {
      case "SingleSelect":
      case "MultiSelect":
        return (
          <OptionSelectQuestion
            setOptionsQuestionDetailsAreValidated={
              setOptionsQuestionDetailsAreValidated
            }
            triggerValidation={triggerValidation}
            typeSelected={typeSelected as QuestionTypes}
            questionToEdit={questionToEdit}
            onOptionsChange={setQuestionOptions}
            hasConditionalJump={hasConditionalJump}
            questionListforConditional={questionListforConditional}
          />
        );
      case "Slider":
        return (
          <SliderQuestion
            question={questionToEdit}
            onOptionsChange={setQuestionOptions}
            onAttributesChange={setQuestionAttributes}
            triggerValidation={triggerValidation}
            setSliderQuestionDetailsAreValidated={
              setSliderQuestionDetailsAreValidated
            }
            hasScoringIntervals={scoreStrategy !== ""}
          />
        );
      case "Informative":
        return (
          <InformativeQuestion
            question={questionToEdit}
            onValueChange={setQuestionTitle}
          />
        );
      default:
        return null;
    }
  };

  return (
    <ModalContainer>
      <ModalContentContainer>
        <QuestionTypeContainer>
          <p>Type of question</p>
          <SelectDropdown
            options={questionTypeOptions}
            width={200}
            height={42}
            placeholder="Choose a type"
            value={typeSelected}
            onValueChange={(value: string | string[]) => {
              setQuestionToEdit(undefined);
              setPrevTypeSelected(typeSelected);
              setTypeSelected(value as QuestionTypes);
              formik.setFieldValue("type", value.toString());
            }}
          />
          {typeSelected === "SingleSelect" && (
            <ConditionalJumpToggleContainer>
              <p style={{ marginRight: "10px" }}>Conditional jump</p>
              <Toggle
                className={ToggleStyleClass()}
                defaultChecked={hasConditionalJump}
                onCheckedChange={(isChecked) => {
                  if (isChecked) {
                    updateHasConditionalJump(true);
                  } else {
                    updateHasConditionalJump(false);
                  }
                }}
              />
            </ConditionalJumpToggleContainer>
          )}
          {typeSelected !== "SingleSelect" && typeSelected && (
            <ConditionalJumpToggleContainer>
              <p style={{ marginRight: "10px" }}>Choose next question</p>
              <Toggle
                key={uuidv4()}
                className={ToggleStyleClass()}
                defaultChecked={hasNextQuestionId}
                onCheckedChange={(isChecked) => {
                  if (isChecked) {
                    setHasNextQuestionId(true);
                  } else {
                    setHasNextQuestionId(false);
                    formik.setFieldValue(`nextQuestionId`, "");
                  }
                }}
              />
            </ConditionalJumpToggleContainer>
          )}
          {hasNextQuestionId && typeSelected !== "SingleSelect" && (
            <SelectDropdown
              options={questionListToJump}
              width={210}
              height={42}
              placeholder="Choose a question"
              value={formik.values.nextQuestionId}
              onValueChange={(value: string | string[]) => {
                formik.setFieldValue(`nextQuestionId`, value.toString());
              }}
            />
          )}
        </QuestionTypeContainer>

        {typeSelected !== undefined &&
        ScoreStrategiesWithDomains.includes(scoreStrategy) &&
        domains?.length ? (
          <DomainContainer>
            <p style={{ marginRight: "10px" }}>Domain</p>
            <SelectDropdown
              placeholder="Select a domain"
              width={200}
              value={formik.values.domain}
              options={domains.map((domain) => {
                return { value: domain, label: domain, key: uuidv4() };
              })}
              onValueChange={(value) => {
                formik.setFieldValue("domain", value as string);
              }}
            />
          </DomainContainer>
        ) : null}

        {typeSelected !== undefined &&
          typeSelected !== "FiveQuestionsAboutSupOx" &&
          typeSelected !== "Informative" && (
            <QuestionInfo
              triggerValidation={triggerValidation}
              setQuestionInfoIsValidated={setQuestionInfoIsValidated}
              question={question}
              onDescriptionChange={setQuestionDescription}
              onTitleChange={setQuestionTitle}
            />
          )}

        {typeSelected && renderQuestionComponent(typeSelected)}
        {typeSelected !== undefined && (
          <HandleQuestionFormContainer>
            <Button label="Save" type="submit" onClick={formik.handleSubmit} />
            <CancelButton
              style={{
                border: "2px solid rgba(248, 248, 248, 0.8)",
                backgroundColor: "rgba(248, 248, 248, 0.8)",
              }}
              onClick={() => onClose()}
            >
              Cancel
            </CancelButton>
          </HandleQuestionFormContainer>
        )}
      </ModalContentContainer>
    </ModalContainer>
  );
}
