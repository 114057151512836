import { IPromsRange } from "../../../../../../../../../@types/Proms";
import { CircleDot } from "../../../../../../../../../assets/icons/CircleDot";
import { Container, StyledLegend } from "./CustomPromsLegend.styles";

type CustomPromsLegendProps = {
  ranges: IPromsRange[];
};

export function CustomPromsLegend({ ranges }: CustomPromsLegendProps) {
  const legend: any[] = [];

  ranges
    .sort((a, b) => a.start - b.start)
    .map((range: IPromsRange, index: number) => {
      legend.push(
        <StyledLegend key={`${range.name}_${index}`}>
          <CircleDot fill={range.color} lineOpacity={0.38} legend focusMode />
          {range.name}
        </StyledLegend>
      );

      return null;
    });

  return <Container>{legend}</Container>;
}
