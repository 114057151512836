import dayjs from "dayjs";
import { useState } from "react";

import { MeasurementTypes } from "../../../../@types/Measurements";
import { Notification } from "../../../../@types/Notification";
import { ISectionProps } from "../../../../@types/Patient";
import { ChevronDown } from "../../../../assets/icons/ChevronDown";
import { ChevronUp } from "../../../../assets/icons/ChevronUp";
import { CustomIconButton } from "../../../../components/CustomIconButton";
import { useMeasurementConfigs } from "../../../../hooks/queries/measurement-configs";
import useIsMobile from "../../../../hooks/useIsMobile";
import { useModal } from "../../../../hooks/useModal";
import { useToast } from "../../../../hooks/useToast";
import { useUserPreferences } from "../../../../hooks/useUserPreferences";
import { PageTitle } from "../../../../layout/components/PageTitle";
import { mixpanelActions } from "../../../../utils/mixpanel";
import {
  ChartsContainer,
  MobilePatientPageTitleContainer,
} from "../../PatientPage.styles";
import { AddMeasurementModal } from "../AddMeasurementModal";
import { MeasurementCard } from "../MeasurementCard";
import { AddMeasurementButton } from "../SpirometrySection/SpirometrySection.styles";
import { NewDataCOntainer, TitleContainer } from "./MeasurementSection.styles";

export function MeasurementSection({
  patient,
  updateColapsedSections,
  collapsedSection,
}: ISectionProps) {
  const { portalPreferences } = useUserPreferences();
  const { isMobile } = useIsMobile();
  const { openModal, closeModal } = useModal();
  const { showToast } = useToast();

  const { configs } = useMeasurementConfigs({
    patientId: patient?.id,
    section: "measurements",
  });

  const [showNewData, setShowNewData] = useState<boolean>(false);

  const handleOnModalClose = (notification?: Notification) => {
    if (notification !== undefined) {
      showToast(notification.message, notification.type);

      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
    closeModal();
  };

  const { preferences, changeUserPreferences } = useUserPreferences();

  const handleAddMeasurement = () => {
    mixpanelActions.track(`User action: Add Measurement Button`);
    openModal(
      <AddMeasurementModal
        section="measurement"
        patientId={patient?.id.toString()}
        onClose={handleOnModalClose}
      />,
      {
        width: isMobile ? "98vw" : "620px",
        height: isMobile ? undefined : "auto",
        borderRadius: isMobile ? "8px" : undefined,
        showCloseButton: true,
      }
    );
  };
  if (!preferences) {
    changeUserPreferences({
      hideFvcRejected: false,
      hideFev1Rejected: false,
      hideFvcUsable: false,
      hideFev1Usable: false,
    });
  }

  const checkNewData = (mostRecentDate: dayjs.Dayjs) => {
    if (showNewData === false) {
      const collapsedDate = dayjs(collapsedSection.collapsedDate);
      if (mostRecentDate > collapsedDate) {
        setShowNewData(true);
      }
    }
  };

  const handleCollapseSection = () => {
    updateColapsedSections("measurementSection");
    if (collapsedSection.value === true) {
      setShowNewData(false);
    }
  };
  return (
    <>
      {!isMobile && (
        <TitleContainer>
          <PageTitle>Measurements</PageTitle>
          {showNewData && <NewDataCOntainer>(new data)</NewDataCOntainer>}
          <CustomIconButton
            style={{ marginLeft: "2px" }}
            onClick={handleCollapseSection}
          >
            {collapsedSection.value ? <ChevronUp /> : <ChevronDown />}
          </CustomIconButton>
        </TitleContainer>
      )}

      <ChartsContainer
        style={{
          height: collapsedSection.value ? "0" : "",
          overflow: collapsedSection.value ? "hidden" : "",
        }}
      >
        <AddMeasurementButton onClick={handleAddMeasurement}>
          Add measurement
        </AddMeasurementButton>
        {isMobile && (
          <MobilePatientPageTitleContainer>
            <PageTitle>Measurements</PageTitle>
          </MobilePatientPageTitleContainer>
        )}

        {configs
          ?.filter(
            (measurement) =>
              measurement.type !== portalPreferences.pinnedMeasurementType
          )
          .map((measurement, index) => {
            return (
              <MeasurementCard
                key={`${measurement.title}_${measurement.type}_${index}`}
                cardTitle={`${
                  measurement.type === "bp_diastolic" ||
                  measurement.type === "bp_systolic"
                    ? "Blood Pressure"
                    : measurement.title
                } ${measurement.unit ? `(${measurement.unit})` : ""}`}
                unit={measurement.unit}
                measurementType={
                  measurement.type === "breathlessness"
                    ? "dyspnea"
                    : (measurement.type as MeasurementTypes)
                }
                minValue={measurement.min}
                maxValue={measurement.max}
                checkNewData={checkNewData}
              />
            );
          })}
      </ChartsContainer>
    </>
  );
}
