import { styled, css } from "../../../../styles";

// Components

export const ReactFlowContainer = styled("div", {
  width: "calc(100vw - 460px)",
  height: "calc(100vh - 124px)",

  display: "flex",

  backgroundColor: "$white",
  borderRight: "1px solid #CFCFCF",
  borderLeft: "1px solid #CFCFCF",

  position: "relative !important",

  ".node-wrapper": {
    pointerEvents: "all !important",
  },

  ".node-wrapper .interactive-element": {
    pointerEvents: "all !important",
  },
});

export const SettingsContainer = styled("div", {
  width: "60%",
  height: "100%",

  backgroundColor: "$white",
  borderRight: "2px solid $gray-40",
  borderLeft: "2px solid $gray-40",

  padding: 48,
});

export const SettingsChildContainer = styled("div", {
  width: "100%",
  height: 346,
  padding: "46px 68px",

  borderRadius: 8,
  backgroundColor: "rgba(248, 248, 248, 0.8)",

  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",

  gap: 26,
});

export const SettingsLabel = styled("p", {
  width: 140,
  fontFamily: "$openSans",
  fontWeight: 600,
  fontSize: 14,
  lineHeight: 14,

  color: "$charcoal",
});

export const Row = styled("div", {
  height: 42,
  width: "100%",

  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",

  position: "relative",

  variants: {
    isError: {
      true: {
        ".ant-select-selector": {
          borderColor: "$error-lighter !important",

          "&:hover": {
            borderColor: "$error-lighter !important",
          },
        },

        input: {
          borderColor: "$error-lighter!important",
        },
      },
    },

    isSaveSettings: {
      true: {
        marginTop: 24,
      },
    },
  },
});

export const ErrorMessage = styled("p", {
  zIndex: 3002,

  margin: "-6px 0 0 6px",

  fontWeight: "600 !important",
  lineHeight: "16px !important",
  fontSize: "$text-md !important",

  color: "$error-light !important",

  variants: {
    isSettingsPageError: {
      true: {
        position: "absolute",
        left: 140,
        bottom: -20,
      },
    },
  },
});

// CSS

export const selectCss = css({
  height: "42px !important",
  wordWrap: "break-word",
  inlineSize: 250,

  ".ant-select-selector": {
    height: "42px !important",
    alignItems: "center",
    border: "solid 1px $gray-50 !important",
    boxShadow: "0 0 0 2px $gray-50 !important",
    fontFamily: "$openSans !important",

    "&:focus-within": {
      borderColor: "$primary-30 !important",
    },

    "&:hover": {
      borderColor: "$primary-30 !important",
    },

    ".ant-select-selection-overflow-item": {
      span: {
        "&.ant-select-selection-item": {
          borderRadius: "14px !important",
        },
      },
    },
  },
});

export const textAreaCss = css({
  height: "max-content !important",
});

export const wardsSelectCss = css({
  height: "42px !important",
  width: "50% !important",
  maxWidth: "300px !important",

  ".ant-select-selector": {
    height: "42px !important",
    alignItems: "center",
    border: "solid 1px $gray-50 !important",
    boxShadow: "0 0 0 2px $gray-50 !important",
    fontFamily: "$openSans !important",

    "&:focus-within": {
      borderColor: "$primary-30 !important",
    },

    "&:hover": {
      borderColor: "$primary-30 !important",
    },

    ".ant-select-selection-overflow-item": {
      span: {
        "&.ant-select-selection-item": {
          borderRadius: "14px !important",
        },
      },
    },
  },
});

export const TextFieldCss = css({
  width: "75% !important",
});
