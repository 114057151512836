import { styled } from "../../../styles";

export const ScrollableContainer = styled("div", {
  width: "100%",
  height: "calc(100vh - 62px)",
  overflowY: "scroll",

  display: "flex",
  justifyContent: "center",

  position: "relative",
});

export const BodyContainer = styled("div", {
  minWidth: "1020px",
  width: "60%",
  height: "max-content",
  minHeight: "calc(100vh - 62px)",

  backgroundColor: "$white",
  border: "1px solid $gray-40",

  padding: "50px 48px 48px 48px",
});
