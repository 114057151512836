import { CircleLegend } from "../../../../../../../../../assets/icons/CircleLegend";
import { DiamondLegend } from "../../../../../../../../../assets/icons/DiamondLegend";
import { RectangleLegend } from "../../../../../../../../../assets/icons/RectangleLegend";
import { SquareLegend } from "../../../../../../../../../assets/icons/SquareLegend";
import { StarLegend } from "../../../../../../../../../assets/icons/StarLegend";
import { TriangleLegend } from "../../../../../../../../../assets/icons/TriangleLegend";
import {
  Container,
  LegendIconWrapper,
  StyledLegend,
} from "./CustomPromsScatterLegend.styles";

type CustomPromsScatterLegend = {
  valueColorMap: Record<string, string>;
  valueIndexMap: Record<string, number>;
};

export function CustomPromsScatterLegend({
  valueColorMap,
  valueIndexMap,
}: CustomPromsScatterLegend) {
  const legend: any[] = [];

  const getShape = (index: number, fill: string) => {
    switch (index % 6) {
      case 0:
        return <CircleLegend fill={fill} lineOpacity={0} />;
      case 1:
        return <RectangleLegend fill={fill} />;
      case 2:
        return <SquareLegend fill={fill} />;
      case 3:
        return <TriangleLegend fill={fill} />;
      case 4:
        return <StarLegend fill={fill} />;
      case 5:
        return <DiamondLegend fill={fill} />;
      default:
        return <CircleLegend fill={fill} lineOpacity={0} />;
    }
  };

  Object.entries(valueColorMap).forEach(([label, color]) => {
    legend.push(
      <StyledLegend key={label}>
        <LegendIconWrapper>
          {getShape(valueIndexMap[label], color)}
        </LegendIconWrapper>
        <span>{label}</span>
      </StyledLegend>
    );
  });

  return <Container>{legend}</Container>;
}
