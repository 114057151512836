import dayjs from "dayjs";
import AdvancedFormat from "dayjs/plugin/advancedFormat";
import {
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  XAxis,
  YAxis,
} from "recharts";
import { v4 as uuidv4 } from "uuid";

import { getGraphSeparators } from "../../../../../../../utils/chartUtils";
import { DateFilterOptionType } from "../../../../MeasurementModal/components/CustomOptionsBar";
import { CustomPromsScatterDot } from "./components/CustomPromsScatterDot/CustomPromsScatterDot";
import { CustomPromsScatterGraphLegend } from "./components/CustomPromsScatterGraphLegend/CustomPromsGraphLegend";

type PromsScatterGraphsProps = {
  data: any[];
  dateInterval: DateFilterOptionType;
  mainValues: any;
  yAxisLabels: string[];
  legendsColorMap: Record<string, string>;
  onDotClick: (surveyId: number) => void;
};

dayjs.extend(AdvancedFormat);

export function PromsScatterGraph({
  data,
  dateInterval,
  mainValues,
  yAxisLabels,
  legendsColorMap,
  onDotClick,
}: PromsScatterGraphsProps) {
  const { graphSeparators, dates, separatorCounter, tickFormat, viewType } =
    getGraphSeparators(dateInterval, mainValues.minDate, mainValues.maxDate);

  let referenceTicks: number[] = [];

  const refs: number[] = [
    ...graphSeparators,
    ...dates.filter((_, index) => index % separatorCounter === 0),
  ];

  referenceTicks = [...refs];

  const usedCategories = new Set(data.map((d) => d.y));

  const fillerPoints = yAxisLabels
    .filter((categorie) => !usedCategories.has(categorie))
    .map((categorie) => ({
      x: new Date().getTime(),
      y: categorie,
      value: "filler",
      isFiller: true,
    }));

  const transformedData = data.map((d) => ({
    name: d.value,
    color: legendsColorMap[d.value as keyof typeof legendsColorMap] ?? "#999",
    data: [d],
    surveyId: d.surveyId,
  }));

  const valueIndexMap = Object.keys(legendsColorMap).reduce(
    (acc, key, index) => {
      acc[key as keyof typeof legendsColorMap] = index;
      return acc;
    },
    {} as Record<keyof typeof legendsColorMap, number>
  );

  const tickXFormatter = (value: string) => {
    if (!data || !data.length) return "";

    if (
      graphSeparators.includes(Number(value)) ||
      referenceTicks.includes(Number(value))
    ) {
      if (tickFormat === "Q") {
        return (
          value &&
          `Q${dayjs(value).format(tickFormat)}, ${dayjs(value).format("YY")}`
        );
      }

      return value && dayjs(value).format(tickFormat);
    }

    return "";
  };

  const handleXDomain = () => {
    if (!dateInterval || !data) return [0, 1];

    const { minDate, maxDate } = mainValues;
    let { startDate, endDate } = dateInterval;

    if (minDate && maxDate && endDate.diff(startDate, "year") > 5) {
      startDate = minDate;
      endDate = maxDate;
    }

    if (endDate.diff(startDate, "months") > 11) {
      endDate = endDate.add(1, "month");
    }

    return [startDate.valueOf(), endDate.valueOf()];
  };

  return (
    <ResponsiveContainer width="90%" height="80%" style={{ marginTop: "6px" }}>
      <ScatterChart margin={{ right: 20, top: 8, left: 50 }}>
        <CartesianGrid strokeDasharray="3 3" vertical={false} syncWithTicks />

        <YAxis
          dataKey="y"
          type="category"
          stroke="#D8D8D8"
          tick={{
            fill: "#727272",
            fontSize: "12px",
            fontWeight: "400",
          }}
          interval={0}
          reversed
          width={110}
          allowDuplicatedCategory={false}
        />

        <XAxis
          tickMargin={14}
          dataKey="x"
          stroke="#D8D8D8"
          type="number"
          domain={handleXDomain()}
          tickFormatter={tickXFormatter}
          ticks={viewType === "yearly" ? graphSeparators : dates}
          tickLine={false}
          height={48}
          minTickGap={100}
          interval={0}
          tick={{
            fill: "#727272",
            fontSize: "12px",
            fontWeight: "400",
          }}
          angle={refs.length >= 15 ? 30 : 0}
        />

        <Legend
          content={
            <CustomPromsScatterGraphLegend
              mainValues={mainValues}
              empty={transformedData.length === 0}
              valueColorMap={legendsColorMap}
              valueIndexMap={valueIndexMap}
              showValues
            />
          }
        />

        {refs.map((ref) => (
          <ReferenceLine
            key={ref.toString()}
            x={ref}
            stroke="#D8D8D8"
            strokeDasharray="8 1000"
          />
        ))}

        {transformedData.map((dot) => (
          <Scatter
            key={uuidv4()}
            data={dot.data}
            shape={(props: any) => (
              <CustomPromsScatterDot
                {...props}
                fill={dot.color}
                index={valueIndexMap[dot.name as keyof typeof legendsColorMap]}
                surveyId={dot.surveyId}
                onDotClick={() => {
                  onDotClick?.(dot.surveyId);
                }}
              />
            )}
          />
        ))}

        {fillerPoints.length > 0 ? (
          <Scatter data={fillerPoints} fill="transparent" />
        ) : null}
      </ScatterChart>
    </ResponsiveContainer>
  );
}
