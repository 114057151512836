import dayjs from "dayjs";
import { useEffect, useState } from "react";

import { CustomPromsScatterLegend } from "../CustomPromsScatterLegend/CustomPromsScatterLegend";
import {
  ChartLegendContainer,
  Container,
  DateLabel,
  Divider,
  MainValueContainer,
  MainValueContainerFirst,
  MainValuesContainer,
  NumberLabel,
  TextLabel,
} from "./CustomPromsScatterGraphLegend.style";

type CustomPromsScatterGraphLegend = {
  mainValues: any;
  empty: boolean;
  showValues: boolean;
  valueColorMap: Record<string, string>;
  valueIndexMap: Record<string, number>;
};

export function CustomPromsScatterGraphLegend({
  mainValues,
  empty,
  showValues,
  valueColorMap,
  valueIndexMap,
}: CustomPromsScatterGraphLegend) {
  const { min, max, average, minValueDate, maxValueDate } = mainValues;

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setDisabled(!min && !max && !average);
  }, [mainValues]);

  const chartLegendContainer = (
    <ChartLegendContainer paddingLeft={!showValues}>
      <CustomPromsScatterLegend
        valueColorMap={valueColorMap}
        valueIndexMap={valueIndexMap}
      />
    </ChartLegendContainer>
  );

  const mainValuesContainer = (
    <MainValuesContainer>
      <MainValueContainerFirst>
        <NumberLabel disabled={disabled}>{average ?? "-"}</NumberLabel>
        <TextLabel disabled={disabled}>Average</TextLabel>
      </MainValueContainerFirst>
      <MainValueContainerFirst>
        <NumberLabel disabled={disabled}>{max ?? "-"}</NumberLabel>
        <TextLabel disabled={disabled}>Highest</TextLabel>
        <DateLabel disabled={disabled}>
          {dayjs(maxValueDate).format("MMM DD, YYYY") || "-"}
        </DateLabel>
      </MainValueContainerFirst>
      <MainValueContainer>
        <NumberLabel disabled={disabled}>{min ?? "-"}</NumberLabel>
        <TextLabel disabled={disabled}>Lowest</TextLabel>
        <DateLabel disabled={disabled}>
          {dayjs(minValueDate).format("MMM DD, YYYY") || "-"}
        </DateLabel>
      </MainValueContainer>
    </MainValuesContainer>
  );

  return (
    <div style={{ position: "relative" }}>
      <Container>
        {showValues && mainValuesContainer}
        {!empty && <Divider />}
        {chartLegendContainer}
      </Container>
    </div>
  );
}
