import { useEffect, useState } from "react";

import { Button } from "../../../../../../components/Button";
import {
  getNoteData,
  textTruncate,
} from "../../../../../../hooks/queries/notes";
import { useModal } from "../../../../../../hooks/useModal";
import { noteService } from "../../../../../../services/noteService";
import { getLongFormattedDate } from "../../../../../../utils/dateFormatter";
import { mixpanelActions } from "../../../../../../utils/mixpanel";
import {
  CancelButton,
  Container,
  SubmitContainer,
  NoteContainer,
  DetailsContainer,
  Title,
  Label,
  ChildOneContainer,
  BackgroundContainer,
  LabelWeight,
} from "./DeleteNoteModal.styles";

type DeleteNoteModalProps = {
  noteId: number;
  setNotification: (
    notificationMessage: string,
    notificationType: "success" | "warning" | "error"
  ) => void;
  onClose: () => void;
};

export function DeleteNoteModal({
  noteId,
  setNotification,
  onClose,
}: DeleteNoteModalProps) {
  const [noteContent, setNoteContent] = useState("");
  const [labelTimeContent, setLabelTimeContent] = useState("");
  const [creatorContent, setCreatorContent] = useState("");
  const { closeModal } = useModal();

  const { patientNoteData, isFetching, isError, refetch, isRefetching } =
    getNoteData(noteId);

  useEffect(() => {
    if (!isFetching && !isRefetching && patientNoteData) {
      setNoteContent(patientNoteData.notes);
      const timeStr = getLongFormattedDate(patientNoteData.time);
      setLabelTimeContent(timeStr);
      setCreatorContent(`${patientNoteData.reviewedByEmail}`);
    }
  }, [patientNoteData, isFetching, isRefetching]);

  const handleDeleteClick = () => {
    mixpanelActions.track("User Action: Delete Note");

    noteService.deleteNote(noteId).then((deletedSuccessfully) => {
      const { data } = deletedSuccessfully;

      if (data) {
        closeModal();
        setNotification("Note successfully deleted", "success");
      } else {
        setNotification("Error deleting note", "error");
      }
      onClose();
    });
  };

  const handleCancelClick = () => {
    closeModal();
  };

  const refreshData = () => {
    refetch();
  };

  if (isError) {
    return (
      <Container>
        <p>
          Error getting note data, please{" "}
          <button type="button" onClick={refreshData}>
            try again
          </button>
          . If the issue continues contact support.
        </p>
      </Container>
    );
  }

  return (
    <Container>
      <Title>Delete note</Title>
      <BackgroundContainer>
        <DetailsContainer>
          <ChildOneContainer>
            <LabelWeight>Date created</LabelWeight>
            <Label>{labelTimeContent}</Label>
          </ChildOneContainer>
          <ChildOneContainer>
            <LabelWeight>Created by</LabelWeight>
            <Label>{creatorContent}</Label>
          </ChildOneContainer>
        </DetailsContainer>
        <NoteContainer>
          <LabelWeight>Note</LabelWeight>
          <Label>{textTruncate(noteContent)}</Label>
        </NoteContainer>
      </BackgroundContainer>
      <SubmitContainer>
        <Button label="Delete" type="button" onClick={handleDeleteClick} />
        <CancelButton onClick={handleCancelClick}>Cancel</CancelButton>
      </SubmitContainer>
    </Container>
  );
}
