import axios, { AxiosResponse, AxiosError } from "axios";

import {
  ISocreStrategyOption,
  ISurvey,
  ISurveyAnswer,
  ISurveyDisplayRulesTypes,
} from "../@types/Surveys";
import { api } from "./api";

export const surveysService = {
  async getAllSurveys(): Promise<AxiosResponse> {
    const url = "/Portal/DynamicSurveys/Surveys";
    const surveys = await api.get(url);
    return surveys;
  },

  async getSurveyById(id: string): Promise<AxiosResponse> {
    const url = `/Portal/DynamicSurveys/Surveys/${id}`;
    const survey = await api.get(url);
    return survey;
  },

  async getSurveyByUserSurveyId(id: string): Promise<AxiosResponse> {
    const url = `/Portal/DynamicSurveys/Surveys/ByUserSurveyId/${id}`;
    const survey = await api.get(url);
    return survey;
  },

  async getQuestionTypes(): Promise<AxiosResponse> {
    const url = `/Portal/DynamicSurveys/SurveyQuestionTypes`;
    const survey = await api.get(url);
    return survey;
  },

  async getSurveyDisplayRules(): Promise<
    AxiosResponse<ISurveyDisplayRulesTypes[]>
  > {
    const url = `/Portal/DynamicSurveys/SurveyDisplayRules`;
    const survey = await api.get(url);
    return survey;
  },

  async getSurveyDisplayRulesComparators(): Promise<AxiosResponse<string[]>> {
    const url = `/Portal/DynamicSurveys/SurveyDisplayRulesComparators`;
    const survey = await api.get(url);
    return survey;
  },

  async addSurvey(survey: ISurvey): Promise<AxiosResponse> {
    const url = "/Portal/DynamicSurveys/Surveys";
    const serverResponse = await api
      .post<ISurvey>(url, survey)
      .then((response: AxiosResponse | AxiosError) => {
        if (axios.isAxiosError(response)) {
          return response.response;
        }
        return response;
      });

    if (serverResponse) {
      return serverResponse;
    }

    throw Error;
  },

  async assignSurveyToHospital(
    surveyId: string,
    hospitalIds: number[]
  ): Promise<AxiosResponse> {
    const url = `/Portal/DynamicSurveys/Surveys/${surveyId}/AssignHospitals`;
    const serverResponse = await api
      .put<{ hospitalIds: number[] }>(url, { hospitalIds })
      .then((response: AxiosResponse | AxiosError) => {
        if (axios.isAxiosError(response)) {
          return response.response;
        }
        return response;
      });

    if (serverResponse) {
      return serverResponse;
    }

    throw Error;
  },

  async getAllHospitals(): Promise<AxiosResponse> {
    const url = "/Portal/Wards/GetAllWards";
    const surveys = await api.get(url);
    return surveys;
  },

  async editSurvey(survey: ISurvey): Promise<AxiosResponse> {
    const url = `/Portal/DynamicSurveys/Surveys/${survey.id}`;
    const serverResponse = await api
      .put<ISurvey>(url, survey)
      .then((response: AxiosResponse | AxiosError) => {
        if (axios.isAxiosError(response)) {
          return response.response;
        }
        return response;
      });

    if (serverResponse) {
      return serverResponse;
    }

    throw Error;
  },

  // Mobile

  async getAssignedSurveysMobile(): Promise<AxiosResponse> {
    const url = `/Mobile/DynamicSurveys/UserSurveys`;
    const surveys = await api.get(url);
    return surveys;
  },

  async getSurveyByIdMobile(surveyId: string): Promise<AxiosResponse> {
    const url = `/Mobile/DynamicSurveys/Surveys/${surveyId}`;
    const survey = await api.get(url);
    return survey;
  },

  async postUserSurveyAnswersMobile(
    userSurveyId: string,
    answersToSend: ISurveyAnswer[]
  ): Promise<AxiosResponse> {
    const url = `/Mobile/DynamicSurveys/UserSurveys/${userSurveyId}/Answers`;
    const objectToSend = {
      answers: answersToSend,
    };
    const serverResponse = await api
      .post<ISurvey>(url, objectToSend)
      .then((response: AxiosResponse | AxiosError) => {
        if (axios.isAxiosError(response)) {
          return response.response;
        }
        return response;
      });

    if (serverResponse) {
      return serverResponse;
    }

    throw Error;
  },

  async getSurveyByUserSurveyIdMobile(
    userSurveyId: string
  ): Promise<AxiosResponse> {
    const url = `/Portal/DynamicSurveys/Surveys/ByUserSurveyId/${userSurveyId}`;
    const survey = await api
      .get(url)
      .then((response: AxiosResponse | AxiosError) => {
        if (axios.isAxiosError(response)) {
          return response.response;
        }
        return response;
      });

    if (survey) {
      return survey;
    }

    throw Error;
  },

  async getScoreStrategiesOptions(): Promise<
    AxiosResponse<ISocreStrategyOption[]>
  > {
    const url = `/Portal/DynamicSurveys/SurveyScoreStrategies`;

    const serverResponse = await api.get(url);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },
};
