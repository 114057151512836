import React, { createContext, useCallback, useMemo } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { NotificationSuccess } from "../assets/icons/NotificationSuccess";

type ToastContextType = {
  showToast: (
    message: string,
    type: "success" | "info" | "warning" | "error",
    options?: Partial<ToastOptions>
  ) => void;
};

type ToastProviderProps = {
  children: React.ReactNode;
};

type ToastOptions = {
  width?: string;
  rightMargin?: string;
};

export const ToastContext = createContext({} as ToastContextType);

export function ToastProvider({ children }: ToastProviderProps) {
  const showToast = useCallback(
    (
      message: string,
      type: "success" | "info" | "warning" | "error",
      options?: Partial<ToastOptions>
    ) => {
      const notificationStyle = {
        width: options?.width || "max-content",
        minHeight: "50px",
        backgroundColor: "#212121",
        borderRadius: "8px",
        right: options?.rightMargin || "30%",
        color: "#FFFFFF",
        paddingLeft: "15px",
        paddingRight: "15px",
        fontFamily: '"Open Sans", sans-serif',
        zIndex: 3100,
      };

      const toastOptions = {
        style: notificationStyle,
        icon: type === "success" ? NotificationSuccess : undefined,
      };

      switch (type) {
        case "success":
          toast.success(message, toastOptions);
          break;
        case "info":
          toast.info(message, toastOptions);
          break;
        case "warning":
          toast.warn(message, toastOptions);
          break;
        case "error":
          toast.error(message, toastOptions);
          break;
        default:
          break;
      }
    },
    []
  );

  const value = useMemo(() => ({ showToast }), [showToast]);

  return (
    <ToastContext.Provider value={value}>
      {children}
      <ToastContainer
        position="bottom-right"
        autoClose={2500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        closeButton={false}
      />
    </ToastContext.Provider>
  );
}
