import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";

import { Calendar } from "../../../../../../../assets/icons/Calendar";
import { GraphToggle } from "../../../../../../../assets/icons/GraphToggle";
import { TableToggle } from "../../../../../../../assets/icons/TableToggle";
import { DatePickerInput } from "../../../../../../../components/DatePicker";
import {
  OptionButton,
  OptionsBar,
} from "../../../../../../../components/OptionsBar";
import { mixpanelActions } from "../../../../../../../utils/mixpanel";
import { DateFilterOptionType } from "../../../../MeasurementModal/components/CustomOptionsBar";
import {
  DatepickerContainer,
  SvgCss,
} from "../../../../MeasurementModal/components/CustomOptionsBar/CustomOptionsBar.styles";
import { dateFilterOptions } from "../../../../MeasurementModal/components/CustomOptionsBar/dateFilterOptions";
import { OptionsBarContainer } from "./PromsOptionsBar.styles";

type PromsOptionsBarType = {
  cardTitle?: string;
  selectedFilterDate: DateFilterOptionType;
  isTableView: boolean;
  hasGraphView: boolean;
  onChange: (selectedDateFilter: DateFilterOptionType) => void;
  onTableViewChange: (enableMeasurementsTable: boolean) => void;
};

export function PromsOptionsBar({
  cardTitle,
  selectedFilterDate,
  isTableView,
  hasGraphView,
  onChange,
  onTableViewChange,
}: PromsOptionsBarType) {
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [showMeasurementsTable, setShowMeasurementsTable] = useState(true);

  const customDateFiterOption = "custom";
  const wrapperRef = useRef<any>(null);
  const calendarButtonRef = useRef<any>(null);

  const cardTitleId = cardTitle?.replaceAll(" ", "_");

  const handleOnChangeDateWithDatePicker = (dateRange: any) => {
    if (dateRange.startDate && dateRange.endDate) {
      setCalendarOpen(false);
    } else {
      return;
    }

    onChange({
      label: customDateFiterOption,
      startDate: dayjs(dateRange.startDate),
      endDate: dayjs(dateRange.endDate),
    });
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        calendarOpen &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        !calendarButtonRef.current.contains(event.target)
      ) {
        setCalendarOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [calendarOpen]);

  useEffect(() => {
    setShowMeasurementsTable(isTableView);
  }, [isTableView]);

  return (
    <OptionsBarContainer>
      <OptionsBar>
        <div
          style={{ backgroundColor: "white", borderRadius: "8px 0 0 8px" }}
          ref={calendarButtonRef}
        >
          <OptionButton
            key={`${cardTitleId}_customDate`}
            text="Custom"
            icon={<Calendar />}
            selected={selectedFilterDate.label === customDateFiterOption}
            onClick={() => {
              mixpanelActions.track(`User Action: CustomDateFilter`);
              setCalendarOpen((prevState) => !prevState);
            }}
          />
        </div>

        {dateFilterOptions.map((dateFilterOption, index) => {
          return (
            <OptionButton
              key={`${cardTitleId}_${dateFilterOption.label}_${index}`}
              text={dateFilterOption.label}
              selected={dateFilterOption.label === selectedFilterDate.label}
              onClick={() => {
                mixpanelActions.track(
                  `User Action: Changed Date Range ${dateFilterOption.label}`
                );

                const currentDate = dayjs();
                onChange({
                  endDate: currentDate,
                  label: dateFilterOption.label,
                  startDate: dateFilterOption.value(currentDate),
                });
              }}
            />
          );
        })}
      </OptionsBar>
      {hasGraphView ? (
        <OptionsBar borderRadius="16px">
          <OptionButton
            borderRadius="11px 0 0 11px"
            selected={!showMeasurementsTable}
            className={SvgCss()}
            text=""
            onClick={() => {
              setShowMeasurementsTable(false);
              onTableViewChange(false);
            }}
            icon={<GraphToggle />}
          />
          <OptionButton
            borderRadius="0 11px 11px 0"
            selected={showMeasurementsTable}
            className={SvgCss()}
            text=""
            onClick={() => {
              setShowMeasurementsTable(true);
              onTableViewChange(true);
            }}
            icon={<TableToggle />}
          />
        </OptionsBar>
      ) : null}

      {calendarOpen && (
        <DatepickerContainer ref={wrapperRef}>
          <DatePickerInput
            initialDateRange={{
              startDate: selectedFilterDate.startDate.toDate(),
              endDate: selectedFilterDate.endDate.toDate(),
            }}
            onDateChange={handleOnChangeDateWithDatePicker}
          />
        </DatepickerContainer>
      )}
    </OptionsBarContainer>
  );
}
