import { ColumnDef } from "@tanstack/react-table";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";

import {
  IPromsLineGraphData,
  IPromsRange,
  IPromsTableData,
  ISurveyFormQuestionTableData,
  ISurveyPromsTableData,
} from "../../../../../../../@types/Proms";
import { ReactComponent as PromsEmptyState } from "../../../../../../../assets/images/proms-empty-state.svg";
import { SkeletonLoading } from "../../../../../../../components/Skeleton";
import { createColumn, Table } from "../../../../../../../components/Table";
import { getPatientSurveyFormsData } from "../../../../../../../hooks/queries/proms";
import { useUserPreferences } from "../../../../../../../hooks/useUserPreferences";
import { userService } from "../../../../../../../services/userService";
import {
  getLongFormattedDate,
  getShortFormattedDate,
} from "../../../../../../../utils/dateFormatter";
import { mixpanelActions } from "../../../../../../../utils/mixpanel";
import { roundToX } from "../../../../../../../utils/unitOperations";
import { DateFilterOptionType } from "../../../../MeasurementModal/components/CustomOptionsBar";
import {
  ImageContainer,
  NoContentMessage,
} from "../../PatientPromsTable.style";
import { PromsLineGraph } from "../PromsLineGraph";
import { PromsOptionsBar } from "../PromsOptionsBar";
import { PromsScatterGraph } from "../PromsScatterGraph";
import {
  PromDetailsModalContainer,
  Subtitle,
  ContentContainer,
  Title,
  FlexContainer,
  HeaderContainer,
  TitleAndFilterOptionsContainer,
  Divider,
  QuestionsAndAnswerContainer,
  SkeletonContainer,
  HcpInformationContainer,
  TableContainer,
  PromsGraphContainer,
} from "./PromDetailsModal.style";
import {
  promDetailsTableColumns,
  promQuestionsAndAnswersTableColumns,
} from "./promDetailsTableColumns";

type PromDetailsModalProps = {
  patientId: number;
  formData: IPromsTableData;
};

export function PromDetailsModal({
  patientId,
  formData,
}: PromDetailsModalProps) {
  const { portalPreferences, changePortalPreferences, getDateRangeFilter } =
    useUserPreferences();
  const [selectedFilterDate, setSelectedFilterDate] =
    useState<DateFilterOptionType>(getDateRangeFilter);

  const { surveyForms, isFetching, refetch } = getPatientSurveyFormsData(
    patientId,
    formData.id,
    selectedFilterDate.startDate.toISOString(),
    selectedFilterDate.endDate.toISOString()
  );

  const [completedDate, setCompletedDate] = useState("");
  const [questionsAndAnswers, setQuestionsAndAnswers] = useState<
    ISurveyFormQuestionTableData[]
  >([]);
  const [isTableView, setIsTableView] = useState<boolean>(
    surveyForms && surveyForms.length && surveyForms[0].displayRules
      ? surveyForms[0]?.displayRules?.type !== "TotalScoreGraphWithLegend"
      : true
  );
  const [hasGraphView, setHasGraphView] = useState<boolean>(
    surveyForms && surveyForms.length && surveyForms[0].displayRules
      ? surveyForms[0]?.displayRules?.type === "TotalScoreGraphWithLegend"
      : false
  );
  const [selectedSurveyId, setSelectedSurveyId] = useState<string>();
  const [formattedDateRange, setFormattedDateRange] = useState<string>("");

  let newTableColums: ColumnDef<any, string>[] = [];

  const roundScoreToOneDecimal = (score: number) => {
    return Math.round(score * 10) / 10;
  };

  const handleRowClick = (rowData: ISurveyPromsTableData) => {
    const dataToDisplay: ISurveyFormQuestionTableData[] = [];

    rowData.questions.forEach((data) => {
      let { answerText } = data;
      if (answerText)
        if (data.type === "Date") {
          answerText = dayjs(answerText).format("MMM D, YYYY, hh:mma");
        }

      dataToDisplay.push({
        key: data.key,
        question: data.questionText,
        answer: answerText,
        type: data.type,
      });
    });

    setSelectedSurveyId(rowData.id.toString());
    setCompletedDate(rowData.time);
    setQuestionsAndAnswers(dataToDisplay);
  };

  const handleOnChangeDateRangeFilter = (
    dateRangeFilter: string,
    customFromDate?: dayjs.Dayjs,
    customToDate?: dayjs.Dayjs
  ) => {
    const newPortalPreferences = {
      ...portalPreferences,
      dateRangeFilter,
      customFromDate,
      customToDate,
    };
    changePortalPreferences(newPortalPreferences);

    if (dateRangeFilter !== "custom") {
      userService.updateDateRangeFilter(dateRangeFilter);
    }
  };

  const parsedSurveyForms = useMemo(() => {
    if (!surveyForms) return [];

    const domains = new Set<string>();

    surveyForms.forEach((surveyForm) => {
      if (surveyForm.scores) {
        surveyForm.scores.forEach((score) => {
          if (score.type === "Domain" && score.domain) {
            domains.add(score.domain);
          }
        });
      }
    });

    newTableColums = [...promDetailsTableColumns];

    const newColumns: ColumnDef<any, string>[] = [];

    domains.forEach((domain) =>
      newColumns.push(
        createColumn({
          keyName: domain,
          header: () => domain,
          cell: (info) => info.renderValue(),
        })
      )
    );

    newTableColums.splice(1, 0, ...newColumns);

    const surveyFormList = surveyForms.map(
      (surveyForm: ISurveyPromsTableData) => {
        const domainScores: { [key: string]: string | number } = {};

        domains.forEach((domain) => {
          const domainScore = surveyForm.scores?.find(
            (score) => score.domain === domain
          )?.score;
          domainScores[domain] =
            domainScore !== undefined && domainScore >= 0
              ? roundScoreToOneDecimal(domainScore)
              : "-";
        });

        const totalScore = surveyForm.scores?.find(
          (x) => x.type === "Total"
        )?.score;

        return {
          id: surveyForm.id,
          time: getLongFormattedDate(surveyForm.time),
          questions: surveyForm.questions.filter(
            (question) => question.type !== "Informative"
          ),
          surveyResult: surveyForm.surveyResult ?? "-",
          total:
            totalScore !== undefined ? roundScoreToOneDecimal(totalScore) : "-",
          ...domainScores,
        };
      }
    );

    return surveyFormList;
  }, [surveyForms]);

  const handleOnLineDotClick = (surveyId: number) => {
    const surveyForm = parsedSurveyForms.find((sf) => sf.id === surveyId);

    if (!surveyForm) return;

    const dataToDisplay: ISurveyFormQuestionTableData[] = [];

    surveyForm.questions.forEach((data) => {
      let { answerText } = data;
      if (answerText)
        if (data.type === "Date") {
          answerText = dayjs(answerText).format("MMM D, YYYY, hh:mma");
        }

      dataToDisplay.push({
        key: data.key,
        question: data.questionText,
        answer: answerText,
        type: data.type,
      });
    });

    setSelectedSurveyId(surveyId.toString());
    setCompletedDate(surveyForm.time);
    setQuestionsAndAnswers(dataToDisplay);
  };

  const graphData = useMemo(() => {
    const data: IPromsLineGraphData[] = [];
    surveyForms?.forEach((surveyForm) => {
      const totalScore = surveyForm.scores?.find(
        (x) => x.type === "Total"
      )?.score;

      if (totalScore !== undefined) {
        data.push({
          id: surveyForm.id,
          time: surveyForm.time,
          totalScore: roundScoreToOneDecimal(totalScore),
        });
      }
    });
    return data;
  }, [surveyForms]);

  const mainValues = useMemo(() => {
    if (graphData.length === 0) return {};

    const minData = graphData.reduce(
      (minObj, current) =>
        current.totalScore < minObj.totalScore ? current : minObj,
      graphData[0]
    );

    const maxData = graphData.reduce(
      (maxObj, current) =>
        current.totalScore > maxObj.totalScore ? current : maxObj,
      graphData[0]
    );
    const average = roundToX(
      graphData.reduce((sum, current) => sum + current.totalScore, 0) /
        graphData.length,
      2
    );

    const minDate = graphData.reduce((prev, current) =>
      prev.time < current.time ? prev : current
    ).time;
    const maxDate = graphData.reduce((prev, current) =>
      prev.time > current.time ? prev : current
    ).time;

    return {
      min: roundToX(minData.totalScore, 2),
      max: roundToX(maxData.totalScore, 2),
      minValueDate: minData.time ? dayjs(minData.time) : undefined,
      maxValueDate: maxData.time ? dayjs(maxData.time) : undefined,
      minDate: minDate ? dayjs(minDate) : undefined,
      maxDate: maxDate ? dayjs(maxDate) : undefined,
      average: roundToX(average, 2),
    };
  }, [graphData]);

  const scatterGraphData = useMemo(() => {
    if (!surveyForms || surveyForms.length === 0) return [];

    const { displayRules } = surveyForms[0];

    const data = surveyForms.flatMap((surveyForm) =>
      surveyForm.questions.map((question) => ({
        questionId: question.questionId,
        questionOptionIds: question.questionOptionIds,
        time: surveyForm.time,
        surveyId: surveyForm.id,
      }))
    );

    const mappedData = data.map(
      ({ questionId, questionOptionIds, time, surveyId }) => {
        if (
          !displayRules ||
          !displayRules.legends ||
          !displayRules.yAxisLabels ||
          !displayRules.dataSourceType
        )
          return null;

        if (displayRules.dataSourceType === "Answers") {
          const legend = displayRules.legends.find((l) =>
            l.legendIds.includes(questionId)
          );

          const yAxisLabel = displayRules.yAxisLabels.find((yAxis) =>
            yAxis.labelIds.some((id) => questionOptionIds?.includes(id))
          );

          if (!legend || !yAxisLabel) return null;

          return {
            y: yAxisLabel?.text,
            x: dayjs(time).valueOf(),
            value: legend?.text,
            surveyId,
          };
        }

        const legend = displayRules.legends.find((l) =>
          l.legendIds.some((id) => questionOptionIds?.includes(id))
        );

        const yAxisLabel = displayRules.yAxisLabels.find((yAxis) =>
          yAxis.labelIds.includes(questionId)
        );

        if (!legend || !yAxisLabel) return null;

        return {
          y: yAxisLabel?.text,
          x: dayjs(time).valueOf(),
          value: legend?.text,
          surveyId,
        };
      }
    );

    const filteredData = mappedData.filter(Boolean);

    return filteredData;
  }, [surveyForms]);

  const renderContent = useMemo(() => {
    if (isFetching) {
      return (
        <SkeletonContainer>
          <SkeletonLoading height="100%" width="100%" />
        </SkeletonContainer>
      );
    }
    if (surveyForms.length === 0) {
      return (
        <ImageContainer style={{ border: "0px", marginTop: "30px" }}>
          <PromsEmptyState width={181} height={158} />
          <NoContentMessage>No data to show</NoContentMessage>
        </ImageContainer>
      );
    }

    const { displayRules } =
      surveyForms.length > 0 ? surveyForms[0] : { displayRules: undefined };

    if (isTableView || !displayRules) {
      return (
        <TableContainer>
          <Table
            data={parsedSurveyForms}
            columns={newTableColums}
            hasBorder={false}
            onRowClick={handleRowClick}
            rowPadding
            fontSize={14}
            selectedId={selectedSurveyId}
          />
        </TableContainer>
      );
    }

    if (graphData.length === 0) {
      return (
        <ImageContainer style={{ border: "0px", marginTop: "30px" }}>
          <PromsEmptyState width={181} height={158} />
          <NoContentMessage>No data to show</NoContentMessage>
        </ImageContainer>
      );
    }

    if (displayRules.type === "TotalScoreGraphWithLegend") {
      const ranges =
        displayRules.ranges?.map((r) => {
          return {
            start: r.start,
            end: r.end,
            startComparator: r.startComparator ?? ">=",
            endComparator: r.startComparator !== "=" ? r.endComparator : "<",
            color: r.color,
            name: r.name,
          } as IPromsRange;
        }) ?? [];

      return (
        <PromsGraphContainer>
          <PromsLineGraph
            data={graphData}
            minY={displayRules.yAxisMin}
            maxY={displayRules.yAxisMax}
            mainValues={mainValues}
            dateInterval={selectedFilterDate}
            showValues={questionsAndAnswers.length === 0}
            ranges={ranges}
            selectedId={selectedSurveyId}
            onDotClick={handleOnLineDotClick}
          />
        </PromsGraphContainer>
      );
    }

    if (displayRules.type === "AnswerPlotGraph") {
      const yAxisLabels = displayRules.yAxisLabels?.map((label) => label.text);

      const legendsColorMap = displayRules.legends?.reduce((acc, curr) => {
        acc[curr.text] = curr.color;
        return acc;
      }, {} as Record<string, string>);

      return (
        <PromsScatterGraph
          data={scatterGraphData}
          mainValues={mainValues}
          dateInterval={selectedFilterDate}
          yAxisLabels={yAxisLabels ?? []}
          legendsColorMap={legendsColorMap ?? {}}
          onDotClick={handleOnLineDotClick}
        />
      );
    }

    return null;
  }, [isFetching, graphData, mainValues]);

  useEffect(() => {
    refetch();
    setQuestionsAndAnswers([]);
    setSelectedSurveyId(undefined);
  }, [selectedFilterDate]);

  useEffect(() => {
    if (isFetching) return;

    if (!surveyForms || !surveyForms.length || !surveyForms[0]?.displayRules) {
      setHasGraphView(false);
      setIsTableView(true);
      return;
    }

    const isGraph =
      surveyForms[0]?.displayRules?.type === "TotalScoreGraphWithLegend" ||
      surveyForms[0]?.displayRules?.type === "AnswerPlotGraph";

    setHasGraphView(isGraph);
    setIsTableView(!isGraph);
  }, [isFetching]);

  useEffect(() => {
    let minDate = dayjs();

    surveyForms?.forEach((surveyForm) => {
      if (dayjs(surveyForm.time).isBefore(minDate)) {
        minDate = dayjs(surveyForm.time);
      }
    });

    setFormattedDateRange(
      `${getShortFormattedDate(
        selectedFilterDate.label === "ALL"
          ? minDate.format()
          : selectedFilterDate.startDate.format()
      )} - ${getShortFormattedDate(selectedFilterDate.endDate.format())}`
    );
  }, [surveyForms]);

  return (
    <PromDetailsModalContainer>
      <>
        {questionsAndAnswers.length > 0 ? <Divider /> : null}
        <FlexContainer>
          <HeaderContainer>
            <TitleAndFilterOptionsContainer>
              <Title>{formData.name}</Title>
              <Subtitle>{formattedDateRange}</Subtitle>
              {surveyForms &&
              surveyForms.length &&
              surveyForms[0].displayRules &&
              surveyForms[0].displayRules.hcpInformation ? (
                <HcpInformationContainer>
                  {surveyForms[0]?.displayRules?.hcpInformation}
                </HcpInformationContainer>
              ) : null}
              <PromsOptionsBar
                cardTitle={formData.name}
                selectedFilterDate={selectedFilterDate}
                isTableView={isTableView}
                hasGraphView={hasGraphView}
                onChange={(selectedDateFilter) => {
                  const { label } = selectedDateFilter;

                  if (
                    label === "custom" ||
                    label !== selectedFilterDate.label
                  ) {
                    setSelectedFilterDate(selectedDateFilter);
                    handleOnChangeDateRangeFilter(
                      label,
                      selectedDateFilter.startDate,
                      selectedDateFilter.endDate
                    );
                  }
                }}
                onTableViewChange={(value) => {
                  if (value) {
                    mixpanelActions.track("User Action: Survey table view");
                  } else {
                    mixpanelActions.track("User Action: Survey graph view");
                  }
                  setIsTableView(value);
                }}
              />
            </TitleAndFilterOptionsContainer>
            <ContentContainer>{renderContent}</ContentContainer>
          </HeaderContainer>

          {questionsAndAnswers.length > 0 ? (
            <QuestionsAndAnswerContainer>
              <Title selectedSurvey>Selected survey</Title>
              <Subtitle selectedSurvey>{completedDate}</Subtitle>
              <TableContainer isQuestions>
                <Table
                  data={questionsAndAnswers}
                  columns={promQuestionsAndAnswersTableColumns}
                  hasBorder={false}
                  rowPadding
                  rowPaddingRight="30px"
                  fontSize={14}
                />
              </TableContainer>
            </QuestionsAndAnswerContainer>
          ) : null}
        </FlexContainer>
      </>
    </PromDetailsModalContainer>
  );
}
