import { ColorPicker } from "antd";
import { FormikProps, getIn } from "formik";

import { ISurveyDisplayRules } from "../../../../../../../@types/Surveys";
import { Close } from "../../../../../../../assets/icons/Close";
import { Button } from "../../../../../../../components/Button";
import { CustomIconButton } from "../../../../../../../components/CustomIconButton";
import { SelectDropdown } from "../../../../../../../components/SelectDropdown";
import { TextField } from "../../../../../../../components/TextField";
import { IDisplayRulesOptions } from "../../../../SurveyBuilder";
import {
  AddRangeOptionButton,
  colorPickerClassName,
  deleteRangeOptionButtonClassName,
  DisplaySubTitle,
  ErrorMessage,
  mediumTextFieldClassName,
  MultipleInputsRow,
  RowElement,
} from "../../DisplayTab.styles";
import {
  RangeSettingsContainer,
  selectPopUpCss,
  smallSelectCss,
  smallTextFieldClassName,
  SubmitContainer,
} from "./TotalScoreWithLegendForm.styles";

interface ITotalScoreWithLegendFormProps {
  displaySettingsFormik: FormikProps<ISurveyDisplayRules>;
  displayRulesOptions: IDisplayRulesOptions;
  addRangeOption: () => void;
}

export function TotalScoreWithLegendForm({
  displaySettingsFormik,
  displayRulesOptions,
  addRangeOption,
}: ITotalScoreWithLegendFormProps) {
  const shouldShowErrorMessage = (
    field:
      | "start"
      | "startComparator"
      | "end"
      | "endComparator"
      | "name"
      | "color",
    index?: number
  ) => {
    if (
      index !== undefined &&
      Array.isArray(displaySettingsFormik.touched.ranges) &&
      displaySettingsFormik.touched.ranges?.[index]
    ) {
      if (
        (field === "start" || field === "end") &&
        displaySettingsFormik.touched.ranges?.[index][field]
      ) {
        const valueError = getIn(
          displaySettingsFormik.errors.ranges?.[index],
          field
        );

        const comparatorError = getIn(
          displaySettingsFormik.errors.ranges?.[index],
          `${field}Comparator`
        );

        return `${comparatorError ?? ""}\n${valueError ?? ""}`.trim();
      }
      return displaySettingsFormik.touched.ranges?.[index][field]
        ? getIn(displaySettingsFormik.errors.ranges?.[index], field)
        : "";
    }
    return "";
  };

  const deleteRangeOption = (indexToRemove: number) => {
    displaySettingsFormik.setFieldValue(
      "ranges",
      displaySettingsFormik.values.ranges?.filter(
        (_, index) => index !== indexToRemove
      )
    );
  };

  const cleanEndValues = (index: number) => {
    displaySettingsFormik.setFieldValue(`ranges[${index}].end`, undefined);
    displaySettingsFormik.setFieldValue(
      `ranges[${index}].endComparator`,
      undefined
    );
  };

  return (
    <>
      {displaySettingsFormik.values.ranges !== undefined &&
        displaySettingsFormik.values.ranges.map((range, index) => (
          <MultipleInputsRow key={index}>
            <RowElement>
              <DisplaySubTitle>Start Range *</DisplaySubTitle>
              <RangeSettingsContainer>
                <SelectDropdown
                  className={smallSelectCss()}
                  popUpCsss={selectPopUpCss()}
                  width={248}
                  height={42}
                  showSearch
                  optionFilterProp="label"
                  value={
                    displaySettingsFormik.values.ranges?.[index].startComparator
                  }
                  onValueChange={(value) => {
                    displaySettingsFormik.setFieldValue(
                      `ranges[${index}].startComparator`,
                      value.toString()
                    );

                    if (value.toString() === "=") {
                      cleanEndValues(index);
                    }
                  }}
                  options={displayRulesOptions.startComparators}
                />
                <TextField
                  label=""
                  placeholder="min"
                  name={`ranges[${index}].start`}
                  value={displaySettingsFormik.values.ranges?.[index].start}
                  onChange={displaySettingsFormik.handleChange}
                  className={smallTextFieldClassName()}
                  backgroudColor="white"
                  onBlur={displaySettingsFormik.handleBlur}
                />
              </RangeSettingsContainer>
              <ErrorMessage>
                {shouldShowErrorMessage("start", index)}
              </ErrorMessage>
            </RowElement>
            <RowElement>
              <DisplaySubTitle>End Range *</DisplaySubTitle>
              <RangeSettingsContainer>
                <SelectDropdown
                  disabled={range.startComparator === "="}
                  className={smallSelectCss()}
                  popUpCsss={selectPopUpCss()}
                  width={248}
                  height={42}
                  showSearch
                  optionFilterProp="label"
                  value={
                    displaySettingsFormik.values.ranges !== undefined
                      ? displaySettingsFormik.values.ranges[index].endComparator
                      : ""
                  }
                  onValueChange={(value) => {
                    displaySettingsFormik.setFieldValue(
                      `ranges[${index}].endComparator`,
                      value.toString()
                    );
                  }}
                  options={displayRulesOptions.endComparators}
                />
                <TextField
                  disabled={range.startComparator === "="}
                  label=""
                  placeholder="max"
                  name={`ranges[${index}].end`}
                  value={displaySettingsFormik.values.ranges?.[index].end ?? ""}
                  onChange={displaySettingsFormik.handleChange}
                  className={smallTextFieldClassName()}
                  backgroudColor="white"
                  onBlur={displaySettingsFormik.handleBlur}
                />
              </RangeSettingsContainer>
              <ErrorMessage>
                {shouldShowErrorMessage("end", index)}
              </ErrorMessage>
            </RowElement>
            <RowElement>
              <DisplaySubTitle>Color *</DisplaySubTitle>
              <ColorPicker
                className={colorPickerClassName()}
                value={displaySettingsFormik.values.ranges?.[index].color}
                onChange={(value) => {
                  displaySettingsFormik.setFieldValue(
                    `ranges[${index}].color`,
                    value.toHexString()
                  );
                }}
                disabledFormat
                showText
                defaultValue="black"
                disabledAlpha
              />
              <ErrorMessage>
                {shouldShowErrorMessage("color", index)}
              </ErrorMessage>
            </RowElement>
            <RowElement>
              <DisplaySubTitle>Name *</DisplaySubTitle>
              <TextField
                label=""
                placeholder="name"
                name={`ranges[${index}].name`}
                value={displaySettingsFormik.values.ranges?.[index].name}
                onChange={displaySettingsFormik.handleChange}
                className={mediumTextFieldClassName()}
                backgroudColor="white"
                onBlur={displaySettingsFormik.handleBlur}
              />
              <ErrorMessage>
                {shouldShowErrorMessage("name", index)}
              </ErrorMessage>
            </RowElement>
            {(displaySettingsFormik.values.ranges?.length ?? 0) > 1 && (
              <CustomIconButton
                className={deleteRangeOptionButtonClassName()}
                key={index}
                onClick={() => deleteRangeOption(index)}
              >
                <Close />
              </CustomIconButton>
            )}
          </MultipleInputsRow>
        ))}

      <SubmitContainer>
        <Button
          type="button"
          label="Add range"
          className={AddRangeOptionButton()}
          onClick={addRangeOption}
        />
        <ErrorMessage style={{ top: "auto", position: "relative" }}>
          {typeof displaySettingsFormik.errors.ranges === "string"
            ? displaySettingsFormik.errors.ranges
            : ""}
        </ErrorMessage>
      </SubmitContainer>
    </>
  );
}
