const DEFAULT_RADIUS = 3;

export function CircleLegend(props: any) {
  const { fill, lineOpacity } = props;
  return (
    <svg x={0} y={0} width="16" height="16" viewBox="0 0 16 16" fill="none">
      <rect
        x="2"
        y="6"
        width="12"
        height="4"
        fill={fill}
        opacity={lineOpacity}
      />
      <circle cx="8" cy="8" r={DEFAULT_RADIUS} fill={fill} />
    </svg>
  );
}
