import { styled } from "../../../../../../../styles";

export const PromDetailsModalContainer = styled("div", {
  padding: "24px 0px 0px 0px",
  position: "relative",
  height: "100%",
  minWidth: "1185px",
  width: "100%",
  display: "flex",
  flexDirection: "column",

  "@media screen and (max-width: 1185px)": {
    width: "calc(100vw - 80px)",
    overflowX: "scroll",
  },
});

export const Title = styled("p", {
  color: "$black",
  fontFamily: "$openSans",
  fontSize: "16px",
  lineHeight: "22px",
  fontWeight: "600",

  marginBottom: "4px",
  paddingLeft: "14px",

  variants: {
    selectedSurvey: {
      true: {
        paddingLeft: "70px",
      },
    },

    loading: {
      true: {
        paddingLeft: "48px",
      },
    },
  },
});

export const Subtitle = styled("h2", {
  margin: "4px 0 12px 18px",

  color: "$gray-80",

  fontWeight: 400,
  fontSize: "12px",

  lineHeight: "16px",

  variants: {
    selectedSurvey: {
      true: {
        paddingLeft: "52px",
      },
    },
  },
});

export const HcpInformationContainer = styled("div", {
  width: "max-content",
  maxWidth: "600px",
  height: "max-content",
  maxHeight: "50px",

  backgroundColor: "rgba(197, 216, 254, 0.24)",
  color: "#1F3E7B",

  margin: "4px 0 12px 18px",
  padding: "12px",
  borderRadius: "4px",

  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "16px",
});

export const ContentContainer = styled("div", {
  width: "100%",
  height: "100%",
  maxHeight: "675px",
});

export const FlexContainer = styled("div", {
  display: "flex",
  maxHeight: "620px",
  height: "100%",

  overflowY: "hidden",

  position: "relative",
});

export const HeaderContainer = styled("div", { width: "100%", height: "100%" });

export const TitleAndFilterOptionsContainer = styled("div", {
  margin: "0 0 4px 34px",
});

export const Divider = styled("div", {
  height: "calc(100% + 34px)",
  marginTop: "-41px",
  width: "1px",
  backgroundColor: "#ECECEC",
  zIndex: 1,

  position: "absolute",
  left: "50%",
});

export const QuestionsAndAnswerContainer = styled("div", {
  width: "100%",
  height: "100%",
});

export const SkeletonContainer = styled("div", {
  marginTop: "40px",
  height: "100%",
});

export const TableContainer = styled("div", {
  height: "100%",

  maxHeight: "560px",

  variants: {
    isQuestions: {
      true: {
        maxHeight: "675px",
      },
    },
  },
});

export const PromsGraphContainer = styled("div", {
  width: "100%",
  height: "100%",
  maxHeight: "545px",
});
