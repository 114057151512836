import { styled, css } from "../../../../../../styles";

export const DatepickerContainer = styled("div", {
  top: 114,
  zIndex: 3000,
  borderRadius: "16px",
  position: "absolute",
  boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.22)",
  border: "1px solid var(--colors-gray-50)",
});

export const FilterContainer = styled("div", {
  top: 114,
  left: 390,
  minWidth: 204,
  zIndex: 3000,
  borderRadius: "16px",
  position: "absolute",
  background: "white",
  boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.22)",
  border: "1px solid var(--colors-gray-50)",
});

export const BaselineContainer = styled("div", {
  top: 114,
  left: 555,
  width: 220,
  zIndex: 3000,
  borderRadius: "16px",
  position: "absolute",
  background: "white",
  boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.22)",
  border: "1px solid var(--colors-gray-50)",

  fontSize: "12px !important",
  fontFamily: "$openSans",
  padding: "22px 14px 10px 22px",

  input: {
    "&::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },

  variants: {
    isLarge: {
      true: { height: "140px", width: "240px" },
      false: { height: "105px" },
    },
  },
});

export const BaselineInputContainer = styled("div", {
  position: "relative",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: 12,

  p: {
    fontWeight: "500",
    lineHeight: "22px",
    color: "#757575",
  },
});

export const ClearButton = styled("div", {
  width: "22px",
  height: "22px",

  position: "absolute",
  right: 5,

  cursor: "pointer",

  svg: {
    circle: {
      fill: "rgba(217, 217, 217, 0.5) !important",
    },
    path: {
      fill: "$charcoal !important",
    },
  },

  "&:hover": {
    svg: {
      circle: {
        fill: "rgba(217, 217, 217, 0.8) !important",
      },
    },
  },
});

export const SaveContainer = styled("div", {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: 12,

  marginTop: 16,
});

export const BaselineButton = styled("div", {
  fontWeight: "500",
  fontSize: "12px",
  lineHeight: "22px",
  color: "$gray-80",

  cursor: "pointer",
  variants: {
    isSave: {
      true: {
        color: "#5D508A",
      },
    },
    isDisabled: {
      true: {
        opacity: 0.38,
        cursor: "not-allowed",
      },
    },
  },
});

export const RadioGroupContainer = styled("div", {
  width: "100%",
  padding: "10px 34px 16px 26px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  fontFamily: "$openSans",
  color: "#4B4B4B",

  b: {
    fontSize: "10px",
    lineHeight: "16px",
    fontWeight: "600",
    padding: "8px 0px 10px 0",
  },

  label: {
    input: {
      cursor: "pointer",
    },
  },

  "&:last-child": {
    paddingTop: "0px",
  },
});

export const RadioButtonContainer = styled("div", {
  width: "100%",
  height: "24px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "3px 3px 6px 0",

  p: {
    fontSize: "10px",
    lineHeight: "13px",
    fontWeight: "400",
  },

  label: {
    transform: "translateY(-4px)",
  },
});

export const OptionDivider = styled("div", {
  width: "100%",
  height: "1px",
  backgroundColor: "#E6E6E6",
  borderRadius: "0.5px",
});

export const OptionsBarContainer = styled("div", {
  display: "flex",
  flexWrap: "wrap",
  gap: "7px",
});

export const OptionButtonContainer = styled("div", {
  backgroundColor: "white",
  borderRadius: "16px",

  variants: {
    hasBaseline: {
      true: {
        button: {
          backgroundColor: "#F7F7F7",
          color: "$gray-90",
        },
      },
    },
  },
});

export const ButtonContainer = styled("div", {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "12px",

  padding: "32px 0 0 0",
});

export const CancelButton = styled("button", {
  height: "$13",
  padding: "0 $13",
  marginLeft: "5px",

  borderRadius: "20px",
  border: "2px solid $white",

  backgroundColor: "$white",

  gap: "$4",

  display: "flex",
  alignItems: "center",

  color: "$black",
  fontWeight: 600,
  lineHeight: "22px",
  fontSize: "$text-lg",
  fontFamily: "$openSans",

  cursor: "pointer",

  "&:focus": {
    outline: "none",
    border: "2px solid $primary-30",
  },

  "&:hover": {
    color: "$primary-40",
  },

  "@sm": {
    width: "80px",
    height: "26px",
    fontSize: "12px",
    marginLeft: "40px",
    paddingLeft: "19px",
  },

  variants: {
    isModal: {
      true: {
        padding: "0 $10",
      },
    },
  },
});

export const ContentContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  alignItems: "flex-start",

  height: "100%",
});

export const ModalSubtitle = styled("p", {
  padding: "14px 0 0 0",
  fontWeight: "400",
  color: "#939393",
  marginLeft: "15px",
});

// CSS

export const SvgCss = css({
  svg: {
    margin: "0px !important",
    width: "auto !important",
  },
});

export const BaselineInputCss = css({
  width: "140px !important",

  input: {
    height: "28px !important",
    padding: "0px 16px",
  },
});

export const confirmModalCss = css({
  fontFamily: "$openSans !important",
  animationDuration: "0s !important",

  ".ant-modal-content": {
    animation: "none !important",
    animationDuration: "0s !important",
    opacity: "1 !important",
    padding: "$12 $14 !important",
  },

  ".ant-modal-title": {
    fontSize: "$text-xl",
    color: "$black",
    fontWeight: "500",
    marginLeft: "15px",
  },

  ".ant-modal-footer": {
    display: "flex",
    gap: "12px",
  },
});

export const MultipleBaselineInputContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "10px",
});

export const BaselineInputLabel = styled("div", {
  width: "70px !important",
  textAlign: "center",
});
