import { getIn, useFormik } from "formik";
import { useEffect } from "react";
import * as yup from "yup";

import { ISurveyQuestion } from "../../../../../../../../../@types/Surveys";
import { TextArea } from "../../../../../../../../../components/TextArea";
import { TextField } from "../../../../../../../../../components/TextField";
import {
  FormField,
  textInputCss,
  FormInfoContainer,
} from "../../../../ContentTab.styles";

interface IQuestionInfoProps {
  onTitleChange: any;
  onDescriptionChange: any;
  question?: ISurveyQuestion;
  setQuestionInfoIsValidated: any;
  triggerValidation: boolean | undefined;
}

interface IQuestionInfoInitialValues {
  title: string;
  description: string;
}

export function QuestionInfo({
  onTitleChange,
  onDescriptionChange,
  question,
  triggerValidation,
  setQuestionInfoIsValidated,
}: IQuestionInfoProps) {
  const initialValues: IQuestionInfoInitialValues = {
    title: "",
    description: "",
  };

  const questionInfoValidation = yup.object().shape({
    title: yup.string().required("required"),
  });

  const questionInfoFormik = useFormik({
    initialValues,
    onSubmit: () => {},
    validationSchema: questionInfoValidation,
  });

  useEffect(() => {
    if (question) {
      questionInfoFormik.setFieldValue("title", question.text);
      questionInfoFormik.setFieldValue("description", question.description);
    }
  }, []);

  useEffect(() => {
    onTitleChange(questionInfoFormik.values.title);
    onDescriptionChange(questionInfoFormik.values.description);
  }, [questionInfoFormik.values]);

  const shouldShowErrorMessage = () => {
    return questionInfoFormik.touched.title
      ? getIn(questionInfoFormik.errors, "title")
      : "";
  };

  useEffect(() => {
    if (triggerValidation !== undefined) questionInfoFormik.submitForm();
  }, [triggerValidation]);

  useEffect(() => {
    if (questionInfoFormik.values.title === "") {
      setQuestionInfoIsValidated(false);
    }
    if (questionInfoFormik.dirty) {
      if (questionInfoFormik.errors.title !== undefined) {
        setQuestionInfoIsValidated(false);
      } else {
        setQuestionInfoIsValidated(true);
      }
    }
  }, [questionInfoFormik.values || questionInfoFormik.errors]);

  return (
    <FormInfoContainer>
      <FormField>
        <p>Question</p>
        <TextField
          maxLength={200}
          label=""
          name="title"
          placeholder="title"
          value={questionInfoFormik.values.title}
          onChange={questionInfoFormik.handleChange}
          backgroudColor="white"
          className={textInputCss()}
          errorMessage={shouldShowErrorMessage()}
        />
      </FormField>
      <FormField>
        <p>Brief explanation</p>
        <TextArea
          maxLength={200}
          name="description"
          placeholder="description"
          value={questionInfoFormik.values.description}
          onChange={questionInfoFormik.handleChange}
          backgroudColor="white"
          className={textInputCss()}
          rows={3}
          cols={900}
        />
      </FormField>
    </FormInfoContainer>
  );
}
